import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CanActivateViaAuthGuard} from '../../security/canactivateviaauthguard';
import {VoucherComponent} from './voucher/voucher.component';
import {VoucherListComponent} from './voucher-list/voucher-list.component';
import {PaymentconceptComponent} from './paymentconcept/paymentconcept.component';
import {BankComponent} from './bank/bank.component';
import {PaymenttypeComponent} from './paymenttype/paymenttype.component';
import {CompanyComponent} from './company/company.component';
import { ValidateComponent } from './validate/validate.component';

const routes: Routes = [
    {
        path: 'company/receipt',
        component: VoucherComponent,
        canActivate: [
            CanActivateViaAuthGuard
        ]
    },
    {
        path: 'company/receipt-list',
        component: VoucherListComponent,
        canActivate: [
            CanActivateViaAuthGuard
        ]
    },
    {
        path: 'company/bank',
        component: BankComponent,
        canActivate: [
            CanActivateViaAuthGuard
        ]
    },
    {
        path: 'company/paymentconcept',
        component: PaymentconceptComponent,
        canActivate: [
            CanActivateViaAuthGuard
        ]
    },
    {
        path: 'company/paymenttype',
        component: PaymenttypeComponent,
        canActivate: [
            CanActivateViaAuthGuard
        ]
    },
    {
        path: 'company/company',
        component: CompanyComponent,
        canActivate: [
            CanActivateViaAuthGuard
        ]
    },
    {
        path: 'validate/:id',
        component: ValidateComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ReceiptRoutingModule { }
