<section class="text-center login-form">
  <div class="curveArea">
    <div class="mainBox">
      <div class="curveSection"></div>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 text-center">
    <div class="circle-bg">
      <img src="assets/iconos/logo_menu.png"/>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="form-horizontal px-5" *ngIf="!_recoverPass ">
    <div class="form-group row" [ngClass]="{'has-error': (!usuario.valid && usuario.touched), 'has-success': (usuario.valid && usuario.touched)}">
      <div class="col-sm-12 col-md-6 padding-login">
        <mat-form-field class="full-width">
          <mat-label for="inputEmail" class="control-label">USUARIO</mat-label>
          <input matInput [formControl]="usuario" type="text" class="form-control" id="inputEmail" >
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row" [ngClass]="{'has-error': (!password.valid && password.touched), 'has-success': (password.valid && password.touched)}">
      <div class="col-sm-12 col-md-6 padding-login">
        <mat-form-field class="full-width">
          <mat-label for="inputPassword" class="control-label">CLAVE</mat-label>
          <input matInput [formControl]="password" type="password" class="form-control" id="inputPassword" value="password">
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12 login-msg">
        {{mensaje_login}}
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12 col-md-6 padding-login text-center">
        <button [disabled]="!form.valid" type="submit" class="btn btn-primary btn-auth btn-block">Ingresar</button>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12 col-md-6 padding-login text-center">
        <button class="btn btn-secondary btn-auth btn-block" (click)="recoverPass()">Recuperar Clave</button>
      </div>
    </div>
  </form>
  <form [formGroup]="formRecover" (ngSubmit)="onSubmitRecover(formRecover.value)" class="form-horizontal" *ngIf="_recoverPass">
    <div class="form-group row" [ngClass]="{'has-error': (!usuarioRecover.valid && usuarioRecover.touched), 'has-success': (usuarioRecover.valid && usuarioRecover.touched)}">
      <div class="col-sm-12 col-md-6 input-login padding-login">
        <mat-form-field class="full-width">
          <mat-label for="inputEmailRecover" class="control-label float-left">EMAIL</mat-label>
          <input matInput [formControl]="usuarioRecover" type="text" class="form-control" id="inputEmailRecover" placeholder="Ingrese su email">  
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row pl-3 pr-3">
      <div class="col-sm-12 col-md-6 text-center padding-login">
        <button [disabled]="!formRecover.valid" type="submit" class="btn btn-primary btn-auth btn-block">Recuperar</button>
        <button class="btn btn-default btn-auth btn-block" (click)="recoverPass()">Atras</button>
      </div>
    </div>
    <br />
    <br />
    <div class="text-center">
      <h4>
        {{recoverMessage}}
      </h4>
    </div>
  </form>
  <div class="text-center bottom-login mt-5 pt-5"><span>Derechos Reservados © {{currentyear}} - e-recibos, S.A.<br> Build v1.1.9</span></div>
</section>
