import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SeggacService } from '../../services/seggac.service';
import { FileManagerService } from '../../services/filemanager.service';
import { DateService} from '../../services/date.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient} from '@angular/common/http';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { convNumLetra} from '../../services/conv_num_letra';
import { CompaniesComponent } from './companies/companies.component';
import { SharedModule } from '../sharedmodule/sharedmodule.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CanActivateViaAuthGuard } from 'src/app/security/canactivateviaauthguard';
import { RouterModule, Routes } from '@angular/router';
import { PanelComponent } from './panel/panel.component';
import { MaterialModule } from 'src/app/material.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
export const routes: Routes = [
  { path: 'admin/new-company',
    component: CompaniesComponent,
    canActivate: [
      CanActivateViaAuthGuard
    ]
  },
  { path: 'admin/companies',
    component: PanelComponent,
    canActivate: [
      CanActivateViaAuthGuard
    ]
  }
];

@NgModule({
  imports: [
    NgbModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    NgxDatatableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    RouterModule.forChild(routes),
    MaterialModule
  ],
  declarations: [
    CompaniesComponent,
    PanelComponent
  ],
  providers: [
    DatePipe,
    SeggacService,
    FileManagerService,
    DateService,
    convNumLetra
  ],
})
export class AdminModule { }
