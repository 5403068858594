<mat-dialog-content>
  <div class="card-header modal-header">
    <h3 class="modal-title">Concepto de Pago</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <section class="card modal-list">
    <div class="pt-5">
      <input [(ngModel)]="searchText" (input)="filterItems($event)" placeholder="BUSCAR" class=" form-control input-rounded">
      <mat-nav-list class="mt-5">
        <ng-container *ngFor="let paymentconcept of paymentconcepts">
          <a mat-list-item (click)="selectPaymentConcept(paymentconcept)">
            <div style="display: block;">
              <div class="font-blue">
                {{ paymentconcept.paymentconceptname }}
              </div>
            </div>
          </a>
        </ng-container>
      </mat-nav-list>
    </div>
  </section>
</mat-dialog-content>
