import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsComponent } from './clients/clients.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { PaymentconceptsComponent } from './paymentconcepts/paymentconcepts.component';
import { BanksComponent } from './banks/banks.component';
import { ClientmodalComponent } from './clientmodal/clientmodal.component';
import { ImageComponent } from './image/image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CompanymodalComponent } from './companymodal/companymodal.component';
import { CountriesComponent } from './countries/countries.component';

@NgModule({
  declarations: [
    ClientsComponent, 
    PaymentconceptsComponent,
    BanksComponent,
    CountriesComponent,
    ClientmodalComponent,
    CompanymodalComponent,
    ImageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    InlineSVGModule,
    ImageCropperModule
  ]
})
export class ComponentsModule { }
