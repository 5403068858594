import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Subscription } from 'rxjs';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'cat-menu-left',
  templateUrl: './menu-left-vertical.component.html',
})
export class MenuLeftComponent implements OnInit, OnDestroy {
  _userid: any;
  isEmployee: Boolean;
  isClient: Boolean;
  modules = {
    listClaim: {
      groupid: 0,
      url: '/company/claims',
      show: false
    },
    createClaim: {
      groupid: 0,
      url: '/company/createClaim',
      show: false
    },
    listClient: {
      url: '/client/list',
      show: false
    },
    listContractor: {
      groupid: 1,
      url: '/contractor/list',
      show: false
    },
    listContractorEmployee: {
      groupid: 1,
      url: '/contractor/employees',
      show: false
    },
    listUser: {
      url: '/user/list',
      show: false
    },
    listVoucher: {
      url: '/company/receipt-list',
      show: false
    },
    createVoucher: {
      url: '/company/receipt',
      show: false
    },
    bank: {
      url: '/company/bank',
      show: false
    },
    paymentconcept: {
      url: '/company/paymentconcept',
      show: false
    },
    paymenttype: {
      url: '/company/paymenttype',
      show: false
    },
    company: {
      url: '/company/company',
      show: false
    },
    listProject: {
      groupid: 2,
      url: '/company/projects',
      show: false
    },
    listModel: {
      groupid: 2,
      url: '/company/models',
      show: false
    },
    listProperty: {
      groupid: 2,
      url: '/company/properties',
      show: false
    },
    listArea: {
      groupid: 2,
      url: '/company/areas',
      show: false
    },
    listActivity: {
      groupid: 2,
      url: '/company/activities',
      show: false
    },
    updateExpiration: {
      groupid: 2,
      url: '/company/updateExpiration',
      show: false
    },
    reportReceivedClaims: {
      groupid: 3,
      url: '/reports/claimreci',
      show: false
    },
    reportReceivedProblems: {
      groupid: 3,
      url: '/reports/problems',
      show: false
    },
    reportConstructionProblems: {
      groupid: 3,
      url: '/reports/list/constructionproblems',
      show: false
    },
    reportProblemsByContractor: {
      groupid: 3,
      url: '/reports/list/probcont',
      show: false
    },
    reportDeliveredProperties: {
      groupid: 3,
      url: '/reports/list/deliverreports',
      show: false
    },
    listDocument: {
      url: '/document/list',
      show: false
    }
  };
  showClaimGroup = false;
  showContractorGroup = false;
  showCompanyGroup = false;
  showStatGroup = false;
  getUserSub: Subscription;
  constructor(loginservice: LoginService) {
    this.isEmployee = false;
    this.isClient = false;
    this._userid = JSON.parse(window.atob(sessionStorage.getItem('_userseggac')));
    this.checkRoles();
    this.getUserSub = loginservice.getUserSeggac().subscribe(_userid => {
      this._userid = _userid;
      this.checkRoles();
    });

    $(function () {

      // scripts for "menu-left" module

      /////////////////////////////////////////////////////////////////////////////////////////
      // add backdrop

      $('.cat__menu-left').after('<div class="cat__menu-left__backdrop cat__menu-left__action--backdrop-toggle"><!-- --></div>');

      /////////////////////////////////////////////////////////////////////////////////////////
      // submenu

      var menus = $('.cat__menu-left__submenu'); 
      menus.toggleClass('cat__menu-left__submenu--toggled');
      menus.find('> .cat__menu-left__list').slideToggle(200);

      $('.cat__menu-left__submenu > a').on('click', function () {
        if ($('body').hasClass('cat__config--vertical') || $('body').width() < 768) {
          var parent = $(this).parent(),
            opened = $('.cat__menu-left__submenu--toggled');

          if (!parent.hasClass('cat__menu-left__submenu--toggled') && !parent.parent().closest('.cat__menu-left__submenu').length)
            //opened.removeClass('cat__menu-left__submenu--toggled').find('> .cat__menu-left__list').slideUp(200);
          parent.toggleClass('cat__menu-left__submenu--toggled');
          parent.find('> .cat__menu-left__list').slideToggle(200);
        }
      });
  
      /////////////////////////////////////////////////////////////////////////////////////////
      // toggle menu
      $('.cat__menu-left__action--menu-toggle').on('click', function () {
        if ($('body').width() < 768) {
          $('body').toggleClass('cat__menu-left--visible--mobile');
        } else {
          $('body').toggleClass('cat__menu-left--visible');
        }
      })

      $('.cat__menu-left__action--backdrop-toggle').on('click', function () {
        $('body').removeClass('cat__menu-left--visible--mobile');
      })
      
      $('.cat__menu-left__submenu .cat__menu-left__item').on('click', function () {
        $('body').removeClass('cat__menu-left--visible--mobile');
      })

      /////////////////////////////////////////////////////////////////////////////////////////
      // colorful menu

      var colorfulClasses = 'cat__menu-left--colorful--primary cat__menu-left--colorful--secondary cat__menu-left--colorful--primary cat__menu-left--colorful--default cat__menu-left--colorful--info cat__menu-left--colorful--success cat__menu-left--colorful--warning cat__menu-left--colorful--danger cat__menu-left--colorful--yellow',
        colorfulClassesArray = colorfulClasses.split(' ');

      function setColorfulClasses() {
        $('.cat__menu-left__list--root > .cat__menu-left__item').each(function () {
          var randomClass = colorfulClassesArray[Math.floor(Math.random() * colorfulClassesArray.length)];
          $(this).addClass(randomClass);
        })
      }

      function removeColorfulClasses() {
        $('.cat__menu-left__list--root > .cat__menu-left__item').removeClass(colorfulClasses);
      }

      if ($('body').hasClass('cat__menu-left--colorful')) {
        setColorfulClasses();
      }

      $('body').on('setColorfulClasses', function () {
        setColorfulClasses();
      });

      $('body').on('removeColorfulClasses', function () {
        removeColorfulClasses();
      });
    });

  }
  ngOnInit() {

  }

  ngOnDestroy() {
    this.getUserSub.unsubscribe();
  }

  checkRoles() {
    var userdata = this._userid && this._userid.userdata;
    this.isEmployee = userdata && userdata.usertypeid && userdata.usertypeid.usertypeid == 2;
    this.isClient = userdata && userdata.usertypeid && userdata.usertypeid.usertypeid == 1;
    if (!userdata) {
      for (const key in this.modules) {
        if (this.modules.hasOwnProperty(key)) {
          this.modules[key].show = false;
        }
      }
      this.showClaimGroup = false;
      this.showContractorGroup = false;
      this.showCompanyGroup = false;
      this.showStatGroup = false;
      return;
    }
    if (userdata.usertypeid && (userdata.usertypeid.usertypeid == 2 || userdata.usertypeid.usertypeid == 1)) {
      var rol = Array.isArray(userdata.roles) && userdata.roles.length > 0 ? userdata.roles[0] : null;
      if (!rol) {
        for (const key in this.modules) {
          if (this.modules.hasOwnProperty(key)) {
            this.modules[key].show = false;
          }
        }
        this.showClaimGroup = false;
        this.showContractorGroup = false;
        this.showCompanyGroup = false;
        this.showStatGroup = false;
        return;
      }
      var showGroups = [false, false, false, false];
      for (const key in this.modules) {
        if (this.modules.hasOwnProperty(key)) {
          this.modules[key].show = rol.modules.indexOf(this.modules[key].url) !== -1;
          if (this.modules[key].groupid !== undefined && this.modules[key].show) {
            showGroups[this.modules[key].groupid] = true;
          }
        }
      }
      this.showClaimGroup = showGroups[0];
      this.showContractorGroup = showGroups[1];
      this.showCompanyGroup = showGroups[2];
      this.showStatGroup = showGroups[3];
    }
  }
}
