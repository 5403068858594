import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { User } from '../../entities/user';
import { SeggacService } from '../../services/seggac.service';
import { LoginService } from '../../services/login.service';
import { md5 } from '../../services/md5';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ImageComponent } from 'src/app/components/image/image.component';
import { MatDialog } from '@angular/material/dialog';

declare var $: any;
declare var jQuery: any;
declare var window: any;

@Component({
  selector: 'profile',
  templateUrl: './user_profile.html',
  styles: [`
        em {float:right; color:#E05C65; padding-left: 10px;}
        .error ::-webkit-input-placeholder {color: #999;}
        .error ::-moz-placeholder {color: #999;}
        .error :-moz-placeholder {color: #999;}
        .error :ms-input-placeholder {color: #999;}`
  ],
  providers: [SeggacService, LoginService]
})
export class Profile implements OnInit, AfterViewInit {
  public form: FormGroup;
  public formRecover: FormGroup;
  public usuario: AbstractControl;
  public password: AbstractControl;
  public usuarioRecover: AbstractControl;
  public submitted: boolean = false;
  public static MODODEV = false;
  public _recoverPass: boolean = false;
  public initparam: string;
  public recoverMessage: string = "";
  _subscription: Subscription;
  public _userid: User;
  _useremployee: any;
  isEditable:boolean=true;

  profileForm: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  password1: FormControl;
  password2: FormControl;
  email: FormControl;
  currentPass: string;


  imageChangedEvent: any = '';
  croppedImage: any = '';


  constructor(
    fb: FormBuilder,
    private _router: Router,
    private SeggacService: SeggacService,
    private LoginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
    ) {
    this.getUserSeggac();
  }

  ngAfterViewInit() {
    $("div").removeClass("disabledwindow");
  }

  ngOnInit() {
    this.currentPass = this._userid.userdata.password.slice(0,6);
    this.firstName = new FormControl(this._useremployee.firstname, [Validators.required, Validators.pattern('[a-zA-Z].*')]);
    this.lastName = new FormControl(this._useremployee.lastname, [Validators.required, Validators.pattern('[a-zA-Z].*')]);
    this.password1 = new FormControl(this.currentPass, [Validators.required, Validators.minLength(6)]);
    this.password2 = new FormControl(this.currentPass, [Validators.required, Validators.minLength(6)]);
    this.email = new FormControl(this._useremployee.email,  [Validators.required, Validators.pattern('[a-z].*.[@].*')]);

    this.profileForm = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      password1: this.password1,
      password2: this.password2,
      email: this.email
    });

  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    let dialogRef = this.dialog.open(ImageComponent, {
      width: '600px',
      data: {
        imageChangedEvent: event
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.croppedImage) {
        this.croppedImage = result.croppedImage;
      }
    });
  }

  triggerUpload() {
    $('#upload-signature').trigger('click');
  }

  getUserSeggac(){
    this._userid = new User();
    var _userCache = JSON.parse(window.atob(sessionStorage.getItem('_userseggac')));
    this._useremployee = JSON.parse(window.atob(sessionStorage.getItem('_useremployee')));
    this._userid.userdata = _userCache.userdata;
    this._userid.signature = this.croppedImage ? this.croppedImage : _userCache.signature;
  }

  checkPass(formvalue) {
    const objUser = JSON.parse(JSON.stringify(formvalue));
    delete objUser.password2;
    if (objUser.password1 !== this.currentPass) {
      objUser.password1 = md5(objUser.password1);
    } else {
      delete objUser.password1;
    }
    return objUser;
  }

  saveProfile(formvalue) {
    if (this.croppedImage) {
        formvalue.signature = this.croppedImage;
        formvalue.signname = 'sign_' + this._userid.uid + '.png';
        if (this.profileForm.valid) {
          const userObj = this.checkPass(formvalue);
          this.SeggacService.updateUserProfile2(userObj, this._userid.userdata.userid).subscribe(
            res => {
              Swal.fire('Éxito', 'Se guardaron los datos del usuario', 'success');
              const _user = JSON.parse(window.atob(sessionStorage.getItem('_userseggac')));
              _user.userdata.firstname = formvalue.firstName;
              _user.userdata.lastname = formvalue.lastName;
              _user.userdata.password = formvalue.password1;
              _user.userdata.email = formvalue.email;
              _user.signature = this.croppedImage;
              _user.signname = formvalue.signname;
              this._useremployee.firstname = formvalue.firstName;
              this._useremployee.lastname = formvalue.lastName;
              this._useremployee.email = formvalue.email;
              this._useremployee.signname = formvalue.signname;
              sessionStorage.setItem('_userseggac', window.btoa(JSON.stringify(_user)));
              sessionStorage.setItem('_useremployee', window.btoa(JSON.stringify(this._useremployee)));
              this.getUserSeggac();
            },
            err => {
              Swal.fire('Error', 'No se guardaron los datos del usuario', 'error');
            },
            () => {}
          );
        }
    }
    else {
      const userObj = this.checkPass(formvalue);
      this.SeggacService.updateUserProfile2(userObj, this._userid.userdata.userid).subscribe(
        res => {
          Swal.fire('Éxito', 'Se guardaron los datos del usuario', 'success');
          var _user = JSON.parse(window.atob(sessionStorage.getItem('_userseggac')));
          _user.userdata.firstname = formvalue.firstName;
          _user.userdata.lastname = formvalue.lastName;
          _user.userdata.password = formvalue.password1;
          _user.userdata.email = formvalue.email;
          sessionStorage.setItem('_userseggac', window.btoa(JSON.stringify(_user)));
          this.getUserSeggac();
        },
        err => {
          Swal.fire('Error', 'No se guardaron los datos del usuario', 'error');
        },
        () => {}
      );
    }
  }

  enableEdit(){
    $('#validation-firstname').prop('disabled', false);
    $('#validation-lastname').prop('disabled', false);
    $('#validation-password').prop('disabled', false);
    $('#validation-password-confirm').prop('disabled', false);$('#validation-password-confirm').show();
    $('#validation-email').prop('disabled', true);
    this.isEditable=true;
  }
  disableEdit(){
    $('#validation-firstname').prop('disabled', true);
    $('#validation-lastname').prop('disabled', true);
    $('#validation-password').prop('disabled', true);
    $('#validation-password-confirm').prop('disabled', true);$('#validation-password-confirm').hide();
    $('#validation-email').prop('disabled', true);
    this.isEditable=false;
  }

  validateFirstName() {
    return this.firstName.valid || this.firstName.untouched;
  }
  validateLastName() {
    return this.lastName.valid || this.lastName.untouched;
  }
  validatePassword1() {
    return this.password1.valid || this.password1.untouched;
  }
  validatePassword2() {
    return this.password2.valid || this.password2.untouched;
  }
  validatePassword2Equal() {
    return this.password1.value == this.password2.value;
  }
  validateEmail() {
    return this.email.valid || this.email.untouched;
  }

  private getFileBlob(file) {
    var reader = new FileReader();
    return new Promise(function(resolve, reject){
      reader.onload = (function(theFile) {
        return function(e) {
          resolve(e.target.result);
        };
      })(file);
      reader.readAsDataURL(file);
    });
  }
}
