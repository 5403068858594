<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>{{'Company' | translate}}</strong>
    </span>
  </div>
  <div class="card-block">
    <form [formGroup]="myForm" (ngSubmit)="edit(myForm.value, myForm.valid)">
      <div class="row">
        <div class="col-md-6">
          <a><span class="ml-1 company-preview" (click)="openPreview()">
            Vista previa <mat-icon>zoom_in</mat-icon>
          </span></a>
          <div class="row">
            <div class="col-lg-12 mb-5 p-3">
              <mat-form-field class="full-width">
                <mat-label>{{'Company' | translate}}: *</mat-label>
                <input matInput type="text" formControlName="company">
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>{{'Description' | translate}}: *</mat-label>
                <input matInput type="text" formControlName="description">
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>{{'Email' | translate}}: *</mat-label>
                <input matInput type="text" formControlName="email">
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>{{'Phone' | translate}}: *</mat-label>
                <input matInput type="text" formControlName="phone">
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>{{'Identification' | translate}}: *</mat-label>
                <input matInput type="text" formControlName="identification">
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>{{ 'Country' | translate }}: *</mat-label>
                <input matInput (click)="selectCountry()" (input)="selectCountry()" formControlName="country" class="input-size">
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>{{'Address' | translate}}: *</mat-label>
                <input matInput type="text" formControlName="address">
              </mat-form-field>
              <div class="full-width">
                <mat-label>Logo: (Formato requerido: .jpg, .png)</mat-label>
                <input id="upload-logo" type="file" (change)="fileChangeEvent($event)" class="hidden"/>
                <div class="mt-3">
                  <img (click)="triggerUpload()" [src]="croppedImage ? croppedImage : (company.logo? company.logo : 'assets/iconos/placeholder-logo.jpg')" alt="">
                </div>
              </div>
              <div>
                * Campos obligatorios
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!isNative" class="col-md-6 px-5">
          <div class="card plan-container p-5" *ngIf="plan">
            <div class="no-border mb-2">
              <span class="cat__core__title">
                <strong>Datos de la cuenta</strong>
              </span>
            </div>
            <mat-list>
              <mat-list-item>
                Plan actual : {{ plan['plan'] }}
              </mat-list-item>
              <mat-list-item>
                Usuarios : {{ uCount }} / {{ plan['userslimit'] }}
              </mat-list-item>
              <mat-list-item>
                Recibos : {{ rCount }} / {{ rLimit }}
              </mat-list-item>
            </mat-list>
            <div class="mt-3 text-center">
              <a class="btn btn-primary" (click)="triggerChargebeePlan()">Cambiar plan</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-actions text-center m-0">
            <button type="submit" class="btn btn-primary blockcenter mb-4">{{'Save' | translate}}</button>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-actions text-center m-0">
            <button type="button" class="btn btn-default blockcenter" (click)="reset()">Cancelar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<div class="modal fade" id="modalPreview" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
  <div class="modal-dialog resize-full" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel">Vista Previa de un Erecibo</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body user-modal resize-modal">
        <img src="assets/preview-erecibos.jpg" alt="">

      </div>
    </div>
  </div>
</div>