import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login.component';
import { Welcome } from './welcome.component';
import { Logout } from './logout.component';
import { MaterialModule } from 'src/app/material.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

export const routes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'welcome', component: Welcome, pathMatch: 'full' },
  { path: 'logout', component: Logout, pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxDatatableModule

  ],
  declarations: [
    LoginComponent,
    Welcome,
    Logout
  ]
})

export class LoginModule { }
