import { Component, AfterViewInit } from '@angular/core';
import { SeggacService } from '../../services/seggac.service';
import { DateService } from '../../services/date.service';
import { GroupByPipe, ValuesPipe, KeysPipe } from 'ngx-pipes';
import { User } from '../../entities/user';
import { LoginService } from '../../services/login.service';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'servicecli',
  templateUrl: './serviceclient.html',
  providers: [SeggacService, DateService, GroupByPipe, ValuesPipe, KeysPipe]
})

export class ServiceCli implements AfterViewInit{
  asunto: string = "";
  mensaje: string = "";
  _userid: User;
  constructor(private seggacservice: SeggacService, private LoginService: LoginService) {
    this._userid = LoginService.userid;
    this.LoginService.getUserSeggac().subscribe(value => {
      this._userid = value;
    });
  }
  
  ngAfterViewInit() {
    $("div").removeClass("disabledwindow");
  }

  /**
   * Send mail to system admin
   */
  sendMail() {
    this.asunto;
    this.mensaje;
    this._userid;
    if( this.asunto.length === 0 || this.mensaje.length === 0 ) {
      $.notify({
        title: '',
        message: 'Deve llenar todos los campos.'
      }, {
        type: 'info',
        delay: 1000,
        timer: 1000,
        z_index: 2000,
      });
      return;
    }
    this.seggacservice.serviceClient({asunto: this.asunto, mensaje: this.mensaje, username: this._userid.userdata.username, useremail: this._userid.userdata.email, cellphone: this._userid.userdata.cellphone, homephone: this._userid.userdata.homephone}).subscribe(
      res => {
        $.notify({
          title: '',
          message: 'Se envio el mensaje exitosamente.'
        });
      },
      err => {
        $.notify({
          title: '',
          message: 'Error al ingresar los datos.'
        }, {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        });
      });
  }
}

