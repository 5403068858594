import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SeggacService} from '../../../services/seggac.service';
import {TranslateService} from '@ngx-translate/core';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {
  public myForm: FormGroup;
  company: any = {
    companyid: '',
    company: '',
    email: '',
    phone: '',
    description: '',
    identification: '',
    address: ''
  };
  constructor(private _fb: FormBuilder,
              private SeggacService: SeggacService,
              public translate: TranslateService) {
  }

  ngOnInit() {
    this.myForm = this._fb.group({
      company: ['', <any>Validators.required],
      email: ['', <any>Validators.required],
      phone: ['', <any>Validators.required],
      description: ['', <any>Validators.required],
      identification: ['', <any>Validators.required],
      address: ['', <any>Validators.required],
      dbname: ['', <any>Validators.required]
    });
  }

  create(company: any, isValid: Boolean) {
    if (isValid) {
      this.SeggacService.postCompany(company)
        .subscribe((data: any) => {
          },
          err => {
            $.notify({
              title: '<strong>Aviso</strong>',
              message: 'Error en actualización de datos.' + err
            }, {
              type: 'warning',
              delay: 1000,
              timer: 1000,
              z_index: 2000,
            });
          },
          () => {
            $.notify({
              title: '<strong>Aviso</strong>',
              message: 'Se actualizó exitosamente.'
            }, {
              type: 'success',
              delay: 1000,
              timer: 1000,
              z_index: 2000,
            });
          },
        );
    }
  }

  reset() {
    this.myForm.controls['company'].setValue(this.company.company);
    this.myForm.controls['email'].setValue(this.company.email);
    this.myForm.controls['phone'].setValue(this.company.phone);
    this.myForm.controls['description'].setValue(this.company.description);
    this.myForm.controls['identification'].setValue(this.company.identification);
    this.myForm.controls['address'].setValue(this.company.address);
    this.myForm.controls['dbname'].setValue(this.company.dbname);
  }
}
