import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SeggacService } from '../../../services/seggac.service';

import { User } from '../../../entities/user';
import { ActivatedRoute } from '@angular/router';


declare var $: any;

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidateComponent implements OnInit {
  public str_client = 'Client';
  id: string
  base64;
  voucher_print: any = {
    voucherid: '',
    date: '',
    amount: 0,
    amount_letters: '',
    deposit: 0,
    residue: 0,
    clientname: '',
    concept: '',
    paymenttype: ''
  };

  company: any = {
    company: '',
    email: '',
    phone: '',
    description: '',
    identification: '',
    address: ''
  };

  amount_letter_div = false;
  amount_letter_first = '';
  amount_letter_second = '';
  cheque_id = 2;
  cheque_is = false;
  cheque_bank = '';
  cheque_number = '';
  hidden_button_msj = false;

  email_data: any = {
    client_email: '',
    client_emailreply: '',
    userid: ''
  }

  _userid: User;
  _useremployee: any;
  myForm: FormGroup;
  identificationtypes = [];
  clienteidselected: any;
  bankidselected: any;
  today: number = Date.now();
  otherPaymentType = null;

  public paymentconcept_array: any = [];
  public paymenttype_array: any = [];
  public client_array: any = [];
  public clientsModal: any = [];
  public bank_array: any = [];
  public voucher_residue = '';
  password1: string;
  password2: string;
  checkboxValue: boolean = false;
  selectBankDisabled: boolean = true;
  rCount;
  lastId;
  rLimit;
  plan;
  country;
  initialCount;

  constructor(
    private SeggacService: SeggacService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id);
    this.loadReceipt(this.id);
  }
  // Send email
  loadReceipt(id) {
    this.email_data = { ...this.email_data, ...this.voucher_print};
    const emaildata = {
      receipt_id: id,
    };
    this.SeggacService.loadPdf(emaildata)
      .subscribe(
        (data: any[]) => {
          this.base64 = data['pdf'];
        }, err => {
          $.notify({
            title: '',
            message: 'Ha ocurrido un error.'
          }, {
            type: 'warning',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          });
        },
      );
  }
}
