import { AfterContentInit, Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SeggacService } from '../../services/seggac.service';
import { DateService } from '../../services/date.service';
import { Project, Company } from '../../models/seggac-model';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'cat-project',
  templateUrl: './projects.html',
  providers: [ SeggacService, DateService ],
  encapsulation: ViewEncapsulation.None
})

export class CompanyProjects implements AfterContentInit, OnInit, AfterViewInit {
  temp = [];
  projects: Project[];
  companies: Company[];
  timeout: any;
  newProject: any = {
    projectid: "",
    project: "",
    active: true,
    changedate: "",
    companyid: -1
  }

  editProject: any = {
    projectid: "",
    project: "",
    active: true,
    changedate: "",
    companyid: -1
  }

  @ViewChild('table') table: any;
  ngAfterContentInit() {
    this.getProjects();
    this.getCompanies();
  }

  ngAfterViewInit() {
  }
  ngOnInit() {
  }
  constructor(private SeggacService: SeggacService, private DateService: DateService) {
  }

  getProjects() {
    this.SeggacService.getProjects().startWith(new Array<Project>())
      .subscribe(
        (data: Project[]) => {
          this.temp = [...data];
          this.projects = data;
        });
  }

  getCompanies() {
    this.SeggacService.getCompanies().startWith(new Array<Company>())
      .subscribe(
        (data: Company[]) => {
          this.companies = data;
        });
  }

  /**
   * Show or Hide detail Row.
   * @param row
   */
  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  /**
   * Show detail Row.
   * @param event
   */
  onDetailToggle(event) {
  }

  /**
   * Give a timeout when page from table is changed.
   * @param event
   */
  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }

  /**
   * Return Date in format yyyy/mm/dd.
   * @param date
   * @returns string: date
   */
  convertDate ( date ) {
    return this.DateService.convertDate(date);
  }

  /**
   * Update Project from Table.
   * @param event
   */
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
      if (d.project && d.project.toString().toLowerCase().indexOf(val) !== -1 || !val)
        return true;
      else if (d.companyid && d.companyid.company.toString().toLowerCase().indexOf(val) !== -1 || !val)
        return true;
    });
    this.projects = temp;
    this.table.offset = 0;
  }

  openNewProject () {
    this.newProject = {
      projectid: "",
      project: "",
      active: true,
      changedate: "",
      companyid: -1
    }
    $('#modalNewProject').modal({ show: true, backdrop: 'static', keyboard: false });
  }

  openEditProject (row) {
    this.editProject = {
      projectid: "",
      project: "",
      active: true,
      changedate: "",
      companyid: -1,
      company: ""
    }
    if(row.companyid) {
      this.editProject.companyid = row.companyid.companyid;
      this.editProject.company = row.companyid.company;
    }
    this.editProject.project = row.project;
    this.editProject.projectid = row.projectid;
    $('#modalEditProject').modal({ show: true, backdrop: 'static', keyboard: false });
  }

  closeNewProject () {
    $('#modalNewProject').modal('hide');  
  }

  closeEditProject () {
    $('#modalEditProject').modal('hide');  
  }

  validateNewProject() {
    if(!this.newProject.project || this.newProject.project.length == 0) {
      $.notify({
        title: '',
        message: 'Escriba un nombre de Proyecto.'
      },{
        type: 'warning',
        delay: 1000,
        timer: 1000,
        z_index: 2000,
      });
      return false;
    }
    return true;
  }

  validateEditProject() {
    if(!this.editProject.projectid || this.editProject.project.length == 0) {
      $.notify({
        title: '',
        message: 'Escriba un nombre de Proyecto.'
      },{
        type: 'warning',
        delay: 1000,
        timer: 1000,
        z_index: 2000,
      });
      return false;
    }
    return true;
  }

  saveNewProject() {
    if(this.newProject.companyid == -1) {
      delete this.newProject.companyid;
    }
    if (this.validateNewProject()) {
      this.SeggacService.addProject(this.newProject).subscribe(
        res => {
          this.newProject.projectid = res.projectid;
          this.newProject.changedate = res.date.date
          if(this.newProject.companyid) {
            var indexProj = this.companies.findIndex(x => x.companyid==this.newProject.companyid);
            this.newProject.companyid = this.companies[indexProj];
          }
          this.projects.push(this.newProject);
          this.projects=[...this.projects];
          this.temp.push(this.newProject);
          this.temp = [...this.temp];
          $('#modalNewProject').modal('hide');
        },
        err => {
          console.log("Error occured");
          $.notify({
            title: '',
            message: 'Error al ingresar los datos.'
          }, {
            type: 'warning',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          });
        }, () => {
          $.notify({
            title: '',
            message: 'Se Ingreso el Proyecto exitosamente.'
          }, {
            type: 'success',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          });
        });
    }
  }

  saveEditProject() {
    if(+this.editProject.companyid == -1) {
      delete this.editProject.companyid;
    }
    if (this.validateEditProject()) {
      this.SeggacService.editProject(this.editProject,this.editProject.projectid).subscribe(
        res => {
          if(this.editProject.companyid) {
            var indexProj = this.companies.findIndex(x => x.companyid==+this.editProject.companyid);
            this.editProject.companyid = this.companies[indexProj];
          }
          this.editProject.changedate = res.date.date;
          var index = this.projects.findIndex(x => x.projectid==this.editProject.projectid);
          this.projects[index] = this.editProject;
          this.projects = [...this.projects];
          var index2 = this.temp.findIndex(x => x.projectid==this.editProject.projectid);
          this.temp[index2] = this.editProject;
          this.temp = [...this.temp];
          $('#modalEditProject').modal('hide');
        },
        err => {
          console.log("Error occured");
          $.notify({
            title: '',
            message: 'Error al ingresar los datos.'
          }, {
            type: 'warning',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          });
        }, () => {
          $.notify({
            title: '',
            message: 'Se Edito el Proyecto exitosamente.'
          }, {
            type: 'success',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          });
        });
    }
  }

  deleteProject(rowIndex) {

    Swal.fire({
      title: "¿Está seguro de eliminar el proyecto?",
      text: "Una vez eliminado el proyecto no se podra recuperar!",
      icon: "warning",
      /* @TODO buttons: [ 'Cancelar', 'OK'],
      dangerMode: true,*/
    })
      .then((willDelete) => {
        if (willDelete) {
          this.SeggacService.deleteProject(+this.projects[rowIndex].projectid).subscribe(
            res => {
              this.projects.splice(rowIndex, 1);
              this.projects=[...this.projects];
              this.temp.splice(rowIndex, 1);
              this.temp=[...this.temp];
            },
            err => {
              console.log("Error occured");
              $.notify({
                title: '',
                message: 'Error al eliminar el Proyecto.'
              }, {
                type: 'warning',
                delay: 1000,
                timer: 1000,
                z_index: 2000,
              });
            }, () => {
              Swal.fire({
                title: 'Su proyecto ha sido eliminado!',
                icon: 'success',
              });
            });

        } else {
          Swal.fire('Se canceló la eliminación del proyecto!');
        }
      });
  }

}

