import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ClientList } from './list-client.page';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { CanActivateViaAuthGuard } from  '../../security/canactivateviaauthguard'
import { InlineSVGModule } from 'ng-inline-svg';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../sharedmodule/sharedmodule.module';
import { MaterialModule } from 'src/app/material.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

export const routes: Routes = [
  { path: 'client/list',
    component: ClientList,
    canActivate: [
      CanActivateViaAuthGuard
    ]
  }
];

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    NgxDatatableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    MaterialModule
  ],
  declarations: [
    ClientList
  ],
})

export class ClientModule { }
