import { Component, OnInit, ViewChild } from '@angular/core';
import { SeggacService } from "../../services/seggac.service";
import { Router, ActivatedRoute } from '@angular/router';
import { PagesConfig } from '../../entities/pages.config';
import { LoginService } from '../../services/login.service';
import { User } from '../../entities/user';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Capacitor } from '@capacitor/core';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'welcome',
  templateUrl: './welcome.html',
  styleUrls: ['./welcome.css'],
  providers: [ SeggacService ],

})
export class Welcome implements OnInit {
  @ViewChild('table') table: any;
  userCompanies: any;
  isNative: Boolean;
  constructor(public seggacService: SeggacService,
    private loginService: LoginService,
    private _router: Router) {
    this.getUserCompany();
  }

  ngOnInit() {
    this.isNative = Capacitor.isNativePlatform() ? true : false;
    $(function () {
      $('body').addClass('cat__menu-left--visible');  
    });  
  }

  /**
   * Get list of Properties and update the table.
   */
  getUserCompany() {
    this.seggacService.getUserCompany()
      .subscribe(
        (data: any) => {
          this.userCompanies = data;
          if (this.userCompanies.length == 0) {
            this._router.navigateByUrl(PagesConfig.PAGELOGOUT);
          }
          if (this.userCompanies.length == 1) {
            let _userid = JSON.parse(window.atob(sessionStorage.getItem('_userseggac')));
            _userid.userdata.usertypeid = {'usertypeid' : this.userCompanies[0].usertypeid, 'usercompany' : this.userCompanies[0] };
            _userid.userdata.usercompany =  this.userCompanies[0];
            sessionStorage.setItem('_userseggac',window.btoa(JSON.stringify(_userid)));
            sessionStorage.setItem('_usercompany', this.userCompanies[0].companyid);
            sessionStorage.setItem('_logourl', this.userCompanies[0].logocompany);
            var usercompany = this.userCompanies[0];
            if (usercompany.usertypeid == 2 || usercompany.usertypeid == 1 || usercompany.usertypeid == 3) {
              this.seggacService.getUserRoles().subscribe(
                res => {
                  _userid.signature = res['signature'];
                  _userid.signname = res['signname']; 
                  sessionStorage.setItem('_userseggac',window.btoa(JSON.stringify(_userid)));
                  sessionStorage.setItem('_useremployee',window.btoa(JSON.stringify(res['employeedata'])));
                  if (!this.checkRoles(_userid, res))
                    return;
                  this.checkModulesAndNavigate(res);
                },
                err => {
                  this.resetRoles(_userid);
                },
                () => {});
            }
          }
        });
  }

  goToCompany(usercompany) {
    sessionStorage.setItem('_usercompany', usercompany.companyid);
    let _userid = JSON.parse(window.atob(sessionStorage.getItem('_userseggac')));
    _userid.userdata.usertypeid = {'usertypeid' : usercompany.usertypeid, 'usercompany' : usercompany};
    _userid.userdata.usercompany = usercompany;
    sessionStorage.setItem('_userseggac',window.btoa(JSON.stringify(_userid)));
    sessionStorage.setItem('_logourl', usercompany.logocompany);
    if (usercompany.usertypeid == 2 || usercompany.usertypeid == 1 || usercompany.usertypeid == 3) {
      this.seggacService.getUserRoles().subscribe(
        res => {
           _userid.signature = res['signature'];
          _userid.signname = res['signname'];
          sessionStorage.setItem('_userseggac', window.btoa(JSON.stringify(_userid)));
          sessionStorage.setItem('_useremployee', window.btoa(JSON.stringify(res['employeedata'])));
          if (!this.checkRoles(_userid, res)) 
            return;
          this.checkModulesAndNavigate(res);
        },
        err => {
          this.resetRoles(_userid);
        },
        () => {});
    }
  }
  private checkRoles(user, res): boolean {
    if (!res || !Array.isArray(res.roles) || res.roles.length == 0) {
      Swal.fire('Advertencia', 'Usuario no tiene roles asignados', 'warning');
      if (user.userdata.roles !== undefined) {
        delete user.userdata.roles;
        sessionStorage.setItem('_userseggac',window.btoa(JSON.stringify(user)));
        var _user = new User();
        _user.userdata = user.userdata;
        this.loginService.setUserSeggac(_user);
      }
      return false;
    }
    user.userdata.roles = res.roles;
    user.userdata.permissions = res.permissions;
    sessionStorage.setItem('_userseggac',window.btoa(JSON.stringify(user)));
    var _user = new User();
    _user.userdata = user.userdata;
    this.loginService.setUserSeggac(_user);
    return true;
  }
  private resetRoles(user) {
    Swal.fire('Error', 'No se han podido consultar roles del usuario', 'error');
    if (user.userdata.roles !== undefined) {
      delete user.userdata.roles;
      if (user.userdata.permissions !== undefined) { delete user.userdata.permissions; }
      sessionStorage.setItem('_userseggac',window.btoa(JSON.stringify(user)));
      var _user = new User();
      _user.userdata = user.userdata;
      this.loginService.setUserSeggac(_user);
    }
  }
  private checkModulesAndNavigate(res) {
    var roles = res && res.roles;
    var modules = [];
    for (var role of roles) {
      for (var mod of role.modules) {
        var idx = modules.indexOf(mod);
        if (idx == -1)
          modules.push(mod);
      }
    }
    if (modules.length == 0) {
      Swal.fire('Advertencia', 'Usuario no tiene módulos asignados', 'warning');
      return;
    }
    this.seggacService.checkStatus('');

    if (modules.indexOf('/' + PagesConfig.PAGEINICIO) == -1) {
      this._router.navigateByUrl(PagesConfig.PAGEINICIO);
      return;
    }
    this._router.navigateByUrl(PagesConfig.PAGEINICIO);

  }

  isAdmin() {
    return true;
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
  }

  onPage(event) {
  }
}
