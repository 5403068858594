import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.scss']
})
export class BanksComponent implements OnInit {
  searchText : String = '';
  banks: Array<any>;
  banks_initial: Array<any>;
  constructor(
    public dialogRef: MatDialogRef<BanksComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.banks = data.banks;
    this.banks_initial = data.banks;
  }

  ngOnInit(): void {
  }

  filterItems(ev) {
    let val = ev.target.value;
    if (val && val.trim() != '') {
      this.banks = this.banks.filter(item => {
        return (
          item.bankname.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    } else {
      this.banks= this.banks_initial;
    }
  }

  selectBank(bank) {
    this.dialogRef.close({bank: bank});
  }

  closeModal() {
    this.dialogRef.close({});
  }
}
