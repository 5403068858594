import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../entities/user';
import { PagesConfig } from '../../entities/pages.config';
import { LoginService } from '../../services/login.service'
import { md5 } from '../../services/md5';
import Swal from 'sweetalert2';
import { SeggacService } from 'src/app/services/seggac.service';
import { Capacitor } from '@capacitor/core';

declare var UXCam:any;

declare var $: any;
declare var jQuery: any;
declare var window: any;

@Component({
  selector: 'app-login',
  styleUrls: ['./login.css'],
  templateUrl: './login.html'
})
export class LoginComponent implements OnInit, AfterViewInit {
  public form: FormGroup;
  public formRecover: FormGroup;
  public usuario: AbstractControl;
  public password: AbstractControl;
  public usuarioRecover: AbstractControl;
  public submitted = false;
  public MODODEV = false;
  public _recoverPass = false;
  public initparam: string;
  public recoverMessage = '';
  currentyear = (new Date()).getFullYear();
  mensaje_login = '';
  showLogin = false;

  constructor(fb: FormBuilder, private _router: Router, private loginService: LoginService, private activatedRoute: ActivatedRoute,
    private seggacService: SeggacService) {
    this.form = fb.group({
      'usuario': ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    });
    this.usuario = this.form.controls['usuario'];
    this.password = this.form.controls['password'];

    this.formRecover = fb.group({
      'usuarioRecover': ['', Validators.email]
    });
    this.usuarioRecover = this.formRecover.controls['usuarioRecover'];
    this.activatedRoute.queryParams.subscribe(params => {
      this.initparam = params['queryurl'];
    });
  }

  ngAfterViewInit() {
    $('div').removeClass('disabledwindow');
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (sessionStorage.getItem('_userseggac')) {
        let _usu = new User();
        const _userCache = JSON.parse(window.atob(sessionStorage.getItem('_userseggac')));
        if (_userCache.userdata) {
          _usu.userdata = _userCache.userdata;
          this.loginService.setUserSeggac(_usu);
          this.initparam === undefined ? this._router.navigateByUrl(PagesConfig.PAGEINICIO) :
            this._router.navigateByUrl(PagesConfig.PAGEINICIO + '?id=' + this.initparam);
        } else {
          sessionStorage.removeItem('_userseggac');
          this.showLogin = true;
        }
      }
    });
  }

  public onSubmit(values: Object): void {
    this.submitted = true;
    if (this._recoverPass) return;
    if (this.form.valid) {
      var _usu = new User();
      _usu.uid = this.usuario.value;
      _usu.userPassword = md5(this.password.value);
      if (this.MODODEV) {
        this.initparam === undefined ? this._router.navigateByUrl(PagesConfig.PAGEINICIO) :
          this._router.navigateByUrl(PagesConfig.PAGEINICIO + '?id=' + this.initparam);
      }
      else {
        this.loginService.loginUser(_usu.uid,_usu.userPassword).subscribe(
          res => {
            if(res['token']) {
              _usu.userdata = res['userdata'][0] ? res['userdata'][0] : res['userdata'];
              _usu.token = res['token'];
              sessionStorage.setItem('_userseggac', window.btoa(JSON.stringify(_usu)));
              this.loginService.setUserSeggac(_usu);
            } else {
              $.notify({
                title: '',
                message: 'Las credenciales ingresadas son incorrectas.'
              }, {
                type: 'warning',
                delay: 1000,
                timer: 1000,
                z_index: 2000,
              });
            }
          },
          err => {
            $.notify({
              title: '',
              message: 'Las credenciales ingresadas son incorrectas.'
            }, {
              type: 'warning',
              delay: 1000,
              timer: 1000,
              z_index: 2000,
            });
          },
          () => {
            let _currentCompany = sessionStorage.getItem('_usercompany');
            let user = JSON.parse(window.atob(sessionStorage.getItem("_userseggac"))).userdata;
            if (Capacitor.isNativePlatform()) {
              UXCam.setUserIdentity(user.username)
            }

            if (_currentCompany) {
              this.seggacService.checkStatus('');
              this.initparam === undefined ? this._router.navigateByUrl(PagesConfig.PAGEINICIO) :
              this._router.navigateByUrl(PagesConfig.PAGEINICIO + '?id=' + this.initparam);
            }
            else {
              this._router.navigateByUrl(PagesConfig.PAGEWELCOME);
            }
          }
        );
      }
    }
  }

  /**
   *
   */
  recoverPass() {
    this._recoverPass = !this._recoverPass;
    this.formRecover.value.usuarioRecover = '';
    this.recoverMessage = '';
  }

  /**
   *
   * @param values
   */
  onSubmitRecover(values: any): void {
    if (!this._recoverPass) return;
    this.loginService.recoverUser(values.usuarioRecover).subscribe(
      res => {
        Swal.fire({
          title: 'Su solicitud fue recibida!',
          text: 'Si la dirección de email ingresada es correcta se le enviará a esta la información necesaria para establecer' +
          ' una nueva contraseña.'
        });
      },
      err => {
        $.notify({
          title: '',
          message: 'Ocurrió un error al enviar el correo.'
        }, {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        });
      } , () => {
        this._router.navigateByUrl(PagesConfig.PAGEINICIO);
      }
    );
  }
}
