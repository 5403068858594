<!-- START: tables/editable-tables -->
<section class="card">
  <div class="card-header">
    <h1 class="cat__core__title">
      <strong>Proyectos</strong>
    </h1>
  </div>
  <div class="col-md-8 col-lg-6 push-md-2 push-lg-3 card-search">
    <div class="row">
      <div class="card-block col-md-12">
        <div class="form-input-icon form-input-icon-right">
          <i class="icmn-search"></i>
          <input matInput type="text" id="searchClient" class="form-control" placeholder="Buscar" (keyup)='updateFilter($event)'>
        </div>
      </div>
      <!--<div class="card-block col-md-4">
        <button class="btn btn-primary" type="button" title="Agregar Proyecto" (click)="openNewProject()">Nuevo Proyecto</button>
      </div>-->
    </div>
  </div>
  <div class="card-block">
    <div class="row">
      <div class="col-lg-12">
        <ngx-datatable #table [rows]='projects' class='material expandable' [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
           [scrollbarH]="false" [rowHeight]="50" [limit]="10" (page)="onPage($event)" [messages]="{emptyMessage: 'Cargando Datos',  totalMessage: 'Total'}">
          <ngx-datatable-row-detail [rowHeight]="'auto'" #myDetailRow (toggle)="onDetailToggle($event)">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
              <div style="padding-left:60px; font-size:14px">
                <!-- <p><span *ngIf="!listColumnProgConst.length>0">Prog. Const.: {{convertDate(row.dateschedule1)}} </span></p>
                  <p><span *ngIf="!listColumnProgSales.length>0">Prog. Ventas: {{convertDate(row.dateschedule2)}} </span></p>
                  <p><span *ngIf="!listColumnDelivery.length>0">Entrega: {{ convertDate(row.datedeliver) }} </span></p>
                  <p><span *ngIf="!listColumnState.length>0">Estado: {{ row.active?'Activo':'Inactivo' }} </span></p>
                  <p><span *ngIf="!listColumnDate.length>0">Fecha: {{ convertDate(row.changedate) }} </span></p> -->
              </div>
            </ng-template>
          </ngx-datatable-row-detail>
          <ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
              <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Ver Detalles" (click)="toggleExpandRow(row)" class="desktop-hidden-1200">
              </a>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Proyecto" [sortable]="true" [prop]="'project'" [flexGrow]="1" [width]="70">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" >{{column.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Compañia" [sortable]="true" [prop]="'companyid.company'" [flexGrow]="1" [width]="80">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()">{{column.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Fecha" [sortable]="true" [prop]="'changedate'" [flexGrow]="1" [width]="80">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()">{{column.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{ convertDate(value) }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Operaciones" [sortable]="false" [width]="60" [flexGrow]="1">
            <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
              <span>
                <button class="btn btn-icon btn-outline-primary btn-link" (click) = "openEditProject(row)">
                  <mat-icon>edit</mat-icon>
                </button>
              </span>
              <span>
                <button class="btn btn-icon btn-outline-danger btn-link" (click) = "deleteProject(rowIndex)">
                  <mat-icon>delete</mat-icon>
                </button>
              </span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="modalNewProject" role="dialog" aria-labelledby="" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!--<h5 class="modal-title">Nuevo Proyecto</h5>-->
        <button type="button" class="close" (click)="closeNewProject()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body resize-modal">
        <div class="form-panel">
          <div class="form-horizontal style-form">
            <mat-form-field class="">
              <mat-label class="col-md-5 col-form-label right">Nombre de Proyecto:</mat-label>
              <div class="col-sm-6">
                <input matInput type="text" [(ngModel)]="newProject.project">
              </div>
            </mat-form-field>
            <mat-form-field class="">
              <mat-label class="col-md-5 col-form-label right">Compañía:</mat-label>
              <div class="col-md-6">
                <div class="col-md-12">
                  <select class="form-control" [(ngModel)]="newProject.companyid">
                    <option value="-1" selected>Seleccione</option>
                    <option *ngFor="let comp of companies" [value]="comp.companyid">{{comp.company}}</option>
                  </select>
                </div>
              </div>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" (click)="closeNewProject()" title="Cerrar Ventana">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="saveNewProject()" title="Guardar Proyecto">Guardar Proyecto</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalEditProject" role="dialog" aria-labelledby="" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">Editar Proyecto</h5>
        <button type="button" class="close" (click)="closeEditProject()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body resize-modal">
        <div class="form-panel">
          <div class="form-horizontal style-form">
            <mat-form-field class="">
              <mat-label class="col-md-5 col-form-label right">Nombre de Proyecto:</mat-label>
              <div class="col-sm-6">
                <input matInput type="text" [(ngModel)]="editProject.project">
              </div>
            </mat-form-field>
            <mat-form-field class="">
              <mat-label class="col-md-5 col-form-label right">Compañía:</mat-label>
              <div class="col-md-6 col-form-label">{{ editProject.company }}
              </div>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" (click)="closeEditProject()" title="Cerrar Ventana">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="saveEditProject()" title="Guardar Proyecto">Editar Proyecto</button>
      </div>
    </div>
  </div>
</div>
