<mat-dialog-content class="full-height">
  <div class="card-header modal-header">
    <button type="button" (click)="openNewClient()" class="add-client-button mt-2">
      <span class="svg-icon client-icon-size client-icon" [inlineSVG]="'assets/iconos/plus-flat.svg'" ></span>
    </button>  
    <h3 class="modal-title">Buscar Cliente</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <section class="card modal-list">
    <div class="pt-5">
      <input [(ngModel)]="searchText" (input)="filterItems($event)" placeholder="NOMBRE / EMPRESA / ID" class=" form-control input-rounded">
      <mat-nav-list class="mt-5">
        <ng-container *ngFor="let client of clients">
          <a mat-list-item (click)="selectClient(client)">
            <div style="display: block;">
              <div class="font-blue">
                {{ client.text }}
              </div>
              <div>
                <small>{{ client.identification }}</small>
              </div>
            </div>
          </a>
        </ng-container>
      </mat-nav-list>
    </div>
  </section>
</mat-dialog-content>   
