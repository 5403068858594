import { Component, OnInit, Inject } from '@angular/core';
import { SeggacService } from '../../services/seggac.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-companymodal',
  templateUrl: './companymodal.component.html',
  styleUrls: ['./companymodal.component.css']
})

export class CompanymodalComponent implements OnInit {
  company;
  checkboxValue:boolean = false;
  identificationtypeCurrent;
  identificationtypes;
  identificationtypesEmpresa = [];
  identificationtypesPersona = [];
  companytypes = [];
  isEdit = false;
  newCompany: any;

  constructor(
    private SeggacService: SeggacService,
    public dialogRef: MatDialogRef<CompanymodalComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data && data.company) {
      this.isEdit = true;
      this.newCompany = data.company;
      if ( this.newCompany.companytypeid == 2 ) {
        this.identificationtypeCurrent = this.identificationtypesEmpresa;
      } else {
        this.identificationtypeCurrent = this.identificationtypesPersona;
      }
    } else {
      this.limpiarNewCompany();
      this.newCompany = {
        active: true,
        companyid: "",
        identification: "",
        displayname: "",
        firstname: "",
        lastname: "",
        email: "",
        sendreadingemail: "",
        homephone: "",
        officephone: "",
        cellphone: "",
        identificationtypeid: ""
      };
    }
  }

  ngOnInit() {
  }

  limpiarNewCompany() {
    this.newCompany = {
      active: true,
      companyid: "",
      identification: "",
      displayname: "",
      firstname: "",
      lastname: "",
      email: "",
      sendreadingemail: "",
      homephone: "",
      officephone: "",
      cellphone: "",
      description: "",
      companytypeid: "",
      identificationtypeid: ""
    };
    this.checkboxValue = false;
  }

  verifyNewCompanyData() {
    if( this.newCompany.firstname.length == 0 ) {
      $.notify({
        title: '<strong>Validacion</strong>',
        message: 'Nombre Incorrecto'
      },{
        type: 'warning',
        delay: 1000,
        timer: 1000,
        z_index: 2000,
      });
      return false;
    }
    if( this.newCompany.companytypeid === 1  && this.newCompany.lastname.length == 0 ) {
      $.notify({
        title: '<strong>Validacion</strong>',
        message: 'Apellido Incorrecto'
      },{
        type: 'warning',
        delay: 1000,
        timer: 1000,
        z_index: 2000,
      });
      return false;
    }
    if( this.newCompany.identification.length == 0 ) {
      $.notify({
        title: '<strong>Validacion</strong>',
        message: 'Numero de Identificaion Incorrecto'
      },{
        type: 'warning',
        delay: 1000,
        timer: 1000,
        z_index: 2000,
      });
      return false;
    }
    if (!this.validateEmail(this.newCompany.email)) {
      $.notify({
        title: '<strong>Validacion</strong>',
        message: 'El correo electronico es Incorrecto'
      }, {
        type: 'warning',
        delay: 1000,
        timer: 1000,
        z_index: 2000,
      });
      return false;
    }

    return true;
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  saveCompany() {
    if (this.verifyNewCompanyData()) {
      if (this.newCompany.lastname) {
        this.newCompany.displayname = this.newCompany.firstname + " " + this.newCompany.lastname;
      } else {
        this.newCompany.displayname = this.newCompany.firstname;
      }
      this.newCompany.checkSendMail = this.checkboxValue;
        this.SeggacService.updateCompany(this.newCompany, this.newCompany.companyid).subscribe(
          res => {
            this.newCompany.changedate = res.date.date;
            var company = JSON.parse(JSON.stringify(this.newCompany));
            this.closeModal(company);
          },
          err => {
            $.notify({
              title: '',
              message: 'Error al ingresar los datos.'
            },{
              type: 'warning',
              delay: 1000,
              timer: 1000,
              z_index: 2000,
            });
          }, () => {
            $.notify({
              title: '',
              message: 'Se editó el companye exitosamente.'
            },{
              type: 'success',
              delay: 1000,
              timer: 1000,
              z_index: 2000,
            });
            this.limpiarNewCompany();
          }
        ); 

    }
  }
  closeModal(company = null) {
    this.limpiarNewCompany();
    if (company) {
      this.dialogRef.close({company: company});
    }
    else {
      this.dialogRef.close();
    }
  }
}
