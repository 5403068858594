import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Share } from '@capacitor/share';
import write_blob from 'capacitor-blob-writer';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
@Injectable({ providedIn: 'root' })
export class XlsxService {

  constructor(
    private socialSharing: SocialSharing
  ) { }

  async exportToExcel(data, filename) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, filename);
    const excelBuffer = XLSX.writeFile(wb, filename + '.xlsx');
  }
}
