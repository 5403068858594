import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SeggacService} from '../../../services/seggac.service';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-paymentconcept',
  templateUrl: './paymentconcept.component.html',
  styleUrls: ['./paymentconcept.component.css']
})
export class PaymentconceptComponent implements OnInit {

  public myForm: FormGroup;
  public paymentconcept_array: any = [];
  public id_edit: any;
  constructor(
    private _fb: FormBuilder,
    private SeggacService: SeggacService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.getPaymentConcept();
    this.myForm = this._fb.group({
      paymentconceptname: ['', <any>Validators.required]
    });
  }

  getPaymentConcept() {
    this.SeggacService.getPaymentConcept()
      .subscribe(
        (data: any) => {
          this.paymentconcept_array = data;
      });
  }

  register(paymentconcept: any, isValid: Boolean) {
    if (isValid) {
      Swal.fire({
        title: '¿Deseas continuar?',
        text: '',
        icon: 'warning',
        showCloseButton: true
      }).then((willCreate) => {
        if (willCreate.isConfirmed) {
          this.SeggacService.addPaymentConcept(paymentconcept)
          .subscribe(
            (data: any) => {
              this.getPaymentConcept();
          });
        }
      });
    } else {
        alert('Se debe colocar un nombre.');
    }
  }

  openEdit(row) {
    this.id_edit = row.paymentconceptid;
    this.myForm.controls['paymentconceptname'].setValue((row.paymentconceptname));
    $('#modaledit').modal('show');
  }

  edit(paymentconcept: any, isValid: Boolean) {
    if(isValid){
      this.SeggacService.editPaymentConcept(paymentconcept,this.id_edit)
        .subscribe(
          (data: any) => {
            this.getPaymentConcept();
            $('#modaledit').modal('hide');
          });
    } else {
      alert('error');
    }
  }

  delete(row: any) {
    Swal.fire({
      title: '¿Deseas eliminar este elemento?',
      text: 'Una vez eliminado no se podrá recuperar.',
      icon: 'warning',
      showCloseButton: true
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
        this.SeggacService.deletePaymentConcept(row.paymentconceptid)
        .subscribe(
          (data: any) => {
            this.getPaymentConcept();
        });
        }
      }
    );
  }
}
