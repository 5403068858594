import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SeggacService } from '../../../services/seggac.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageComponent } from 'src/app/components/image/image.component';
import { CountriesComponent } from 'src/app/components/countries/countries.component';
import { Capacitor } from '@capacitor/core';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  public country_array: any = [];
  selectCountryEnabled: boolean = true;
  public myForm: FormGroup;
  company: any = {
    companyid: '',
    company: '',
    email: '',
    phone: '',
    description: '',
    identification: '',
    address: '',
    country: ''
  };
  plan;
  addons;
  rCount;
  uCount;
  rLimit;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  isNative: Boolean

  constructor(
    private _fb: FormBuilder,
    private seggacService: SeggacService,
    public translate: TranslateService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.isNative = Capacitor.isNativePlatform() ? true : false;
    this.myForm = this._fb.group({
      company: ['', <any>Validators.required],
      email: ['', <any>Validators.required],
      phone: ['', <any>Validators.required],
      description: ['', <any>Validators.required],
      identification: ['', <any>Validators.required],
      address: ['', <any>Validators.required],
      country: ['', <any>Validators.required],
      countryid: ['', <any>Validators.required],
      logo: ['']
    });
    this.getCountries();
    this.plan = JSON.parse(window.atob(sessionStorage.getItem('_userplan')));
  }

  getCompany() {
    var idcompany = JSON.parse(sessionStorage.getItem('_usercompany'));
    this.seggacService.getCompanyWithDetails(idcompany).subscribe((data: any) => {
      this.company = data;
      this.seggacService.getPlan(data.planid).subscribe((res: any) => {
        this.plan = res;
        sessionStorage.setItem('_userplan', window.btoa(JSON.stringify(res)));
        this.rLimit = this.seggacService.getRLimit(res, this.company.companyaddon);
        sessionStorage.setItem('_useraddons', window.btoa(JSON.stringify(data.companyaddon)));
      });
      this.seggacService.getReceiptslimit().subscribe((res: any) => {
        this.rCount = res[0]['count(voucherid)'];
        this.uCount = res[0]['count(userid)'];
        sessionStorage.setItem('_receiptscount', window.btoa(JSON.stringify(this.rCount)));
      });
      this.reset();
    });
  }

  openPreview() {
    $('#modalPreview').modal('show');
  }

  edit(company: any, isValid: Boolean) {
    let companyUpdated = company;
    if (this.selectCountryEnabled == false) {
     let companyUpdated =  company;
     delete(companyUpdated.country);
     delete(companyUpdated.countryid);
    } else {
      companyUpdated.country = company.countryid;
      delete(companyUpdated.countryid);
    }
    if (isValid) {
      this.seggacService.updateCompany(companyUpdated, this.company.companyid).subscribe(
        (data: any) => {
          this.refresh();
        },
        err => {
          $.notify({
            title: '',
            message: 'Error en actualización de datos.'
          }, {
            type: 'warning',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          });
        },
        () => {
          $.notify({
            title: '',
            message: 'Se actualizó exitosamente.'
          }, {
            type: 'success',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          });
        }
      );
    }
  }

  reset() {
    this.myForm.controls['company'].setValue(this.company.company);
    this.myForm.controls['email'].setValue(this.company.email);
    this.myForm.controls['phone'].setValue(this.company.phone);
    this.myForm.controls['description'].setValue(this.company.description);
    this.myForm.controls['identification'].setValue(this.company.identification);
    this.myForm.controls['address'].setValue(this.company.address);
    this.myForm.controls['logo'].setValue(this.company.logo);
    if (this.company.country) {
      this.selectCountryEnabled = false;
      let country = this.country_array.filter(item => {
        return (
          item.countryid == this.company.country
        );
      });
  
      this.myForm.controls['country'].setValue(country[0]['country']);
      this.myForm.controls['countryid'].setValue(country[0]['countryid']);
      this.myForm.controls['country'].disable();
      this.myForm.controls['countryid'].disable();
    }

  }

  private refresh() {
    this.company.company = this.myForm.get('company').value;
    this.company.email = this.myForm.get('email').value;
    this.company.phone = this.myForm.get('phone').value;
    this.company.description = this.myForm.get('description').value;
    this.company.identification = this.myForm.get('identification').value;
    this.company.address = this.myForm.get('address').value;
    this.company.countryid = this.myForm.get('countryid').value;
    this.company.country = this.myForm.get('country').value;
    this.company.logo = this.croppedImage ? this.croppedImage : this.company.logo;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    let dialogRef = this.dialog.open(ImageComponent, {
      width: '600px',
      data: {
        imageChangedEvent: event
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.croppedImage) {
        this.croppedImage = result.croppedImage;
        this.myForm.controls['logo'].setValue(result.croppedImage);
      }
    });
  }

  triggerUpload() {
    $('#upload-logo').trigger('click');
  }

  triggerChargebeePlan() {
    $('#chargebee-button-plan')[0].click();
  }
  selectCountry() {
    let dialogRef = this.dialog.open(CountriesComponent, {
      width: '600px',
      height: '90%',
      data: {
        countries : this.country_array
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result['country']) {
        this.myForm.controls['countryid'].setValue(result['country']['countryid']);
        this.myForm.controls['country'].setValue(result['country']['country']);
      } else {
        this.myForm.controls['countryid'].setValue('');
        this.myForm.controls['country'].setValue('');
      }
    });
  }

  getCountries() {
    this.seggacService.getCountry()
      .subscribe(
        (data: any[]) => {
          this.country_array = data;
          this.getCompany();
        });
  }
}
