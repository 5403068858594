export class User {
	public uid: string;
	public userPassword: string;
	public newUserPassword: string;
	public displayName: string;
	public givenName: string;
	public sn: string;
	public userdata: any;
	public token: any;
	public signature: any;
	public signname: any;
	constructor() {}

	public newUser(a: string, b: string){
		this.uid = a;
		this.userPassword = b;
	}
}