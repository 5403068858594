  <div class="modal-header">
    <h3 md-autofocus class="modal-title" id="myModalLabel">{{isEdit?'Editar': 'Nuevo'}} Companye</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <mat-dialog-content>
    <div class="form-panel">
      <div class="form-horizontal style-form">
        <mat-form-field class="full-width">
          <mat-label>Estado:</mat-label>
          <mat-select class="form-control" [(ngModel)]="newCompany.active" autofocus>
            <mat-option [value]=true selected>Activo</mat-option>
            <mat-option [value]=false>Inactivo</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Correo electrónico:</mat-label>
          <input matInput type="text" class="form-control" [(ngModel)]="newCompany.email" pattern="[0-9]+" required>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Nombre:</mat-label>
          <input matInput type="text" class="form-control" [(ngModel)]="newCompany.firstname" placeholder="Ej. Juan">
        </mat-form-field>
        <mat-form-field class="full-width" [hidden]="newCompany.clienttypeid !== 1">
          <mat-label>Apellido:</mat-label>
          <input matInput type="text" class="form-control" [(ngModel)]="newCompany.lastname" placeholder="Ej. Gonzales">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Tipo de Identificación:</mat-label>
          <mat-select [(ngModel)]="newCompany.identificationtypeid">
            <ng-container *ngFor="let c of identificationtypes">
              <mat-option *ngIf="newCompany.clienttypeid == 2 && c.identificationtypeid == 4" [value]="c.identificationtypeid">{{c.identificationtype}}</mat-option>
              <mat-option *ngIf="newCompany.clienttypeid == 1 && (c.identificationtypeid == 2 || c.identificationtypeid == 3)" [value]="c.identificationtypeid">{{c.identificationtype}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Numero de Identificación:</mat-label>
          <input matInput type="text" class="form-control" [(ngModel)]="newCompany.identification" [disabled]="!newCompany.identificationtypeid">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Identificación Interna:</mat-label>
          <input matInput type="text" class="form-control" [(ngModel)]="newCompany.internalcode">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Teléfono 1:</mat-label>
          <input matInput type="text" class="form-control" [(ngModel)]="newCompany.homephone">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Teléfono 2:</mat-label>
          <input matInput type="text" class="form-control" [(ngModel)]="newCompany.officephone">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Teléfono Celular:</mat-label>
          <input matInput type="text" class="form-control" [(ngModel)]="newCompany.cellphone">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="modal-footer">
      <div class="text-center mb-4">
        <button type="button" class="btn btn-primary blockcenter" (click)="saveCompany()" title="Guardar Companye">{{isEdit?'Actualizar':'Guardar'}} Companye</button>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-default blockcenter" data-dismiss="modal" (click)="closeModal()" title="Cerrar Ventana">Cancelar</button>
      </div>
    </div>  
  </mat-dialog-actions>

