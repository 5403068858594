import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CompanyModule } from './company/company.module';
import { ClientModule } from './client/client.module';
import { LoginModule } from './login/login.module'
import { ProfileModule } from './profile/user_profile.module'
import { ServiceCliModule } from './serviceclient/serviceclient.module'
import { SharedModule } from './sharedmodule/sharedmodule.module';
import { UserModule } from './company/user.module';
import { ReceiptModule } from './receipt/receipt.module';
import { RecoverModule } from './recover/recover.module';
import {CommonModule} from '@angular/common';
import { AdminModule } from './admin/admin.module';
import { CurrencyMaskModule } from "ng2-currency-mask";

@NgModule({
  imports: [
    CompanyModule,
    SharedModule,
    ClientModule,
    AdminModule,
    UserModule,
    FormsModule,
    LoginModule,
    ProfileModule,
    ServiceCliModule,
    ReceiptModule,
    RecoverModule,
    CommonModule,
    CurrencyMaskModule
  ]
})

export class StructureModule { }

