import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FooterComponent} from './components/footer/footer.component';
import {TopBarComponent} from './components/top-bar/top-bar.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {InlineSVGModule} from 'ng-inline-svg';
import {MenuLeftComponent} from './components/menu-left/menu-left.component';
import {NgProgressModule} from 'ngx-progressbar';
import { NgProgressHttpModule } from "ngx-progressbar/http";
import {LoginService} from './services/login.service';
import {Servidores} from './services/servidores';
import {ReactiveFormsModule} from '@angular/forms';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import {CommonModule} from '@angular/common';
import {StructureModule} from './structure/structure.module';
import {CanActivateViaAuthGuard} from "./security/canactivateviaauthguard";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { AngularFireModule } from '@angular/fire';
import "@fontsource/nunito";
import { ComponentsModule } from './components/components.module';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ImageCropperModule } from 'ngx-image-cropper';

const config = {
  apiKey: "AIzaSyAj_02BTKfRt4Bz4ebSMbinAQAjT9QKC3M",
  authDomain: "e-recibos.firebaseapp.com",
  projectId: "e-recibos",
  storageBucket: "e-recibos.appspot.com",
  messagingSenderId: "305943228305",
  appId: "1:305943228305:web:9c0f07affb68a1317cf430",
  measurementId: "G-BSYBSFFG38"
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

declare var NProgress: any;

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    TopBarComponent,
    MenuLeftComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    InlineSVGModule,
    AngularFireModule.initializeApp(config),
    NgProgressModule.withConfig({
      spinner: false
    }),
    NgProgressHttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    StructureModule,
    BrowserAnimationsModule,
    MaterialModule,
    ComponentsModule,
    CurrencyMaskModule,
    ImageCropperModule
  ],
  providers: [
    LoginService,
    CanActivateViaAuthGuard,
    Servidores,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    SocialSharing
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
