import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SeggacService} from '../../../services/seggac.service';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';


declare var $: any;
declare var jQuery: any;
@Component({
    selector: 'app-bank',
    templateUrl: './bank.component.html',
    styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit {
    public myForm: FormGroup;
    public bank_array:any = [];
    public id_edit: any;

    constructor(
        private _fb: FormBuilder,
        private SeggacService: SeggacService,
        public translate: TranslateService
    ) { }

    ngOnInit() {
        this.getBank();
        this.myForm = this._fb.group({
            bankname: ['', <any>Validators.required]
        });
    }

    getBank() {
        this.SeggacService.getBank().subscribe(
            (data: any) => {
                    this.bank_array = data;
        });
    }

    register(bank: any, isValid: Boolean) {
        if (isValid) {
            Swal.fire({
                title: '¿Deseas continuar?',
                text: '',
                icon: 'warning',
                showCloseButton: true
            }).then((willCreate) => {
                if (willCreate.isConfirmed) {
                    this.myForm.controls['bankname'].setValue('');
                    this.SeggacService.addBank(bank).subscribe(
                    (data: any) => {
                        this.getBank();
                    });
                }
            });
        } else {
            alert('Se debe colocar un nombre.');
        }
    }

    openEdit(row) {
        this.id_edit = row.bankid;
        this.myForm.controls['bankname'].setValue((row.bankname));
        $('#modaledit').modal('show');
    }

    edit(bank: any, isValid: Boolean) {
        if(isValid){
            this.SeggacService.editBank(bank,this.id_edit)
                .subscribe(
                    (data: any) => {
                        this.getBank();
                        $('#modaledit').modal('hide');
                    });
        } else {
            alert('error');
        }
    }

    delete(row: any) {
        Swal.fire({
          title: '¿Deseas eliminar este elemento?',
          text: 'Una vez eliminado no se podrá recuperar.',
          icon: 'warning',
          showCloseButton: true
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                this.SeggacService.deleteBank(row.bankid)
                .subscribe(
                    (data: any) => {
                        this.getBank();
                    }
                );
            }
          }
        );
    }
}
