<section class="card">
  <div class="card-header no-border">
    <span class="cat__core__title">
      <strong>{{'Payment Concepts' | translate}}</strong>
    </span>
  </div>

  <div class="card-block">
   <div class="row mb-5">
    <div class="col-lg-12">
      <form [formGroup]="myForm" (ngSubmit)="register(myForm.value,myForm.valid)">
       <div class="row">
        <div class="col-md-8 push-md-2">
          <mat-form-field class="full-width">
            <mat-label>{{'Payment Concept Name' | translate}}</mat-label>
            <input matInput type="text" formControlName="paymentconceptname" />
          </mat-form-field>
        </div>
       </div>
       <div class="text-center">
        <button type="submit" class="btn btn-primary blockcenter ">
          {{'Register' | translate}}
        </button>
       </div>
      </form>
    </div>
   </div>
   <div class="row">
    <div class="col-lg-12">
      <div>
       <ngx-datatable #table [rows]='paymentconcept_array' class='material expandable' [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50"
                 [scrollbarH]="false" [rowHeight]="50" [limit]="10" [messages]="{emptyMessage: 'Cargando Datos',  totalMessage: 'Total'}">

        <ngx-datatable-column name="Concept" [flexGrow]="3" [sortable]="true" [prop]="'paymentconceptname'">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
           <span (click)="sort()">Concepto</span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
           <span> {{value}} </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Operations" [flexGrow]="1"  [sortable]="false" [minWidth]="100" [cellClass]="'custom-cell-icons'">
          <ng-template let-column="column" ngx-datatable-header-template>
           <span></span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
           <span>
            <button type="button" class="btn btn-icon btn-outline-primary btn-link" title="{{'Edit' | translate}}" (click)="openEdit(row)"><mat-icon>edit</mat-icon></button>
           </span>
           <span>
              <button type="button" class="btn btn-icon btn-outline-danger btn-link" title="{{'Delete' | translate}}" (click)="delete(row)"><mat-icon>delete</mat-icon></button>
           </span>
          </ng-template>
        </ngx-datatable-column>
       </ngx-datatable>
      </div>
    </div>
   </div>
  </div>


</section>

<div class="modal fade modal-size-large" id="modaledit" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
  <div class="modal-dialog resize-medium" role="document">
   <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="myModalLabel">{{'Edit Payment Concept' | translate}}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
       <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="myForm" (ngSubmit)="edit(myForm.value,myForm.valid)">
    <div class="modal-body resize-modal">
       <div class="form-panel">
        <div class="form-horizontal style-form">
          <mat-form-field class="full-width">
           <mat-label class="col-md-5 col-form-label right">{{'Payment Concept Name' | translate}}:</mat-label>
           <input matInput type="text" formControlName="paymentconceptname">
          </mat-form-field>
        </div>
       </div>
      </div>
      <div class="modal-footer">
        <div class="text-center mb-4">
          <button type="submit" class="btn btn-primary blockcenter">{{'Save' | translate}}</button>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-default blockcenter" data-dismiss="modal" title="Cerrar Ventana">{{'Cancel' | translate}}</button>
        </div>
      </div>

    </form>
   </div>
  </div>
</div>
