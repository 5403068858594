import { Component, Input } from '@angular/core';
import { PagesConfig } from '../../entities/pages.config';
import { Router } from '@angular/router';
import {LoginService} from '../../services/login.service';

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'cat-top-bar',
  templateUrl: './top-bar.component.html'
})
export class TopBarComponent {
  _username: any;

  constructor(private loginService: LoginService, private _router: Router) {
    $(function () {
      $('.menu-toggle').on('click', function () {
        if ($('body').width() < 768) {
          $('body').toggleClass('cat__menu-left--visible--mobile');
        } else {
          $('body').toggleClass('cat__menu-left--visible');
        }
      })
    });
  }

  logout() {
    sessionStorage.removeItem('_userseggac');
    sessionStorage.removeItem('_useremployee');
    sessionStorage.removeItem('_usercompany');
    sessionStorage.removeItem('_userplan');
    sessionStorage.removeItem('_useraddons');
    this.loginService.removeUserSeggac();
    this._router.navigateByUrl(PagesConfig.PAGELOGIN);
    $('body').removeClass('cat__menu-left--visible');
  }

  profile() {
    this._router.navigateByUrl(PagesConfig.PAGEPROFILE);
  }

  switchAccounts() {
    this._router.navigateByUrl(PagesConfig.PAGEWELCOME);
  }
}
