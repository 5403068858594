import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/index';

@Injectable()

export class AuthInterceptorService implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let _currentCompany = '';
    let token = null;
    if (sessionStorage.getItem('_userseggac')) {
      token = JSON.parse(window.atob(sessionStorage.getItem('_userseggac'))).token;
    }
    if (sessionStorage.getItem('_usercompany')) {
      _currentCompany = sessionStorage.getItem('_usercompany');
    }
    let request = req;
    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`,
          Csg: _currentCompany
        }
      });
    }
    return next.handle(request);
  }
}
