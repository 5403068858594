import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompanymodalComponent } from 'src/app/components/companymodal/companymodal.component';
import { DateService } from 'src/app/services/date.service';
import { SeggacService } from 'src/app/services/seggac.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
  companies = [];
  currentCompany: any;
  editCompany: any;
  temp = [];
  @ViewChild('table') table: any;


  constructor(
    private seggacService: SeggacService,
    private dialog: MatDialog,
    private dateService: DateService
  ) { }

  ngOnInit(): void {
    this.getCompanies();
  }

  getCompanies() {
    this.seggacService.getCompanies().subscribe((data: any) => {
      this.companies = data;
    });
  }

  openEditCompany(row:any) {
    this.editCompany = JSON.parse(JSON.stringify(row));
    if ( row.companyid && row.companyid.companyid ) {
      this.editCompany.companyid = row.companyid.companyid;
    }
    if ( row.identificationtypeid && row.identificationtypeid.identificationtypeid ) {
      this.editCompany.identificationtypeid = row.identificationtypeid.identificationtypeid;
    }
    if ( row.clienttypeid ) {
      if (Number.isInteger(row.clienttypeid)) {
        this.editCompany.clienttypeid = row.clienttypeid;
      } else {
        this.editCompany.clienttypeid = row.clienttypeid.clienttypeid;
      }
    }

    let dialogRef = this.dialog.open(CompanymodalComponent, {
      width: '600px',
      height: '90%',
      data: {
        client : this.editCompany
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getEditCompany(result.client);
    });
  }

  getEditCompany(editCompany) {
    var index = this.companies.findIndex(x => x.clientid==editCompany.clientid);
    this.companies[index] = editCompany;
    this.companies = [...this.companies];
    var index2 = this.temp.findIndex(x => x.clientid==editCompany.clientid);
    this.temp[index2] = editCompany;
    this.temp = [...this.temp]
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
  }
  convertDate(date) {
    return this.dateService.convertDate(date);
  }

  onPage(event) {
  }
}
