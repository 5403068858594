import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomDatePicker } from '../../components/utils/datepicker.component';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule
  ],
  declarations: [
    CustomDatePicker,
  ],
  exports: [
    CustomDatePicker,
  ]
})

export class SharedModule {}
