<div id="rangedate">
  <select id="{{idDatePicker}}" class="select2">
    <option *ngFor="let item of lastDatesSelect" [value]="item.id">
    {{item.title}}
    </option>
  </select>
</div>
<div id="{{'datepersonalized' + idDatePicker }}" style="display: none">
  <form #rangeForm="ngForm" (submit)="getRangeDate(rangeForm.value)" autocomplete="off">
    <div class="form-group">
      <mat-label for="datestart">De:</mat-label>   
      <em class="requiredEm" *ngIf="rangeForm.controls.datestart?.value == '' || rangeForm.controls.datestart?.value == 'NaN-NaN-NaN'">Requerido</em>  
      <em class="requiredEm" *ngIf="valideDate()">Debe ser menor que: {{ dateend }}</em>
      <div class="input-group date" id="{{'datetimepicker1' + idDatePicker }}">
        <input [(ngModel)]="datestart" name="datestart" id="datestart" size="16" type="text" value="" class="form-control">
        <span class="input-group-addon">
          <i class="icmn-calendar"></i>
        </span> 
      </div> 
    </div>
    <div class="form-group">
      <mat-label for="dateend">Hasta:</mat-label>   
      <em class="requiredEm" *ngIf="rangeForm.controls.dateend?.value == '' || rangeForm.controls.dateend?.value == 'NaN-NaN-NaN'">Requerido</em>
      <em class="requiredEm" *ngIf="valideDate()">Debe ser mayor que: {{ datestart }}</em>
      <div class="input-group date" id="{{'datetimepicker2' + idDatePicker }}"> 
        <input [(ngModel)]="dateend" name="dateend" id="dateend" size="16" type="text" value="" class="form-control">
        <span class="input-group-addon">
          <i class="icmn-calendar"></i>
        </span>  
      </div>  
    </div>
    <button type="submit" [disabled]="valideForm()" class="btn btn-primary">Filtrar</button>
  </form>
</div>
<span id="{{'rngdates' + idDatePicker }}"></span>

