import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {Servidores} from './servidores';
import {map} from 'rxjs/internal/operators';

@Injectable()
export class DateService {

  public baseUrl: string;
  constructor ( private http: HttpClient, private servidores: Servidores ) {
    this.baseUrl = this.servidores.API;
  }

  /**
   * Convert string date (from Backend) to format yyyy/mm/dd (string)
   * @param date
   */
  convertDate(date: string) {
    if (date === undefined || date.length === 0) { return ''; }
    const dateArray = date.split('-');
    const newDate = dateArray[0] + '/' + dateArray[1] + '/' + dateArray[2].substring(0, 2);
    return newDate;
  }

  /**
   * Convert string date (from Backend) to format dd/mm/yyyy (string)
   * @param date
   */
  convertDate2(date: string) {
    if (date === undefined || date.length === 0) { return ''; }
    const dateArray = date.split('-');
    const newDate = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0].substring(0, 2);
    return newDate;
  }

  /**
   * Convert Date to format yyyy-mm-dd (string)
   * @param date
   */
  formatDate(date: Date) {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const date_ = new Date(date.getTime() + userTimezoneOffset);
    const month = date_.getMonth() + 1; // months from 1-12
    const day = date_.getDate();
    const year = date_.getFullYear();
    return  year + '-' + month + '-' + day;
  }

  /**
   * return true if its the same day between two date
   * @param date
   * @param date2
   */
  isSameDay(date: Date, date2: Date) {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const date_ = new Date(date.getTime() + userTimezoneOffset);
    const month = date_.getMonth() + 1; // months from 1-12
    const day = date_.getDate();
    const year = date_.getFullYear();

    const userTimezoneOffset2 = date2.getTimezoneOffset() * 60000;
    const date_2 = new Date(date2.getTime() + userTimezoneOffset2);
    const month2 = date_2.getMonth() + 1; // months from 1-12
    const day2 = date_2.getDate();
    const year2 = date_2.getFullYear();
    return  month2 == month && day2 == day && year2 == year;
  }

  /**
   * Calculate number of days between two dates
   * @param date
   */
  getDaysBetweenDates(date1: Date, date2: Date) {
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays - 1;
  }

  /**
   * Calculate number of month between two dates
   * @param date
   */
  getMonthsBetweenDates(date1: Date, date2: Date) {
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth() + 1;
    months += date2.getMonth();
    return months <= 0 ? 0 : months;
  }

  /**
   * Calculate number of hours between two dates
   * @param date
   */
  getHoursBetweenDates(date1: Date, date2: Date) {
    return Math.abs(date1.getTime() - date2.getTime()) / 3600000;
  }

  /**
   * Calculate number of minuts between two dates result is 0 to 59
   * @param date
   */
  getMinutsBetweenDates(date1: Date, date2: Date) {
    const diffMs = date1.getTime() - date2.getTime();
    return Math.round(((diffMs % 86400000) % 3600000) / 60000);
  }

  /**
   * Get date from server
   * @param date
   */
  getDateActual(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getDate`, null).pipe(map(res => res));
  }
  /**
   * To compare the date string param with today
   * @param datestr the date string with format YYYY-MM-DD
   */
  compareWithToday(datestr: string): number {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const date = this.convertToDate(datestr);
    if (!date) {
      return NaN;
    }
    if (date.getTime() == today.getTime()) {
      return 0;
    } else {
      if (date < today) {
        return -1;
      }
      else {
        return 1;
      }
    }
  }
  getDateLocal(): string {
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return  year + '-' + month + '-' + day;
  }

  /**
   * From date string with format YYYY-MM-DD in ten first chars
   * @returns null if it can not convert string to date object
   */
  convertToDate(datestr: string): Date {
    const newDate = new Date(datestr);
    return new Date(newDate.setHours(0, 0, 0, 0));
  }
}
