import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-paymentconcepts',
  templateUrl: './paymentconcepts.component.html',
  styleUrls: ['./paymentconcepts.component.scss']
})
export class PaymentconceptsComponent implements OnInit {
  searchText : String = '';
  paymentconcepts: Array<any>;
  paymentconcepts_initial: Array<any>;
  constructor(
    public dialogRef: MatDialogRef<PaymentconceptsComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.paymentconcepts = data.paymentconcepts;
    this.paymentconcepts_initial = data.paymentconcepts;
  }

  ngOnInit(): void {
  }

  filterItems(ev) {
    let val = ev.target.value;
    if (val && val.trim() != '') {
      this.paymentconcepts = this.paymentconcepts.filter(item => {
        return (
          item.paymentconceptname.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    } else {
      this.paymentconcepts= this.paymentconcepts_initial;
    }
  }

  selectPaymentConcept(paymentconcept) {
    this.dialogRef.close({paymentconcept: paymentconcept});
  }

  closeModal() {
    this.dialogRef.close({});
  }
}
