<section class="card">
  <div class="card-block">
    <div class="row">
      <div class="col-md-6 push-md-3 push-lg-3 col-lg-6">
        <div class="no-border mb-5">
          <span class="cat__core__title">
            <strong>Perfil de Usuario</strong> : {{_userid.userdata.firstname }} {{_userid.userdata.lastname}}
          </span>
        </div>
        <div>
          <form [formGroup]="profileForm" (ngSubmit)="saveProfile(profileForm.value)" autocomplete="off" novalidate>
            <mat-form-field class="full-width" [ngClass]="{'error' : validateFirstName() }">
              <mat-label>Nombres:</mat-label>
              <em *ngIf="!validateFirstName() && profileForm.controls.firstName.errors.required">Requerido</em>
              <em *ngIf="!validateFirstName() && profileForm.controls.firstName.errors.pattern">Debe comenzar con una letra</em>
              <input matInput formControlName="firstName" id="firstName" type="text" class="form-control" placeholder="Nombres..." />
            </mat-form-field>
            <mat-form-field class="full-width" [ngClass]="{'error' : validateLastName() }">
              <mat-label>Apellidos:</mat-label>
              <em *ngIf="!validateLastName() && profileForm.controls.lastName.errors.required">Requerido</em>
              <em *ngIf="!validateLastName() && profileForm.controls.lastName.errors.pattern">Debe comenzar con una letra</em>
              <input matInput formControlName="lastName" id="lastName" type="text" class="form-control" placeholder="Apellidos..." />
            </mat-form-field>
            <mat-form-field class="full-width" [ngClass]="{'error' : validatePassword1() }">
              <mat-label>Password:</mat-label>
              <em *ngIf="!validatePassword1() && profileForm.controls.password1.errors.required">Requerido</em>
              <em *ngIf="!validatePassword1() && profileForm.controls.password1.errors.minLength">Longitud mínima de caracteres es 6</em>
              <input matInput formControlName="password1" id="password1" type="password" class="form-control"/>
            </mat-form-field>
            <mat-form-field class="full-width" [ngClass]="{'error' : validatePassword2() }">
              <mat-label>Confirmar Password:</mat-label>
              <em *ngIf="!validatePassword2() && profileForm.controls.password2.errors.required">Requerido</em>
              <em *ngIf="!validatePassword2Equal()">Contraseñas no son iguales </em>
              <em *ngIf="!validatePassword2() && profileForm.controls.password2.errors.minLength">Longitud  mínima de caracteres es 6</em>
              <input matInput formControlName="password2" id="password2" type="password" class="form-control"/>
            </mat-form-field>
            <mat-form-field class="full-width" [ngClass]="{'error' : validateEmail() }">
              <mat-label>Email:</mat-label>
              <em *ngIf="!validateEmail() && profileForm.controls.email.errors.required">Requerido</em>
              <em *ngIf="!validateEmail() && profileForm.controls.email.errors.pattern">No es un email válido</em>
              <input matInput formControlName="email" id="email" type="text" class="form-control"/>
            </mat-form-field>
            <div class="full-width mb-5">
              <mat-label>Firma: (Formato requerido: .jpg, .png | Tamaño máximo: 800 x 800 px)</mat-label>
              <input id="upload-signature" type="file" (change)="fileChangeEvent($event)" class="form-control hidden"/>
              <div>
                <img style="display:block; max-width: 300px;" (click)="triggerUpload()" class="mt-2" [src]="croppedImage ? croppedImage : (_userid.signature ? _userid.signature : 'assets/iconos/placeholder-signature.jpg')"/>
              </div>
            </div>
            <div class="text-center mt-5">
              <button type="submit" [disabled]="profileForm.invalid" class="btn btn-primary blockcenter">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
