<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Usuarios</strong>
    </span>
  </div>
  <div class="col-md-8 col-lg-6 push-md-2 push-lg-3 card-search">
    <div class="row">
      <div class="card-block col-md-12">
        <div class="form-input-icon form-input-icon-right">
          <i class="icmn-search"></i>
          <input type="text" id="searchEmployee" class="form-control input-rounded" placeholder="USUARIO / NOMBRES / APELLIDOS / EMAIL" (keyup)="updateFilter($event)"/>
        </div>
      </div>
      <div class="card-block col-md-12">
        <div class="text-center">
          <button class="btn btn-primary blockcenter" type="button" title="Agregar Usuario" (click)="openNewEmployee()">
            Nuevo Usuario
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-block">
    <div class="row">
      <div class="col-lg-12">
        <div>
          <ngx-datatable
            #table
            [rows]="employees"
            class="material expandable"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [scrollbarH]="false"
            [rowHeight]="50"
            [limit]="10"
            (page)="onPage($event)"
            [messages]="{emptyMessage: 'Cargando Datos',  totalMessage: 'Total'}">
            <ngx-datatable-column name="Username" [sortable]="true" [prop]="'userid.username'" [flexGrow]="1">
              <ng-template
                let-column="column"
                let-sort="sortFn"
                ngx-datatable-header-template>
                <span (click)="sort()">Usuario</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span> {{value}} </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              *ngFor="let col of listColumnFirstName"
              [name]="col.name"
              [sortable]="true"
              [prop]="'firstname'"
              [flexGrow]="1"
              [canAutoResize]="true">
              <ng-template
                let-column="column"
                let-sort="sortFn"
                ngx-datatable-header-template>
                <span (click)="sort()">Nombres</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden-600"> {{value}} </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              *ngFor="let col of listColumnLastName"
              [name]="col.name"
              [sortable]="true"
              [prop]="'lastname'"
              [flexGrow]="1"
              [canAutoResize]="true">
              <ng-template
                let-column="column"
                let-sort="sortFn"
                ngx-datatable-header-template>
                <span (click)="sort()">Apellidos</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span> {{value}} </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              *ngFor="let col of listColumnEmail"
              [name]="col.name"
              [sortable]="true"
              [prop]="'email'"
              [flexGrow]="1"
              [canAutoResize]="true">
              <ng-template
                let-column="column"
                let-sort="sortFn"
                ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span> {{value}} </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              *ngFor="let col of listColumnState"
              [name]="col.name"
              [prop]="'active'"
              [sortable]="false"
              [flexGrow]="1">
              <ng-template
                let-column="column"
                let-sort="sortFn"
                ngx-datatable-header-template>
                <span>{{column.name}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span> {{value?'Activo':'Inactivo'}} </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              *ngFor="let col of listColumnDate"
              [name]="col.name"
              [sortable]="true"
              [prop]="'changedate'"
              [flexGrow]="1">
              <ng-template
                let-column="column"
                let-sort="sortFn"
                ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span> {{ convertDate(value) }} </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="Operaciones"
              [sortable]="false"
              [flexGrow]="1"
              [canAutoResize]="true"
              [cellClass]="'custom-cell-icons'">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span></span>
            </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>
                  <button type="button" class="btn btn-icon btn-outline-primary btn-link" title="Editar Usuario" (click)="openEditEmployee(row)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </span>
                <span>
                  <button
                    type="button"
                    class="btn btn-icon btn-outline-danger btn-link"
                    title="Eliminar Usuario"
                    (click)="deleteEmployee(row)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </span>
                <span>
                  <button
                    type="button"
                    class="btn btn-icon btn-outline-success btn-link"
                    title="Reset password"
                    (click)="resetPassword(row)">
                    <mat-icon>email</mat-icon>
                  </button>
                </span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade modal-resize-large" id="modalNewEmployee" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog resize-medium" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Nuevo Usuario</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body user-modal resize-modal">
          <div class="form-panel">
            <div class="form-horizontal style-form">
              <mat-form-field class="full-width">
                <mat-label class="">Estado:</mat-label>
                <mat-select class="form-control" [(ngModel)]="newEmployee.active">
                  <mat-option [value]="true" selected>Activo</mat-option>
                  <mat-option [value]="false">Inactivo</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Usuario:</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="newEmployee.userid.username"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Contraseña:</mat-label>
                <input matInput type="password" class="form-control" [(ngModel)]="password1"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Reescribir Contraseña:</mat-label>
                <input matInput type="password" class="form-control" [(ngModel)]="password2"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Nombre:</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="newEmployee.firstname"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Apellido:</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="newEmployee.lastname"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Rol:</mat-label>
                <mat-select class="form-control" [(ngModel)]="newEmployee.employeeroleid">
                  <mat-option *ngFor="let c of employeeroles" [value]="c.roleid">
                    {{c.role}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>
                  Tipo de Identificación:
                </mat-label>
                <mat-select class="form-control" [(ngModel)]="newEmployee.identificationtypeid">
                  <mat-option *ngFor="let c of identificationtypes" [value]="c.identificationtypeid">
                    {{c.identificationtype}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Numero de Identificación:</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="newEmployee.identification" [disabled]="!newEmployee.identificationtypeid"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class=""
                  >Telefono Casa:</mat-label>
                <input matInput
                  type="text"
                  class="form-control"
                  [(ngModel)]="newEmployee.homephone"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Telefono Oficina:</mat-label>
                  <input matInput
                    type="text"
                    class="form-control"
                    [(ngModel)]="newEmployee.officephone"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Telefono Celular:</mat-label>
                <input matInput
                  type="text"
                  class="form-control"
                  [(ngModel)]="newEmployee.cellphone"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Correo Electronico:</mat-label>
                <input matInput
                  type="text"
                  class="form-control"
                  [(ngModel)]="newEmployee.email"/>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="text-center mb-4">
            <button type="button" class="btn btn-primary blockcenter" (click)="saveEmployee()" title="Guardar Usuario">
              Guardar Usuario
            </button>
          </div>
          <div class="text-center">
            <button type="button" class="btn btn-default blockcenter" data-dismiss="modal" title="Cerrar Ventana">
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal fade" id="modalEditEmployee" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Editar Usuario</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body resize-medium user-modal">
          <div class="form-panel">
            <div class="form-horizontal style-form">
              <mat-form-field class="full-width">
                <mat-label class="">Usuario:</mat-label>
                <input disabled="true" matInput class="form-control" [(ngModel)]="editEmployee.userid.username"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Correo Electronico:</mat-label>
                <input matInput class="form-control" [(ngModel)]="editEmployee.email"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Nombres:</mat-label>
                <input matInput class="form-control" [(ngModel)]="editEmployee.firstname"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label class="">Apellidos:</mat-label>
                <input matInput class="form-control" [(ngModel)]="editEmployee.lastname"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>Rol:</mat-label>
                <mat-select class="form-control" [(ngModel)]="editEmployee.employeeroleid">
                  <mat-option *ngFor="let c of employeeroles" [value]="c.roleid">
                    {{c.role}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>
                  Tipo de Identificación:
                </mat-label>
                <mat-select class="form-control" [(ngModel)]="editEmployee.identificationtypeid">
                  <mat-option *ngFor="let c of identificationtypes" [value]="c.identificationtypeid">
                    {{c.identificationtype}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>Numero de Identificación:</mat-label>
                <input matInput class="form-control" [(ngModel)]="editEmployee.identification"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>Telefono Casa:</mat-label>
                <input matInput class="form-control" [(ngModel)]="editEmployee.homephone"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>Telefono Oficina:</mat-label>
                <input matInput class="form-control" [(ngModel)]="editEmployee.officephone"/>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>Telefono Celular:</mat-label>
                <input matInput class="form-control" [(ngModel)]="editEmployee.cellphone"/>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="text-center mb-4">
            <button type="button" class="btn btn-primary" (click)="updateEmployee()" title="Editar Usuario">
              Guardar
            </button>
          </div>
          <div class="text-center">
            <button type="button" class="btn" data-dismiss="modal" title="Cerrar Ventana">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
