import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CompanyProjects } from './projects.page';
import { FormsModule } from '@angular/forms';
import { MenuFilterAdvancedPropertyRightComponent } from './filter/filter-property.menu';
import { CanActivateViaAuthGuard } from  '../../security/canactivateviaauthguard'
import { NgPipesModule } from 'ngx-pipes';
import { InlineSVGModule } from 'ng-inline-svg';
import { MaterialModule } from 'src/app/material.module';

export const routes: Routes = [
  { path: 'company/projects',
    component: CompanyProjects,
    canActivate: [
      CanActivateViaAuthGuard
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    NgxDatatableModule,
    NgPipesModule,
    InlineSVGModule,
    MaterialModule
  ],
  declarations: [
    CompanyProjects,
    MenuFilterAdvancedPropertyRightComponent,
  ]
})

export class CompanyModule { }
