<mat-dialog-content class="full-height">
  <div class="card-header modal-header">
    <h3 class="modal-title">Seleccionar País</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <section class="card modal-list">
    <div class="pt-2">
      <input [(ngModel)]="searchText" (input)="filterItems($event)" placeholder="BUSCAR" class=" form-control input-rounded">
      <mat-list class="mt-5">
        <ng-container *ngFor="let country of countries">
          <mat-list-item (click)="selectCountry(country)">
            <div style="display: block;">
              <div class="font-blue">
                {{ country.country }}
              </div>
            </div>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </div>
  </section>
</mat-dialog-content>
