<mat-toolbar>
  <div class="cat__top-bar">
    <div id = "main">
      <div id="bar-sup" class="bar-sup mobile-hidden">
      </div> 
    </div>
    <!-- left aligned items -->
    <div class="cat__top-bar__left">
      <a>      
        <mat-icon class="menu-toggle">
          menu
        </mat-icon>
      </a>

    </div>
    <!-- right aligned items -->
    <div class="cat__top-bar__right">

      <div class="cat__top-bar__item hidden-sm-down">
        <div class="dropdown">
        </div>
      </div>
      <div class="cat__top-bar__item">
        <div class="dropdown cat__top-bar__avatar-dropdown">
          <a href="javascript: void(0);" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            <span>
              <span class="cat__top-bar__avatar" href="javascript:void(0);">
                <span class="svg-icon" [inlineSVG]="'assets/iconos/user.svg'"></span>
              </span>
            </span>
          </a>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="" role="menu">
            <a class="dropdown-item" href="javascript:void(0)" (click) = "profile()"><i class="dropdown-icon icmn-user"></i> {{ 'Profile' | translate }}</a>
            <a class="dropdown-item" href="javascript:void(0)" (click) = "switchAccounts()"><i class="dropdown-icon icmn-user"></i> {{ 'Switch Accounts' | translate }}</a>
            <a class="dropdown-item" href="javascript:void(0)" (click) = "logout()"><i class="dropdown-icon icmn-exit"></i> {{ 'Logout' | translate }}</a>
          </ul>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
