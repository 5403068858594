import { Component, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { SeggacService } from '../../services/seggac.service';
import { DateService } from '../../services/date.service';
import { Client, Company, Identificationtype, ClientType } from '../../models/seggac-model';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ClientmodalComponent } from 'src/app/components/clientmodal/clientmodal.component';
import { MatDialog } from '@angular/material/dialog';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'list-client',
  templateUrl: './list-client.html',
  providers: [SeggacService, DateService],
  encapsulation: ViewEncapsulation.None
})

export class ClientList implements OnInit {
  public form: FormGroup;
  clients: Client[];
  currentClient: Client;
  editClient: Client;
  temp = [];
  companies = [];
  identificationtypes = [];
  clienttypes = [];
  checkboxValue:boolean = false;
  clienttypeid: "";
  columnFirstName : any = {
    name: "Nombre"
  };
  columnLastName : any = {
    name: "Apellido"
  }
  columnDate : any = {
    name: "Fecha"
  }
  columnEmail : any = {
    name: "Email"
  }
  columnState : any = {
    name: "Estado"
  }
  listColumnFirstName = [];
  listColumnLastName = [];
  listColumnDate = [];
  listColumnEmail = [];
  listColumnState= [];

  expanded: any = {};
  timeout: any;
  @ViewChild('table') table: any;

  ngOnInit(){
    this.getClients();
    this.getCompanies();
    this.getIdentificationtypes();
    this.getClientTypes();
    var self = this;
    (function($) {
      $(window).resize(function(){
        var current_width = $(window).width();
        self.listColumnFirstName = [];
        self.listColumnLastName = [];
        self.listColumnDate = [];
        self.listColumnEmail = [];
        self.listColumnState= [];
        if (current_width > 1200) {
          //self.table.rowDetail.collapseAllRows();
          self.listColumnFirstName.push(self.columnFirstName);
          self.listColumnLastName.push(self.columnLastName);
          self.listColumnEmail.push(self.columnEmail);
          self.listColumnState.push(self.columnState);
          self.listColumnDate.push(self.columnDate);
        } else if (current_width > 1100 && current_width < 1200) {
          self.listColumnFirstName.push(self.columnFirstName);
          self.listColumnLastName.push(self.columnLastName);
          self.listColumnEmail.push(self.columnEmail);
          self.listColumnState.push(self.columnState);
        } else if (current_width > 1000 && current_width < 1100) {
          self.listColumnFirstName.push(self.columnFirstName);
          self.listColumnLastName.push(self.columnLastName);
          self.listColumnEmail.push(self.columnEmail);
        } else if (current_width > 800 && current_width < 1000) {
          self.listColumnFirstName.push(self.columnFirstName);
          self.listColumnLastName.push(self.columnLastName);
        } else if (current_width < 800) {
          self.listColumnFirstName.push(self.columnFirstName);
          self.listColumnLastName.push(self.columnLastName);
        }
      });
    })(jQuery);
  }

  constructor(
    private SeggacService: SeggacService,
    private DateService: DateService,
    public translate: TranslateService,
    private dialog: MatDialog
    ) {
    var current_width = $(window).width();
    var self = this;
    if (current_width > 1200) {
      this.listColumnFirstName.push(this.columnFirstName);
      this.listColumnLastName.push(this.columnLastName);
      this.listColumnEmail.push(this.columnEmail);
      this.listColumnState.push(this.columnState);
      this.listColumnDate.push(this.columnDate);
    } else if (current_width > 1100 && current_width < 1200) {
      this.listColumnFirstName.push(this.columnFirstName);
      this.listColumnLastName.push(this.columnLastName);
      this.listColumnEmail.push(this.columnEmail);
      this.listColumnState.push(this.columnState);
    } else if (current_width > 1000 && current_width < 1100) {
      this.listColumnFirstName.push(this.columnFirstName);
      this.listColumnLastName.push(this.columnLastName);
      this.listColumnEmail.push(this.columnEmail);
    } else if (current_width > 800 && current_width < 1000) {
      this.listColumnFirstName.push(this.columnFirstName);
      this.listColumnLastName.push(this.columnLastName);
    } else if (current_width > 700 && current_width < 800) {
      this.listColumnFirstName.push(this.columnFirstName);
    }
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }

  getIdentificationtypes() {
    this.SeggacService.getIdentificationtypes()
      .subscribe(
        (data: Identificationtype[]) => {
          this.identificationtypes = data;
        });
  }

  getClientTypes() {
    this.SeggacService.getClientTypes()
      .subscribe(
        (data: ClientType[]) => {
          this.clienttypes = data;
        });
  }

  getCompanies() {
    this.SeggacService.getCompanies().subscribe(
      (data: Company[]) => {
        this.companies = data;
      }
    );
  }

  getClients() {
    this.SeggacService.getClients().subscribe(
      (data: Client[]) => {
        this.temp = [...data];
        this.clients = data;
      }
    );
  }

  openEditClient(row:any) {
    this.editClient = JSON.parse(JSON.stringify(row));
    if ( row.companyid && row.companyid.companyid ) {
      this.editClient.companyid = row.companyid.companyid;
    }
    if ( row.identificationtypeid && row.identificationtypeid.identificationtypeid ) {
      this.editClient.identificationtypeid = row.identificationtypeid.identificationtypeid;
    }
    if ( row.clienttypeid ) {
      if (Number.isInteger(row.clienttypeid)) {
        this.editClient.clienttypeid = row.clienttypeid;
      } else {
        this.editClient.clienttypeid = row.clienttypeid.clienttypeid;
      }
    }

    let dialogRef = this.dialog.open(ClientmodalComponent, {
      width: '600px',
      height: '90%',
      data: {
        client : this.editClient
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getEditClient(result.client);
    });
  }

  openNewClient() {
    let dialogRef = this.dialog.open(ClientmodalComponent, {
      width: '600px',
      height: '90%'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getNewClient(result.client);
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function(d) {
      if (d.displayname && d.displayname.toLowerCase().indexOf(val) !== -1 || !val)
        return true;
      else if (d.email && d.email.toString().toLowerCase().indexOf(val) !== -1 || !val)
        return true;
      else if (d.identification && d.identification.toString().toLowerCase().indexOf(val) !== -1 || !val)
        return true;
    });
    this.clients = temp;
    this.table.offset = 0;
  }

  getNewClient(newClient) {
    var client = JSON.parse(JSON.stringify(newClient));
    this.clients.push(client);
    this.temp.push(client);
    this.clients=[...this.clients];
  }

  getEditClient(editClient) {
    var index = this.clients.findIndex(x => x.clientid==editClient.clientid);
    this.clients[index] = editClient;
    this.clients = [...this.clients];
    var index2 = this.temp.findIndex(x => x.clientid==editClient.clientid);
    this.temp[index2] = editClient;
    this.temp = [...this.temp]
  }

  deleteClient(row : any) {
    let flat = true;
    Swal.fire({
      title: '¿Deseas eliminar el cliente?',
      text: 'Una vez eliminado el cliente no se podrá recuperar!',
      icon: 'warning',
      showCloseButton: true
    })
      .then((willDelete) => {
        if (willDelete.isConfirmed) {

          var tempid = +row.clientid;
          this.SeggacService.deleteClient(+row.clientid).subscribe(
            res => {
              if(res.status == false) {
                flat = false;
                Swal.fire(res.msg + ' Las propiedades son: ' + res.properties.join(', '));
                return;
              }
              else {
                var index = this.clients.indexOf(row);
                this.clients.splice(index, 1);
                this.clients=[...this.clients];

                var index2 = this.temp.findIndex(x => x.clientid == tempid);
                this.temp.splice(index2, 1);
                this.temp = [...this.temp]
              }
            },
            err => {
              $.notify({
                title: '',
                message: 'Error al eliminar el Cliente.'
              },{
                type: 'warning',
                delay: 1000,
                timer: 1000,
                z_index: 2000,
              });
            }, ()=> {
              if(flat) {
                $.notify({
                  title: '',
                  message: 'Se Eliminó el Cliente exitosamente.'
                },{
                  type: 'success',
                  delay: 1000,
                  timer: 1000,
                  z_index: 2000,
                });
              }

            }
          );
        }
      });
  }

  convertDate(date) {
    return this.DateService.convertDate(date);
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  randomPassword(length) {
    let chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    let pass = "";
    for (let x = 0; x < length; x++) {
      let i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    return pass;
  }
}
