import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CanActivateViaAuthGuard } from  '../../security/canactivateviaauthguard'
import { Profile } from './user_profile.page';
import {InlineSVGModule} from 'ng-inline-svg';
import { MaterialModule } from 'src/app/material.module';
export const routes: Routes = [
    {   path: 'profile', component: Profile, canActivate: [CanActivateViaAuthGuard] }
]

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        InlineSVGModule,
        ReactiveFormsModule,
        MaterialModule
    ],
    declarations: [
        Profile
    ]
})

export class ProfileModule { }
