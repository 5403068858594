<!-- START: tables/editable-tables -->
<section class="card">
  <div class="card-header">
    <h1 class="cat__core__title">
      <strong>Servicio al Cliente</strong>
    </h1>
  </div>
  <div class="card-block">
      De requerir algún apoyo o tener cualquier consulta, puede comunicarse con nosotros mediante el siguiente formulario. 
    <br /><br />
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group row" >
          <mat-label class="col-lg-2 control-label right">Asunto:</mat-label>
          <div class="col-lg-10">
            <input [(ngModel)]="asunto" type="text" class="form-control" id="asunto" placeholder="Ingrese el Asunto">
          </div>
        </div>
        <mat-form-field class="">
          <mat-label class="col-lg-2 control-label right">Mensaje:</mat-label>
          <div class="col-lg-10">
            <textarea [(ngModel)]="mensaje" rows="8" class="form-control" id="mensaje" placeholder="Ingrese el Mensaje" value="password"></textarea>
          </div>
        </mat-form-field>
        <mat-form-field class="">
          <div class="col-lg-12 right">
            <button class="btn btn-primary btn-auth" (click)="sendMail()">Envia Mensaje</button>
          </div>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
      </div>
    </div>
  </div>
</section>

