<section class="card pb-5">
  <div class="card-search">
    <div class="row">
      <div class="card-block col-md-6" style="margin: auto;">
        <div class="form-input-icon form-input-icon-right" >
          <input type="text" id="search" class="form-control input-rounded" placeholder="# / CLIENTE / ID /  CONCEPTO /  MONTO" (keyup)='updateFilter($event)'>
          <i class="icmn-search iconsearch"></i>
        </div>
      </div>
      <div class="card-block col-6 col-md-3" style="margin: auto;">
        <div class="form-input-icon form-input-icon-right" >
          <i class="icmn-cancel-circle" (click)="clearDate1()"></i>
          <input type="text" #date1 name ="date1" id="date1" class="form-control datepicker-only-init date1 input-rounded" placeholder="DESDE">
        </div>
      </div>
      <div class="card-block col-6 col-md-3" style="margin: auto;">
        <div class="form-input-icon form-input-icon-right" >
          <i class="icmn-cancel-circle" (click)="clearDate2()"></i>
          <input type="text" #date2 name ="date2" id="date2" class="form-control datepicker-only-init date2 input-rounded" placeholder="HASTA">
        </div>
      </div>
    </div>
  </div>
  <div #tableWrapper class="card-block receipt-datatable table-wrapper">
    <ngx-datatable #tablereceipt id="tablereceipt"
      [rows]='voucher_array' 
      class='material expandable' 
      [columnMode]="'flex'" 
      [headerHeight]="50" 
      [footerHeight]="50"
      [scrollbarH]="false"
      [rowHeight]="80" 
      [limit]="10" 
      [rowClass]="getRowClass" 
      [messages]="{emptyMessage: 'Cargando Datos',  totalMessage: 'Total'}">
      <ngx-datatable-row-detail [rowHeight]="50" #myDetailRow (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div style="padding-left: 50px;">
            {{ row.paymentconceptid.paymentconceptname }}
            <br>
            {{ row.clientid.identification }}
          </div>
        </ng-template>
      </ngx-datatable-row-detail>
      <ngx-datatable-column name="receipt" [flexGrow]="3" [sortable]="true" [prop]="'voucherid'" [cellClass]="'widthreceiptcol mobile-width-80'" [headerClass]="'mobile-hidden widthreceiptcol'">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          #
        </ng-template>
        <ng-template let-expanded="expanded" let-row="row" ngx-datatable-cell-template>
          <div class="custom-cell-receipt mobile-hidden">{{ row.voucherid }}</div>
          <div class="receipt-data desktop-hidden">
            <div (click)="toggleExpandRow(row)" class="material-icons pointer receipt-expand">
              {{ !expanded ? 'chevron_right' : 'expand_more' }}
            </div>
            <div style="display: inline-block; width: calc(100% - 45px); margin: 5px 0;">
              <div class="custom-cell-receipt">{{ row.voucherid }}</div>
              <div>{{ customSplit(row.dataclient)[0] }}</div>
              <div>
                <span style="float: left;">{{ row.voucherdate | date:'dd/MM/yy' }}</span>
                <span class="receipt-amount" style="float: right;">{{ country.ccode }}{{ row.vouchertotal | number : '1.2-2' }}</span>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Client" [flexGrow]="12" [sortable]="true" [prop]="'dataclient'" [cellClass]="'mobile-hidden'" [headerClass]="'mobile-hidden'">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort()">{{column.name | translate | uppercase}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span> {{value}} </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="ID" [flexGrow]="5" [sortable]="true" [prop]="'clientid.identification'" [cellClass]="'align-column-right mobile-hidden'" [headerClass]="'align-column-right mobile-hidden'">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort()">{{column.name | translate | uppercase}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span> {{value}} </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="12" name="Payment Concept" [sortable]="true" [prop]="'paymentconceptid.paymentconceptname'" [cellClass]="'align-column-right mobile-hidden'" [headerClass]="'align-column-right mobile-hidden'">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort()">{{column.name | translate | uppercase}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span> {{value}} </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="6" name="Date" [sortable]="true" [prop]="'voucherdate'" [cellClass]="'align-column-right mobile-hidden'" [headerClass]="'align-column-right mobile-hidden'">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort()">{{column.name | translate | uppercase}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span> {{value | date:'dd/MM/yy'}} </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Amount" [flexGrow]="8" [sortable]="true" [prop]="'vouchertotal'" [cellClass]="'mobile-hidden flex'" [headerClass]="'align-column-right mobile-hidden'">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort()">{{column.name | translate | uppercase}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span class="receipt-amount"> {{ country.ccode }} {{value | number : '1.2-2'}} </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Operations" [flexGrow]="3" [sortable]="true" [prop]="'active'" [cellClass]="'mobile-fix-right flex'" [headerClass]="'mobile-fix-right'">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort()"></span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="actions-buttons" *ngIf="row.active">
            <ng-template #popContent>
              <div class="popover-content">
                <span class="operations-button">
                  <button type="button" class="btn btn-icon btn-outline-danger btn-link" title="{{'Send Email' | translate}}" (click)="sendEmail(row)">
                    <span class="svg-icon operations-icon" [inlineSVG]="'assets/iconos/email.svg'"></span>
                  </button>
                </span> 
                <span class="operations-button">
                  <button type="button" class="btn btn-icon btn-outline-danger btn-link" title="{{ 'Print' | translate}}" (click)="print(row)">
                    <span class="svg-icon operations-icon" [inlineSVG]="'assets/iconos/download.svg'"></span>
                  </button>
                </span>
                <span class="operations-button">
                  <button type="button" class="btn btn-icon btn-outline-danger btn-link" title="{{'Cancel Receipt' | translate}}" (click)="delete(row)">
                    <span class="svg-icon operations-icon" [inlineSVG]="'assets/iconos/cancel.svg'"></span>
                  </button>
                </span>
              </div>
            </ng-template>
            <span>
              <button type="button" class="btn btn-icon btn-outline-danger btn-link" (click)="showPopContent()" [ngbPopover]="popContent" placement="center" #popover="ngbPopover" container="body">
                <span class="svg-icon operations-icon operationsicon" [inlineSVG]="'assets/iconos/options.svg'" ></span>
              </button>
            </span>
          </div>
          <div *ngIf="!row.active">
            <span style="font-size: 11px;font-style: italic;">
              {{'Canceled' | translate}}
            </span>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
  <div *ngIf="!isNative" class="text-center">
    <button [routerLink]="['/company/receipt']" type="submit" class="btn btn-primary btn-block  blockcenter" [disabled]="!myForm.valid">
      {{'Create receipt' | translate}}
    </button>
    <div class="mt-5 color-primary">
      <a (click)="exportToExcel()" style="color: #2f3b7b">
        <img src="/assets/iconos/xlsx.png" style="width:30px"> Descargar lista 
      </a><br>
      <small>* Selecciona el rango de fecha deseado para descargar.</small>
    </div>

  </div>
</section>

<div class="modal fade modal-size-medium" id="modalMsgConfirm" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">{{'Enviar Recibo # '+ email_data.receipt_id }}</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body resize-modal">
        <div class="form-panel">
          <div class="style-form">
            <form id="form-sendemail" [formGroup]="myForm" (ngSubmit)="sendfromform(myForm.value,myForm.valid)">
              <div class="full-width">
                <h4>Correo: {{ email_data.client_email }}</h4>
              </div>
              <mat-form-field class="full-width">
                <mat-label>CC:</mat-label>
                <input matInput type="text"  formControlName="voucheremailreply" pattern="^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}(,?[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4})*$"/>
              </mat-form-field>
              <mat-error class="alertBox" *ngIf="myForm.controls['voucheremailreply'].errors?.pattern">{{'* '+('Field no valid' | translate)+'. ' +('Example' | translate) +': usuario@gmail.com ' + ('or' | translate) + ' ' + 'usuario@gmail.com,cliente@gmail.com' }}</mat-error>

              <div class="text-center">
                <button type="submit" class="btn btn-primary blockcenter" style="padding: 10px 24px;" title="Enviar Correos" [disabled]="myForm.controls['voucheremailreply'].errors?.pattern">Enviar Correo</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
