import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent implements OnInit {
  searchText : String = '';
  countries: Array<any>;
  countries_initial: Array<any>;
  constructor(
    public dialogRef: MatDialogRef<CountriesComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.countries = data.countries;
    this.countries_initial = data.countries;
  }

  ngOnInit(): void {
  }

  filterItems(ev) {
    let val = ev.target.value;
    if (val && val.trim() != '') {
      this.countries = this.countries.filter(item => {
        return (
          item.country.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    } else {
      this.countries= this.countries_initial;
    }
  }

  selectCountry(country) {
    this.dialogRef.close({country: country});
  }

  closeModal() {
    this.dialogRef.close({});
  }
}
