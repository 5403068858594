import {Injectable} from '@angular/core';

@Injectable()
export class convNumLetra {

    private Unidades(num){

        switch(num)
        {
            //case 0: return "CERO";
            case 1: return "Uno";
            case 2: return "Dos";
            case 3: return "Tres";
            case 4: return "Cuatro";
            case 5: return "Cinco";
            case 6: return "Seis";
            case 7: return "Siete";
            case 8: return "Ocho";
            case 9: return "Nueve";
        }

        return "";
    }

    private Decenas(num){

        let decena = Math.floor(num/10);
        let unidad = num - (decena * 10);

        switch(decena)
        {
            case 1:
                switch(unidad)
                {
                    case 0: return "Diez";
                    case 1: return "Once";
                    case 2: return "Doce";
                    case 3: return "Trece";
                    case 4: return "Catorce";
                    case 5: return "Quince";
                    default: return "Dieci" + this.Unidades(unidad).toLowerCase();
                }
            case 2:
                switch(unidad)
                {
                    case 0: return "Veinte";
                    default: return "Veinti" + this.Unidades(unidad).toLowerCase();
                }
            case 3: return this.DecenasY("Treinta", unidad);
            case 4: return this.DecenasY("Cuarenta", unidad);
            case 5: return this.DecenasY("Cincuenta", unidad);
            case 6: return this.DecenasY("Sesenta", unidad);
            case 7: return this.DecenasY("Setenta", unidad);
            case 8: return this.DecenasY("Ochenta", unidad);
            case 9: return this.DecenasY("Noventa", unidad);
            case 0: return this.Unidades(unidad);
        }
    }//Unidades()

    private DecenasY(strSin, numUnidades){
        if (numUnidades > 0)
            return strSin + " y " + this.Unidades(numUnidades)

        return strSin;
    }//DecenasY()

    private Centenas(num){

        let centenas = Math.floor(num / 100);
        let decenas = num - (centenas * 100);

        switch(centenas)
        {
            case 1:
                if (decenas > 0)
                    return "Ciento " + this.Decenas(decenas);
                return "Cien";
            case 2: return "Doscientos " + this.Decenas(decenas);
            case 3: return "Trescientos " + this.Decenas(decenas);
            case 4: return "Cuatroscientos " + this.Decenas(decenas);
            case 5: return "Quinientos " + this.Decenas(decenas);
            case 6: return "Seiscientos " + this.Decenas(decenas);
            case 7: return "Setecientos " + this.Decenas(decenas);
            case 8: return "Ochocientos " + this.Decenas(decenas);
            case 9: return "Novecientos " + this.Decenas(decenas);
        }

        return this.Decenas(decenas);
    }//Centenas()

    private Seccion(num, divisor, strSingular, strPlural){
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let letras = "";

        if (cientos > 0)
            if (cientos > 1)
                letras = this.Centenas(cientos) + " " + strPlural;
            else
                letras = strSingular;

        if (resto > 0)
            letras += "";

        return letras;
    }//Seccion()

    private Miles(num){
        let divisor = 1000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMiles = this.Seccion(num, divisor, "Mil", "Mil");
        let strCentenas = this.Centenas(resto);

        if(strMiles == "")
            return strCentenas;

        return strMiles + " " + strCentenas;

        //return Seccion(num, divisor, "UN MIL", "MIL") + " " + Centenas(resto);
    }//Miles()

    private Millones(num){
        let divisor = 1000000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMillones = this.Seccion(num, divisor, "Un Millón", "Millones");
        let strMiles = this.Miles(resto);

        if(strMillones == "")
            return strMiles;

        return strMillones + " " + strMiles;

        //return Seccion(num, divisor, "UN MILLON", "MILLONES") + " " + Miles(resto);
    }//Millones()

    public NumeroALetras(num){
        num = num.replace(",", "");
        var data = {
            numero: num,
            enteros: Math.floor(num),
            centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
            letrasCentavos: "",
            letrasMonedaPlural: "",//Balboas
            letrasMonedaSingular: "" //Balboas
        };

        if (data.centavos > 0)
            data.letrasCentavos = "con " + data.centavos + "/100 Balboas";

        if(data.enteros == 0)
            return "CERO" + data.letrasMonedaPlural + " " + data.letrasCentavos; //IMPRIME CERO O CAMPO VACÍO
        if (data.enteros == 1)
            return this.Millones(data.enteros) + " " + data.letrasMonedaSingular + " " + data.letrasCentavos;
        else
            return this.Millones(data.enteros) + " " + data.letrasMonedaPlural + " " + data.letrasCentavos;
    }//NumeroALetras()
}
