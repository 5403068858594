import { Component, OnInit } from '@angular/core';
import {SeggacService} from '../../../services/seggac.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-paymenttype',
  templateUrl: './paymenttype.component.html',
  styleUrls: ['./paymenttype.component.css']
})
export class PaymenttypeComponent implements OnInit {
  public myForm: FormGroup;
  public paymenttype_array: any = [];
  public id_edit: any;
  
  constructor(
    private _fb: FormBuilder,
    private SeggacService: SeggacService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.getPaymentType();
    this.myForm = this._fb.group({
      paymenttypename: ['', <any>Validators.required]
    });
  }

  getPaymentType() {
    this.SeggacService.getPaymentType()
      .subscribe(
        (data: any) => {
          this.paymenttype_array = data;
        });
  }

  register(paymenttype: any, isValid: Boolean) {
    if (isValid) {
      Swal.fire({
          title: '¿Deseas continuar?',
          text: '',
          icon: 'warning',
          showCloseButton: true
      }).then((willCreate) => {
          if (willCreate.isConfirmed) {
            this.SeggacService.addPaymentType(paymenttype)
            .subscribe(
              (data: any) => {
                this.myForm.controls['paymenttypename'].setValue('');
                this.getPaymentType();
              });
          }
      });
    } else {
        alert('Se debe colocar un nombre.');
    }
  }

  openEdit(row) {
    if (row.editable) {
      this.id_edit = row.paymenttypeid;
      this.myForm.controls['paymenttypename'].setValue((row.paymenttypename));
      $('#modaledit').modal('show');
    }
  }

  edit(paymenttype: any, isValid: Boolean){
    if(isValid){
      this.SeggacService.editPaymentType(paymenttype,this.id_edit)
        .subscribe(
          (data: any) => {
            this.getPaymentType();
            $('#modaledit').modal('hide');
          });
    }
    else{
      alert('error');
    }
  }

  delete(row: any) {
    Swal.fire({
      title: '¿Deseas eliminar este elemento?',
      text: 'Una vez eliminado no se podrá recuperar.',
      icon: 'warning',
      showCloseButton: true
    }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.SeggacService.deletePaymentType(row.paymenttypeid)
          .subscribe(
            (data: any) => {
              this.getPaymentType();
            });
        }
      }
    );

  }
}
