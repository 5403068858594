import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../entities/user';
import {Servidores} from './servidores';
import {Observable, Subject, Subscription} from 'rxjs/index';
import {map} from 'rxjs/internal/operators';

@Injectable()
export class LoginService {
  headers: HttpHeaders = new HttpHeaders();
  private _user = new Subject<User>();
  private _isLogged = new Subject<boolean>();
  private _usertypeid = new Subject<boolean>();
  public isLogged: boolean = false;
  public userid: any;
  public usertypeid: any;
  private baseUrl: string;
  _subscription: Subscription;

  constructor(private http: HttpClient, private servidores: Servidores) {
    this.baseUrl = this.servidores.API;
  }

  loginUser(user, password) {
    return this.http
      .post(`${this.baseUrl}/user/login`, {user: user, password: password})
      .pipe(map(res => res));
  }

  recoverUser(usermail) {
    return this.http
      .post(`${this.baseUrl}/user/request_recover`, {userRecover: usermail})
      .pipe(map(res => res));
  }

  resetPassword(token, password, newpass) {
    return this.http
      .post(`${this.baseUrl}/user/reset_password`, {
        token: token,
        password: password,
        newpass: newpass,
      })
      .pipe(map(res => res));
  }

  validateRecoverToken(token) {
    return this.http
      .post(`${this.baseUrl}/user/validate_token`, {token: token})
      .pipe(map(res => res));
  }

  setUserSeggac(_user: User, usertypeid = null) {
    this._user.next(_user);
    this._isLogged.next(true);
    this.isLogged = true;
    this.userid = {..._user};
  }

  setUserType(_usertypeid) {
    this._usertypeid.next(_usertypeid);
    this.usertypeid = _usertypeid;
  }

  removeUserSeggac() {
    this._user.next(null);
    this._isLogged.next(false);
    this.isLogged = false;
    this.userid = null;
  }

  getUserSeggac(): Observable<User> {
    return this._user.asObservable();
  }

  getIsLogged(): Observable<boolean> {
    return this._isLogged.asObservable();
  }

  getUsertype(): Observable<boolean> {
    return this._usertypeid.asObservable();
  }
}
