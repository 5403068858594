import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ReceiptRoutingModule } from './receipt-routing.module';
import { VoucherComponent } from './voucher/voucher.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BankComponent } from './bank/bank.component';
import { PaymentconceptComponent } from './paymentconcept/paymentconcept.component';
import { VoucherListComponent } from './voucher-list/voucher-list.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SeggacService } from '../../services/seggac.service';
import { FileManagerService } from '../../services/filemanager.service';
import {DateService} from '../../services/date.service';
import { PaymenttypeComponent } from './paymenttype/paymenttype.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {convNumLetra} from '../../services/conv_num_letra';
import { PipeNumberFormatPipe } from './pipe-number-format.pipe';
import { CompanyComponent } from './company/company.component';
import { SharedModule } from '../sharedmodule/sharedmodule.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from 'src/app/material.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ValidateComponent } from './validate/validate.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  imports: [
    NgbModule,
    SharedModule,
    CommonModule,
    ReceiptRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    NgxDatatableModule,
    TypeaheadModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    MaterialModule,
    ImageCropperModule,
    NgxExtendedPdfViewerModule
  ],
  declarations: [
    VoucherComponent,
    VoucherListComponent,
    ValidateComponent,
    BankComponent,
    PaymentconceptComponent,
    PaymenttypeComponent,
    PipeNumberFormatPipe,
    CompanyComponent
  ],
  providers: [
    DatePipe,
    SeggacService,
    FileManagerService,
    DateService,
    TranslateService,
    convNumLetra
  ],
})
export class ReceiptModule { }
