import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders  } from '@angular/common/http';

@Injectable()
export class FileManagerService {
  public urlApi2pdf = 'https://v2018.api2pdf.com/chrome/html';
  public httpOptionsApi2Pdf = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': '6fae9d47-ec6a-4385-bc06-78d9551236f9'
    })
  };

  constructor (private httpclient: HttpClient) { }

  blob2base64(blob, namefile) {
    return new Promise(resolve => {
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        const fileData = fileReader.result;
        const rawData = (<string>fileData).split('base64,');
        const file64 = {
          type: rawData[0],
          file: rawData[1],
          name: namefile,
        };
        resolve(file64);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  postApi2Pdf(jsonObj) {
    return this.httpclient.post(this.urlApi2pdf, jsonObj, this.httpOptionsApi2Pdf);
  }

  getApi2Pdf(url) {
    return this.httpclient.get(`https://api.allorigins.win/get?url=${encodeURIComponent(url)}`);
  }
}
