import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UserList } from './list-user.page';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { CanActivateViaAuthGuard } from  '../../security/canactivateviaauthguard'
import { InlineSVGModule } from "ng-inline-svg";
import { MaterialModule } from 'src/app/material.module';

export const routes: Routes = [
  { path: 'user/list',
    component: UserList,
    canActivate: [
      CanActivateViaAuthGuard
    ] 
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    NgxDatatableModule,
    MaterialModule  
  ],
  declarations: [
    UserList
  ]
})

export class UserModule { }
