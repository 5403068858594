<section class="card">
  <div class="card-header">
        <span class="cat__core__title">
            <strong>{{'Company' | translate}}</strong>
        </span>
  </div>
  <div class="card-block">
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-5">
          <form [formGroup]="myForm" (ngSubmit)="create(myForm.value,myForm.valid)">
            <div class="form-group row">
              <label class="col-md-3 col-form-label">{{'Company' | translate}}: *</label>
              <div class="col-md-9">
                <input type="text" class="form-control" formControlName="company">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label">{{'Email' | translate}}: *</label>
              <div class="col-md-9">
                <input type="text" class="form-control" formControlName="email">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label">{{'Phone' | translate}}: *</label>
              <div class="col-md-9">
                <input type="text" class="form-control" formControlName="phone">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label">{{'Description' | translate}}: *</label>
              <div class="col-md-9">
                <input type="text" class="form-control" formControlName="description">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label">{{'Identification' | translate}}: *</label>
              <div class="col-md-9">
                <input type="text" class="form-control" formControlName="identification">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label">{{'Address' | translate}}: *</label>
              <div class="col-md-9">
                <input type="text" class="form-control" formControlName="address">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label">{{'Db Name' | translate}}:</label>
              <div class="col-md-9">
                <input type="text" class="form-control" formControlName="dbname">
              </div>
            </div>
            <div class="form-actions">
              <div class="form-group row">
                <div class="col-md-9 offset-md-3">
                  <button type="submit" class="btn btn-primary">{{'Create' | translate}}</button>
                  <button type="button" class="btn btn-default" (click)="reset()">Cancelar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
