import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs/index';
import {Servidores} from './servidores';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/internal/operators';
import { Share } from '@capacitor/share';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

@Injectable()
export class SeggacService {
  public baseUrl: string;
  public imagesUrl: String;
  public imagesCompaniesUrl: String;
  companyStatus = new Subject<boolean>();

  constructor(private http: HttpClient, private servidores: Servidores,
    private socialSharing: SocialSharing,
    private _router: Router
    ) {
    this.baseUrl = this.servidores.API;
    this.imagesUrl = this.servidores.IMG + '/problems';
    this.imagesCompaniesUrl = this.servidores.IMG + '/companies';
  }

  getCompanies(): Observable<any> {
    return this.http.get(`${this.baseUrl}/company`, {});
  }

  getCompanyStatus(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/company-status-check/${id}`, {});
  }

  getCompanyStatusValue(): Observable<boolean> {
    return this.companyStatus.asObservable();
  }

  checkStatus(url) {
    if (sessionStorage.getItem('_usercompany')) {
      let _currentCompany = sessionStorage.getItem('_usercompany');
      this.getCompanyStatus(_currentCompany).subscribe(
        res => {
          if (res.status == false && url != res.action) {
            Swal.fire({
              title: res.title,
              text: res.text,
              icon: res.icon
            }).then((confirm) => {
                this._router.navigateByUrl(res.action);
              }
            );
          }
          this.companyStatus.next(res.status);
        }
      );
    }
  }

  postCompany(company: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/company`, company, {})
      .pipe(map(res => res)
    );
  }

  getCompanyWithDetails(companyId: string): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/company/${companyId}`, {})
      .pipe(map(res => res)
    );
  }

  getPlan(planId: string): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/plan/${planId}`, {})
      .pipe(map(res => res));
  }

  getReceiptslimit(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/receiptslimit`, {})
      .pipe(map(res => res));
  }

  updateCompany(company: any, id: number): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/company/${id}`, company, {})
      .pipe(map(res => res));
  }

  getUserCompany() {
    return this.http.get(`${this.baseUrl}/usercompany`, {})
      .pipe(map(res => res));
  }

  getUserRoles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/userroles`, {}).pipe(map(res => res));
  }

  getClients(): Observable<any> {
    return this.http.get(`${this.baseUrl}/client`, {}).pipe(map(res => res));
  }

  addClient(client: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/client`, client, {})
      .pipe(map(res => res));
  }

  updateClient(client: any, id: number): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/client/${id}`, client, {})
      .pipe(map(res => res));
  }

  deleteClient(idClient: number): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/client/${idClient}/delete`, null, {})
      .pipe(map(res => res));
  }

  getEmployees(): Observable<any> {
    return this.http.get(`${this.baseUrl}/employee`, {}).pipe(map(res => res));
  }

  addEmployee(employee: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/user`, employee, {})
      .pipe(map(res => res));
  }

  updateEmployee(employee: any, id: number): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/employee/${id}`, employee, {})
      .pipe(map(res => res));
  }

  deleteEmployee(idEmployee: number): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/employee/${idEmployee}/delete`, null, {})
      .pipe(map(res => res));
  }

  getProjects(): Observable<any> {
    return this.http.get(`${this.baseUrl}/project`, {}).pipe(map(res => res));
  }

  getIdentificationtypes(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/identificationtype`, {})
      .pipe(map(res => res));
  }

  getClientTypes(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/clienttype`, {})
      .pipe(map(res => res));
  }

  getEmployeeRoles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/roles`, {}).pipe(map(res => res));
  }

  addProject(project: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/project`, project, {})
      .pipe(map(res => res));
  }

  editProject(project: any, id: number): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/project/${id}`, project, {})
      .pipe(map(res => res));
  }

  deleteProject(idProject: number): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/project/${idProject}/delete`, null, {})
      .pipe(map(res => res));
  }

  serviceClient(mailform): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/serviceclient`, mailform, {})
      .pipe(map(res => res));
  }

  updateUserProfile2(user: any, id: number): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/user2/${id}`, user, {})
      .pipe(map(res => res));
  }

  addVoucher(voucher: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/voucher`, voucher, {})
      .pipe(map(res => res));
  }
  getVoucher(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/getVoucher`, {})
      .pipe(map(res => res));
  }
  deleteVoucher(id: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/dvoucher/${id}/delete`, null, {})
      .pipe(map(res => res));
  }
  addPaymentConcept(paymentconcept: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/PaymentConcept`, paymentconcept, {})
      .pipe(map(res => res));
  }
  editPaymentConcept(paymentconcept: any, id: any): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/PaymentConcept/${id}`, paymentconcept, {})
      .pipe(map(res => res));
  }
  deletePaymentConcept(id: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/dPaymentConcept/${id}/delete`, null, {})
      .pipe(map(res => res));
  }
  getPaymentConcept(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/getPaymentConcept`, {})
      .pipe(map(res => res));
  }
  addPaymentType(paymenttype: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/PaymentType`, paymenttype, {})
      .pipe(map(res => res));
  }
  editPaymentType(paymenttype: any, id: any): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/PaymentType/${id}`, paymenttype, {})
      .pipe(map(res => res));
  }
  deletePaymentType(id: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/dPaymentType/${id}/delete`, null, {})
      .pipe(map(res => res));
  }
  getPaymentType(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/getPaymentType`, {})
      .pipe(map(res => res));
  }
  addBank(bank: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/Bank`, bank, {})
      .pipe(map(res => res));
  }
  editBank(bank: any, id: any): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/Bank/${id}`, bank, {})
      .pipe(map(res => res));
  }
  deleteBank(id: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/dBank/${id}/delete`, null, {})
      .pipe(map(res => res));
  }

  getBank(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getBank`, {}).pipe(map(res => res));
  }

  getCountry(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCountry`, {}).pipe(map(res => res));
  }

  getCountryById(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/getCountry/${id}`, {}).pipe(map(res => res));
  }

  sendEmail(data: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/emailreceipt`, data, {})
      .pipe(map(res => res));
  }

  loadPdf(data: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/loadpdf`, data, {})
      .pipe(map(res => res));
  }

  resetPassword(data: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/resetUser`, data, {})
      .pipe(map(res => res));
  }

  editEmailReply(data: any, id: any): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/voucheremailcc/${id}`, data, {})
      .pipe(map(res => res));
  }

  base64ToBlob(b64Data, contentType): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
         const slice = byteCharacters.slice(offset, offset + sliceSize);
         const byteNumbers = new Array(slice.length);
         for (let i = 0; i < slice.length; i++) {
             byteNumbers[i] = slice.charCodeAt(i);
         }
         const byteArray = new Uint8Array(byteNumbers);
         byteArrays.push(byteArray);
    }
   return new Blob(byteArrays, {type: contentType});
  }

  downloadPdf(stringpdf, namepdf, urlpdf = null) {
    if (Capacitor.isNativePlatform()) {
      return Filesystem.writeFile({
        path: namepdf,
        data: stringpdf,
        directory: Directory.Cache
      })
        .then(() => {
          return Filesystem.getUri({
            directory: Directory.Cache,
            path: namepdf
          });
        })
        .then((uriResult) => {
          if (Capacitor.getPlatform() == 'android') {
            return this.socialSharing.share(
              namepdf, namepdf, uriResult.uri
            );
          } else {
            return Share.share({
              title: namepdf,
              text: namepdf,
              url: uriResult.uri
            });
          }
        }
      );
    } else {
      const linkSource = `data:application/pdf;base64,${stringpdf}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = namepdf;
      downloadLink.click();
      downloadLink.remove();
    }
  }

  writeSecretFile(path, data) {
    return Filesystem.writeFile({
      path: path,
      data: data,
      directory: Directory.External,
      recursive: true
    }).then(res => {
    }).catch(e => {
      console.error('error writesecretfile', e);
      if (e.message) { 
        alert("Error inicial:\n\t" + e.message + "\nLine:\n\t" + e.lineno + "\nFile:\n\t" + e.filename);
      } else {
        alert("Error inicial:\n\t" + e.type + "\nElement:\n\t" + (e.srcElement || e.target));
      }
    });
  };

  getRLimit(plan, addons) {
    let rLimit = 0;
    rLimit += +plan.receiptslimit;
    addons.forEach(addon => {
      if (addon.addonid.entity = 'receipt') {
        rLimit += addon.quantity * (+addon.addonid.value)
      }
    });
    return rLimit;
  }
}
