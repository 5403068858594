export class PagesConfig {
    /* Modificar segun Sistema */
    public static APP = 'E-recibo';
    public static NAME_APP = "E-RECIBO";
    public static PAGEINICIO = "company/receipt";
    public static PAGEWELCOME = "welcome";
    public static PAGEVIEWCLAIMS = "company/claims";
    public static PAGELOGIN = "login";
    public static PAGELOGOUT = "logout";
    public static PAGEPROFILE = "profile";
    public static IP_ROUTE = 'https://api.seggac.com/api/web/app_dev.php';
  }