<div class="row">
  <div class="col-6 text-align-center">
    <button (click)="zoomOut()" class="mr-4 btn btn-secondary zoom-btn">Zoom -</button>
  </div>
  <div class="col-6 text-align-center">
    <button (click)="zoomIn()"class="mr-2 btn btn-secondary">Zoom +</button>
  </div>
</div>
<div>
  <image-cropper 
  [imageChangedEvent]="imageChangedEvent" 
  [maintainAspectRatio]="true" 
  [aspectRatio]="20 / 9"
  [resizeToWidth]="400"
  [containWithinAspectRatio]="true"
  format="png"
  [transform]="transform"
  (imageCropped)="imageCropped($event)" 
  (imageLoaded)="imageLoaded()"
  (cropperReady)="cropperReady()" 
  (loadImageFailed)="loadImageFailed()"></image-cropper>
</div>
<div class="row">
  <div class="col-12 text-align-center">
    <button (click)="closeModal()" class="btn btn-primary blockcenter">Guardar</button>
  </div>
</div>

