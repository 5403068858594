<div class="row">
  <div class="col col-6"><a [routerLink]="['/']">
    <img style="height: 50px;" src="assets/erecibos_logo_1x.png" />
  </a></div>
  <div class="col col-6 text-right mobile-hidden"><h1 class="mt-2 mr-5">Validación de e-recibo por QR</h1></div>
</div>
<ngx-extended-pdf-viewer
  [base64Src]="base64"
  backgroundColor="#ffffff"
  [height]="'90vh'"
  [showToolbar]="true"
  [showSidebarButton]="false"
  [showFindButton]="false"
  [showPagingButtons]="false"
  [showZoomButtons]="true"
  [showPresentationModeButton]="false"
  [showOpenFileButton]="false"
  [showPrintButton]="false"
  [showDownloadButton]="true"
  [showSecondaryToolbarButton]="false"
  [showRotateButton]="false"
  [showHandToolButton]="false"
  [showScrollingButton]="false"
  [showSpreadButton]="false"
  [showPropertiesButton]="false"
>
</ngx-extended-pdf-viewer>