<div class="welcome-links p-4">
  <h2>Selecciona una compañía</h2>
  <div *ngIf="!isNative">


    <ngx-datatable id="table" #table [rows]='userCompanies' class='material expandable' [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50"
    [scrollbarH]="false" [rowHeight]="50" [limit]="20" (page)="onPage($event)" [messages]="{emptyMessage: 'Cargando Datos',  totalMessage: 'Total'}">
    <ngx-datatable-row-detail [rowHeight]="90" #myDetailRow (toggle)="onDetailToggle($event)">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
        <div style="padding-left:40px; font-size:14px">
          <div>{{ row.companyid }} - {{ row.company }}</div>
          <div>{{ row.email }}</div>
          <div>{{ row.created }}</div>
          <div>{{ row.cbstatus }}</div>
          <div>{{ row.expirationdate }}</div>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>
    <ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false" [cellClass]="'desktop-hidden'" [headerClass]="'desktop-hidden'">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
        <span (click)="toggleExpandRow(row)" class="material-icons">
          {{ !expanded ? 'chevron_right' : 'expand_more' }}
        </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [flexGrow]="1" [headerClass]="''" [cellClass]="''">
      <ng-template let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()">Id</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span (click)="goToCompany(row)">{{ row.companyid }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [flexGrow]="4" [headerClass]="''" [cellClass]="''">
      <ng-template let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()">Compañía</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span (click)="goToCompany(row)">{{ row.company }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [flexGrow]="4" [headerClass]="''" [cellClass]="''">
      <ng-template let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()">Email</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span>{{ row.email }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [flexGrow]="5" [headerClass]="''" [cellClass]="''">
      <ng-template let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()">Creado</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span>{{ row.created }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [flexGrow]="4" [headerClass]="''" [cellClass]="''">
      <ng-template let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()">Status CB</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span>{{ row.cbstatus }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [flexGrow]="5" [headerClass]="''" [cellClass]="''">
      <ng-template let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()">Expira</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span>{{ row.expirationdate }}</span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

  </div>

  <div *ngIf="isNative">
    <mat-nav-list>
      <a mat-list-item *ngFor="let usercompany of userCompanies" (click)="goToCompany(usercompany)">
        {{ usercompany.company }}
      </a>
    </mat-nav-list>
  </div>
</div>