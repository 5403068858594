import {
  Component,
  ViewEncapsulation,
  ViewChild,
  OnInit
} from '@angular/core';
import {SeggacService} from '../../services/seggac.service';
import {DateService} from '../../services/date.service';
import {
  Employee,
  Company,
  Identificationtype,
  EmployeeRole,
} from '../../models/seggac-model';
import {
  FormGroup
} from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';


declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'list-user',
  templateUrl: './list-user.html',
  providers: [SeggacService, DateService],
  encapsulation: ViewEncapsulation.None,
})
export class UserList implements OnInit {
  employeeroles: EmployeeRole[];
  public form: FormGroup;
  employees: Employee[];
  currentEmployee: Employee;
  temp = [];
  password1: string;
  password2: string;
  editPassword: boolean = false;
  companies = [];
  identificationtypes = [];
  newEmployee: any = {
    active: '',
    companyid: '',
    identification: '',
    displayname: '',
    firstname: '',
    lastname: '',
    email: '',
    homephone: '',
    officephone: '',
    cellphone: '',
    identificationtypeid: '',
    userid: {
      username: '',
      password: '',
      active: '',
    },
    employeeroleid: '',
  };

  editEmployee: any = {
    employeeid: '',
    active: '',
    companyid: '',
    identification: '',
    displayname: '',
    firstname: '',
    lastname: '',
    email: '',
    homephone: '',
    officephone: '',
    cellphone: '',
    identificationtypeid: '',
    userid: {
      username: '',
      password: '',
      active: '',
    },
    employeeroleid: '',
  };
  columnFirstName: any = {
    name: 'Nombre',
  };
  columnLastName: any = {
    name: 'Apellido',
  };
  columnDate: any = {
    name: 'Fecha',
  };
  columnEmail: any = {
    name: 'Email',
  };
  columnState: any = {
    name: 'Estado',
  };
  listColumnFirstName = [];
  listColumnLastName = [];
  listColumnDate = [];
  listColumnEmail = [];
  listColumnState = [];

  expanded: any = {};
  timeout: any;
  @ViewChild('table') table: any;

  ngOnInit() {
    this.getEmployees();
    this.getEmployeeRoles();
    this.getIdentificationtypes();
    var self = this;
    (function($) {
      $(window).resize(function() {
        var current_width = $(window).width();
        self.listColumnFirstName = [];
        self.listColumnLastName = [];
        self.listColumnDate = [];
        self.listColumnEmail = [];
        self.listColumnState = [];
        if (current_width > 1200) {
          //self.table.rowDetail.collapseAllRows();
          self.listColumnFirstName.push(self.columnFirstName);
          self.listColumnLastName.push(self.columnLastName);
          self.listColumnEmail.push(self.columnEmail);
          self.listColumnState.push(self.columnState);
          self.listColumnDate.push(self.columnDate);
        } else if (current_width > 1100 && current_width < 1200) {
          self.listColumnFirstName.push(self.columnFirstName);
          self.listColumnLastName.push(self.columnLastName);
          self.listColumnEmail.push(self.columnEmail);
          self.listColumnState.push(self.columnState);
        } else if (current_width > 1000 && current_width < 1100) {
          self.listColumnFirstName.push(self.columnFirstName);
          self.listColumnLastName.push(self.columnLastName);
          self.listColumnEmail.push(self.columnEmail);
        } else if (current_width > 800 && current_width < 1000) {
          self.listColumnFirstName.push(self.columnFirstName);
          self.listColumnLastName.push(self.columnLastName);
        } else if (current_width > 700 && current_width < 800) {
          self.listColumnFirstName.push(self.columnFirstName);
        }
      });
    })(jQuery);
  }

  constructor(
    private SeggacService: SeggacService,
    private DateService: DateService,
  ) {
    var current_width = $(window).width();
    var self = this;
    if (current_width > 1200) {
      this.listColumnFirstName.push(this.columnFirstName);
      this.listColumnLastName.push(this.columnLastName);
      this.listColumnEmail.push(this.columnEmail);
      this.listColumnState.push(this.columnState);
      this.listColumnDate.push(this.columnDate);
    } else if (current_width > 1100 && current_width < 1200) {
      this.listColumnFirstName.push(this.columnFirstName);
      this.listColumnLastName.push(this.columnLastName);
      this.listColumnEmail.push(this.columnEmail);
      this.listColumnState.push(this.columnState);
    } else if (current_width > 1000 && current_width < 1100) {
      this.listColumnFirstName.push(this.columnFirstName);
      this.listColumnLastName.push(this.columnLastName);
      this.listColumnEmail.push(this.columnEmail);
    } else if (current_width > 800 && current_width < 1000) {
      this.listColumnFirstName.push(this.columnFirstName);
      this.listColumnLastName.push(this.columnLastName);
    } else if (current_width > 700 && current_width < 800) {
      this.listColumnFirstName.push(this.columnFirstName);
    }
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {}

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {}, 100);
  }

  getIdentificationtypes() {
    this.SeggacService.getIdentificationtypes().subscribe(
      (data: Identificationtype[]) => {
        if (data.length > 0) {
          this.identificationtypes = data.filter(
            e => e.identificationtype !== 'RUC',
          );
        }
      }
    );
  }

  getEmployeeRoles() {
    this.SeggacService.getEmployeeRoles().subscribe(
      (data: EmployeeRole[]) => {
        if (data.length > 0) {
          this.employeeroles = data;
        }
      });
  }

  getEmployees() {
    this.SeggacService.getEmployees().subscribe(
      (data: Employee[]) => {
        this.temp = [...data];
        this.employees = data;
      }
    );
  }

  openEditEmployee(row: any) {
    this.password1 = '';
    this.password2 = '';
    this.currentEmployee = JSON.parse(JSON.stringify(row));
    this.editEmployee = JSON.parse(JSON.stringify(row));
    this.editEmployee.identificationtypeid = +this.editEmployee
      .identificationtypeid;
    $('#modalEditEmployee').modal('show');
  }

  limpiarNewEmployee() {
    this.newEmployee = {
      active: true,
      companyid: '',
      identification: '',
      displayname: '',
      firstname: '',
      lastname: '',
      email: '',
      homephone: '',
      officephone: '',
      cellphone: '',
      description: '',
      identificationtypeid: '',
      userid: {
        username: '',
        password: '',
        active: true,
      },
      employeeroleid: '',
    };
    this.password1 = '';
    this.password2 = '';
    this.newEmployee.identificationtypeid = this.identificationtypes[0].identificationtypeid;
    this.newEmployee.employeeroleid = this.employeeroles[0].roleid;
  }

  openNewEmployee() {
    this.limpiarNewEmployee();
    $('#modalNewEmployee').modal('show');
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function(d) {
      if (
        (d.userid &&
          d.userid.username &&
          d.userid.username
            .toString()
            .toLowerCase()
            .indexOf(val) !== -1) ||
        !val
      )
        return true;
      else if (
        (d.displayname && d.displayname.toLowerCase().indexOf(val) !== -1) ||
        !val
      )
        return true;
      else if (
        (d.email &&
          d.email
            .toString()
            .toLowerCase()
            .indexOf(val) !== -1) ||
        !val
      )
        return true;
      else if (
        (d.identification &&
          d.identification
            .toString()
            .toLowerCase()
            .indexOf(val) !== -1) ||
        !val
      )
        return true;
    });
    this.employees = temp;
    this.table.offset = 0;
  }

  verifyPassword(employee) {
    if (this.password1.length < 6 || this.password2.length < 6) {
      $.notify(
        {
          title: '<strong>Advertencia</strong>',
          message: 'Contraseña debe tener mínimo 6 carácteres',
        },
        {
          type: 'info',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    if (this.password1 !== this.password2) {
      $.notify(
        {
          title: '<strong>Advertencia</strong>',
          message: 'Contraseña reescrita no coincide',
        },
        {
          type: 'info',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    //employee.userid.password=md5(this.password1);
    employee.userid.password = btoa(this.password1);
    return true;
  }

  verifyNewEmployeeData() {
    if (this.newEmployee.userid.username.length == 0) {
      $.notify(
        {
          title: '<strong>Validación</strong>',
          message: 'Usuario Incorrecto',
        },
        {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    if (this.newEmployee.firstname.length == 0) {
      $.notify(
        {
          title: '<strong>Validación</strong>',
          message: 'Nombre Incorrecto',
        },
        {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    if (this.newEmployee.lastname.length == 0) {
      $.notify(
        {
          title: '<strong>Validación</strong>',
          message: 'Apellido Incorrecto',
        },
        {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    if (this.newEmployee.employeeroleid == '') {
      $.notify(
        {
          title: '<strong>Validación</strong>',
          message: 'Rol de usuario Incorrecto',
        },
        {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    if (this.newEmployee.identification.length == 0) {
      $.notify(
        {
          title: '<strong>Validación</strong>',
          message: 'Numero de Identificaion Incorrecto',
        },
        {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    if (this.newEmployee.email.length == 0) {
      $.notify(
        {
          title: '<strong>Validación</strong>',
          message: 'Email Incorrecto',
        },
        {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    return true;
  }

  verifyEditEmployeeData() {
    if (this.editEmployee.userid) {
      if (this.editEmployee.userid.username.length == 0) {
        $.notify(
          {
            title: '<strong>Validación</strong>',
            message: 'Usuario Incorrecto',
          },
          {
            type: 'warning',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          },
        );
        return false;
      }
    }
    if (this.editEmployee.firstname.length == 0) {
      $.notify(
        {
          title: '<strong>Validación</strong>',
          message: 'Nombre Incorrecto',
        },
        {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    if (this.editEmployee.lastname.length == 0) {
      $.notify(
        {
          title: '<strong>Validación</strong>',
          message: 'Apellido Incorrecto',
        },
        {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    if (this.editEmployee.employeeroleid == '') {
      $.notify(
        {
          title: '<strong>Validación</strong>',
          message: 'Rol de usuario Incorrecto',
        },
        {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    if (
      this.editEmployee.identification &&
      this.editEmployee.identification.length == 0
    ) {
      $.notify(
        {
          title: '<strong>Validación</strong>',
          message: 'Numero de Identificación Incorrecto',
        },
        {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    if (this.editEmployee.email.length == 0) {
      $.notify(
        {
          title: '<strong>Validación</strong>',
          message: 'Email Incorrecto',
        },
        {
          type: 'warning',
          delay: 1000,
          timer: 1000,
          z_index: 2000,
        },
      );
      return false;
    }
    return true;
  }

  saveEmployee() {
    if (this.verifyNewEmployeeData() && this.verifyPassword(this.newEmployee)) {
      this.newEmployee.displayname = this.newEmployee.firstname + ' ' + this.newEmployee.lastname;
      this.newEmployee.companyid = +sessionStorage.getItem('_usercompany');
      Swal.fire({
        title: 'Guardando el usuario...'
      });
      this.SeggacService.addEmployee(this.newEmployee).subscribe(
        res => {
          this.newEmployee.changedate = res.date.date;
          this.newEmployee.employeeid = res.employeeid;
          this.newEmployee.userid.userid = res.userid;
          var employee = JSON.parse(JSON.stringify(this.newEmployee));
          this.employees.push(employee);
          this.temp.push(employee);
          this.employees = [...this.employees];
          $('#modalNewEmployee').modal('hide');
        },
        err => {
          Swal.close();
          $.notify(
            {
              title: '',
              message: 'Error al ingresar los datos.',
            },
            {
              type: 'warning',
              delay: 2000,
              timer: 2000,
              z_index: 2000,
            },
          );
        },
        () => {
          Swal.close();
          $.notify(
            {
              title: '',
              message: 'Se añadió el usuario exitosamente.',
            },
            {
              type: 'success',
              delay: 3000,
              timer: 3000,
              z_index: 3000,
            },
          );
        },
      );
    }
  }

  editEmployee_() {
    this.editEmployee.displayname =
      this.editEmployee.firstname + ' ' + this.editEmployee.lastname;
    if (this.editEmployee.changedate) {
      delete this.editEmployee.changedate;
    }
    if (this.editEmployee.userid.changedate) {
      delete this.editEmployee.userid.changedate;
    }
    if (this.editEmployee.signname) {
      delete this.editEmployee.signname;
    }
    let em_id = this.editEmployee.employeeid;
    delete this.editEmployee.employeeid;
    Swal.fire({
      title: 'Guardando el usuario...',
    });
    this.SeggacService.updateEmployee(this.editEmployee, em_id).subscribe(
      res => {
        this.editEmployee.changedate = res.date.date;
        this.editEmployee.employeeid = em_id;
        var employee = JSON.parse(JSON.stringify(this.editEmployee));
        var index = this.employees.findIndex(
          x => x.employeeid == employee.employeeid,
        );
        this.employees[index] = employee;
        this.employees = [...this.employees];
        let index2 = this.temp.findIndex(
          x => x.employeeid == employee.employeeid,
        );
        this.temp[index2] = employee;
        this.temp = [...this.temp];
        $('#modalEditEmployee').modal('hide');
      },
      err => {
        Swal.close();
        $.notify(
          {
            title: '',
            message: 'Error al ingresar los datos.',
          },
          {
            type: 'warning',
            delay: 2000,
            timer: 2000,
            z_index: 2000,
          },
        );
      },
      () => {
        Swal.close();
        $.notify(
          {
            title: '',
            message: 'Se editó el usuario exitosamente.',
          },
          {
            type: 'success',
            delay: 3000,
            timer: 3000,
            z_index: 3000,
          },
        );
      },
    );
  }
  updateEmployee() {
    if (this.verifyEditEmployeeData()) {
      if (this.editPassword) {
        if (this.verifyPassword(this.editEmployee)) {
          this.editEmployee_();
        }
      } else {
        this.editEmployee_();
      }
    }
  }

  deleteEmployee(row: any) {
    Swal.fire({
      title: '¿Está seguro de eliminar el usuario?',
      text: 'Una vez eliminado el usuario no se podrá recuperar',
      icon: 'warning'
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        Swal.fire({
          title: 'Eliminando el usuario...',
        });
        var tempid = +row.employeeid;
        this.SeggacService.deleteEmployee(+row.employeeid).subscribe(
          res => {
            var index = this.employees.indexOf(row);
            this.employees.splice(index, 1);
            this.employees = [...this.employees];

            var index2 = this.temp.findIndex(x => x.employeeid == tempid);
            this.temp.splice(index2, 1);
            this.temp = [...this.temp];
          },
          err => {
            Swal.close();
            $.notify(
              {
                title: '',
                message: 'Error al eliminar el usuario.',
              },
              {
                type: 'warning',
                delay: 2000,
                timer: 2000,
                z_index: 2000,
              },
            );
          },
          () => {
            Swal.close();
            $.notify(
              {
                title: '',
                message: 'Se eliminó el usuario exitosamente.',
              },
              {
                type: 'success',
                delay: 3000,
                timer: 3000,
                z_index: 3000,
              },
            );
          },
        );
      }
    });
  }

  convertDate(date) {
    return this.DateService.convertDate(date);
  }

  resetPassword(row: any) {
    Swal.fire({
      title: 'Enviando email al usuario...',
    });
    this.SeggacService.resetPassword(row).subscribe(
      res => {},
      err => {
        Swal.close();
        $.notify(
          {
            title: '',
            message: 'Error al enviar el email.',
          },
          {
            type: 'warning',
            delay: 2000,
            timer: 2000,
            z_index: 2000,
          },
        );
      },
      () => {
        Swal.close();
        $.notify(
          {
            title: '',
            message: 'Se envió el email exitosamente.',
          },
          {
            type: 'success',
            delay: 3000,
            timer: 3000,
            z_index: 3000,
          },
        );
      },
    );
  }
}
