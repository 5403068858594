import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './structure/login/login.component';
import {Welcome} from "./structure/login/welcome.component";
import {Logout} from "./structure/login/logout.component";


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'welcome', component: Welcome, pathMatch: 'full' },
  { path: 'logout', component: Logout, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
