import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client, Identificationtype} from '../../../models/seggac-model';
import { SeggacService } from '../../../services/seggac.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { User } from '../../../entities/user';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MatDialog } from '@angular/material/dialog';
import { ClientsComponent } from 'src/app/components/clients/clients.component';
import { PaymentconceptsComponent } from 'src/app/components/paymentconcepts/paymentconcepts.component';
import { BanksComponent } from 'src/app/components/banks/banks.component';
import { ClientmodalComponent } from 'src/app/components/clientmodal/clientmodal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var $: any;
declare var jQuery: any;
declare var window: any;

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VoucherComponent implements OnInit {
  public str_client = 'Client';
  voucher_print: any = {
    voucherid: '',
    date: '',
    amount: 0,
    amount_letters: '',
    deposit: 0,
    residue: 0,
    clientname: '',
    concept: '',
    paymenttype: ''
  };

  company: any = {
    company: '',
    email: '',
    phone: '',
    description: '',
    identification: '',
    address: ''
  };

  amount_letter_div = false;
  amount_letter_first = '';
  amount_letter_second = '';
  cheque_id = 2;
  cheque_is = false;
  cheque_bank = '';
  cheque_number = '';
  hidden_button_msj = false;

  email_data: any = {
    client_email: '',
    client_emailreply: '',
    userid: ''
  }

  _userid: User;
  _useremployee: any;
  myForm: FormGroup;
  identificationtypes = [];
  clienteidselected: any;
  bankidselected: any;
  today: number = Date.now();
  otherPaymentType = null;

  public paymentconcept_array: any = [];
  public paymenttype_array: any = [];
  public client_array: any = [];
  public clientsModal: any = [];
  public bank_array: any = [];
  public voucher_residue = '';
  password1: string;
  password2: string;
  checkboxValue: boolean = false;
  selectBankDisabled: boolean = true;
  rCount;
  lastId;
  rLimit;
  plan;
  country;
  initialCount;

  constructor(
    private _fb: FormBuilder,
    private SeggacService: SeggacService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.voucher_print.date  = this.datePipe.transform(Date.now(),'dd-MM-yy');
  }

  ngOnInit() {
    this.myForm = new FormGroup({
      vouchertotal: new FormControl('', <any>Validators.required),
      voucherdeposit: new FormControl(''),
      voucherresidue: new FormControl({value: '', disabled: true}, <any>Validators.required),
      voucherobservation: new FormControl(''),
      paymenttypeid: new FormControl('', <any>Validators.required),
      paymentconceptid: new FormControl('', <any>Validators.required),
      paymentconcept: new FormControl('', <any>Validators.required),
      clientid: new FormControl('', <any>Validators.required),
      client: new FormControl('', <any>Validators.required),
      bankid: new FormControl('', <any>Validators.required),
      bank: new FormControl(''),
      voucherchecknumber: new FormControl('', <any>Validators.required),
      voucheremailreply: new FormControl('')
    });
    this.myForm.get('voucherchecknumber').setValue('');
    this.myForm.get('voucherchecknumber').disable();
    this.myForm.get('bankid').setValue('');
    this.myForm.get('bankid').disable();
    this.getClients();
    this.getPaymentConcepts();
    this.getPaymentTypes();
    this.getIdentificationtypes();
    this.getUser();
    this.getCompany();
  }

  onPaymentTypeChange(value) {
    this.myForm.controls['paymenttypeid'].setValue(value);
    if (value == '3') {
      this.getBanks();
      this.selectBankDisabled = false;
      this.myForm.get('voucherchecknumber').enable();
      this.myForm.get('bankid').enable();
    } else if (value == '0') {
    }
    else {
      this.selectBankDisabled = true;
      this.myForm.get('voucherchecknumber').setValue('');
      this.myForm.get('voucherchecknumber').disable();
      this.myForm.get('bankid').setValue('');
      this.myForm.get('bankid').disable();
      this.myForm.controls['bankid'].markAsUntouched();
      this.myForm.controls['voucherchecknumber'].markAsUntouched();
    }
    this.otherPaymentType = null;
  }

  ngAfterViewInit() {
    const these = this;
    $("#amount").maskMoney().change(function() {
      these.myForm.controls['vouchertotal'].setValue($(this).val());
    });
  }

  register(voucher: any, isValid: Boolean) {
    this.myForm.markAllAsTouched();

    if (isValid) {
      this.amount_letter_div = false;
      this.cheque_is = false;
      voucher.vouchertotal = this.convertStringNumber('' + this.myForm.get('vouchertotal').value);
      voucher.voucherdeposit = this.convertStringNumber('' + this.myForm.get('vouchertotal').value);
      //voucher.voucherdeposit = this.convertStringNumber('' + this.myForm.get('voucherdeposit').value);
      voucher.voucherresidue = this.convertStringNumber('' + this.myForm.get('voucherresidue').value);
      voucher.dataemployee = this._useremployee.displayname;
      voucher.employeeid = this._useremployee.employeeid;
      voucher.databank =  this.myForm.get('bank').value;
      voucher.datapaymentconcept = this.findElement(this.paymentconcept_array, 'paymentconceptid',
        this.myForm.get('paymentconceptid').value).paymentconceptname;
      voucher.datapaymenttype = this.findElement(this.paymenttype_array, 'paymenttypeid',
        this.myForm.get('paymenttypeid').value).paymenttypename;
      voucher.datacompany = this.company.company;
      voucher.datacompanyphone = this.company.phone;
      voucher.datacompanyaddress = this.company.address;
      voucher.datacompanyemail = this.company.email;
      voucher.datacompanydescription = this.company.description;
      voucher.datacompanyidentification = this.company.identification;
      voucher.datasignname = this._useremployee.signname;
      const client_q = this.findElement(this.client_array, 'clientid', this.myForm.get('clientid').value);
      voucher.dataclient = client_q.displayname + '|' + client_q.identification;
      delete(voucher.client);
      delete(voucher.bank);
      delete(voucher.paymentconcept);
      if (this.initialCount) {
        voucher.initialCount = this.initialCount;
      }

      Swal.fire(
        {
          title: this.translate.instant('Saving receipt...')
        }
      );
      this.SeggacService.addVoucher(voucher).subscribe(
        (data: any) => {
          // this.email_data.client_emailreply = this.myForm.get('voucheremailreply').value;
          this.email_data.client_email = client_q.email;
          this.email_data.receipt_id = data.voucherid;
          this.email_data.userid = this._userid.userdata.userid;
          this.voucher_print.clientname = client_q.displayname;

          this.voucher_print.voucherid = data.voucherid;
          this.voucher_print.amount = voucher.vouchertotal;
          this.voucher_print.deposit = voucher.voucherdeposit;
          this.voucher_print.residue = voucher.voucherresidue;
          this.voucher_print.concept = voucher.datapaymentconcept;
          this.voucher_print.paymenttype = voucher.datapaymenttype;
          const pos_div: number = this.divtext(this.voucher_print.amount_letters, 35);

          if (voucher.paymenttypeid == this.cheque_id) {
            this.cheque_is = true;
            this.cheque_number = voucher.voucherchecknumber;
          }

          if (pos_div > 0) {
            this.amount_letter_div = true;
            this.amount_letter_first = this.voucher_print.amount_letters.substring(0, pos_div);
            this.amount_letter_second = this.voucher_print.amount_letters.substring(pos_div + 1);
          }
          this.cleanVoucher();
          this.getReceiptslimit()
          Swal.close();
          $('#modalMsgConfirm').modal('show');
        },
        error => {
          Swal.fire(this.translate.instant('Could not register'));
        }
      );
    }
  }

  openNewClient(){
    let dialogRef = this.dialog.open(ClientmodalComponent, {
      width: '600px',
      height: '90%'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.myForm.controls['clientid'].setValue(result.client.clientid);
      this.myForm.controls['client'].setValue(result.client.displayname);
      this.getClients();
    });
  }

  getCompany() {
    var idcompany = JSON.parse(sessionStorage.getItem('_usercompany'));
    this.SeggacService.getCompanyWithDetails(idcompany).subscribe((data: any) => {
      this.company = data;
      this.SeggacService.getCountryById(data.country).subscribe(
        data2 => {
          this.country = data2[0];
        }
      )
      if (data.planid) {
        this.getUserPlan(data);
        this.getReceiptslimit();

      }
    });
  }

  getUser(){
    this._userid = new User();
    var _userCache = JSON.parse(window.atob(sessionStorage.getItem('_userseggac')));
    this._useremployee = JSON.parse(window.atob(sessionStorage.getItem('_useremployee')));
    this._userid.userdata = _userCache.userdata;
    this._userid.signature = _userCache.signature;
    this._userid.signname = _userCache.signname;
    this._userid.userdata.userid = _userCache.userdata.userid;
  }

  getIdentificationtypes() {
    this.SeggacService.getIdentificationtypes()
      .subscribe(
        (data: Identificationtype[]) => {
          this.identificationtypes = data;
        });
  }

  getClients() {
    this.SeggacService.getClients().subscribe(
      (data: Client[]) => {
        this.client_array = data;
        this.clientsModal = data.reduce( function(filtered, obj) {
          if(obj.active){
            let client_f = {id:obj.clientid, text:obj.displayname, identification: obj.identification};
            filtered.push(client_f)
          }
          return filtered;
        },[]);
      }
    );
  }

  getPaymentTypes() {
    this.SeggacService.getPaymentType().subscribe(
      (data: any[]) => {
        this.paymenttype_array = data;
        let dataset = data.map(obj => {return {id:obj.paymenttypeid, text:obj.paymenttypename};});
        let these = this;
        $('#selectPaymentType').select2({
          data: dataset,
          'language': {
            'noResults': function () {
              return these.translate.instant('Payment type not found');
            }
          }
        });
      }
    );
  }

  getPaymentConcepts() {
    this.SeggacService.getPaymentConcept().subscribe(
      (data: any[]) => {
        this.paymentconcept_array = data;
      }
    );
  }

  getBanks() {
    this.SeggacService.getBank()
      .subscribe(
        (data: any[]) => {
          this.bank_array = data;
          let dataset = data.map(obj => {
            return {id:obj.bankid, text:obj.bankname};
          });
          let these = this;
          $('#selectBank').select2({
            data: dataset,
            'language': {
              'noResults': function () {
                return these.translate.instant('Bank not found');
              }
            }
          });
        });
  }

  getNewClient(newClient) {
    this.getClients();
    $('#modalNewClient').modal('hide');
  }

  ocultar(){
    this.hidden_button_msj = true;
  }

  sendfromform(voucher: any, isValid: Boolean){
    this.email_data.client_emailreply = this.myForm.get('voucheremailreply').value;
    this.SeggacService.editEmailReply({'emailcc':this.email_data.client_emailreply},this.email_data.receipt_id)
      .subscribe(
        (data: any[]) => {
          this.sendemail();
        }
      );
  }

  // Send email
  sendemail(download = 'false') {
    this.email_data = { ...this.email_data, ...this.voucher_print};
    const emaildata = {
      receipt_id: this.email_data.receipt_id,
      download: download,
      client_emailreply: this.email_data.client_emailreply
    };
    this.SeggacService.sendEmail(emaildata)
      .subscribe(
        (data: any[]) => {
          if (download == 'true') {
            this.SeggacService.downloadPdf(data['pdf'], data['namepdf']);
          }
        }, err => {
          $.notify({
            title: '',
            message: 'Error en envio de correo.'
          }, {
            type: 'warning',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          });
        },
        () => {
          $.notify({
            title: '',
            message: 'Se envio exitosamente.'
          }, {
            type: 'success',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          });
        },
      );
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private findElement(arr, propName, propValue) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][propName] == propValue)
      {
        return arr[i];
      }
    }
  }

  private timeout(ms) {
    return () => new Promise(resolve => setTimeout(resolve, ms));
  }

  private convertStringNumber( mystr: String) {
    mystr = mystr.replace(/,/g, '');
    if (mystr.length > 0)
      return  parseFloat(mystr + '');
    return 0;
  }

  private divtext(str: string, limit: number){
    let array_str = str.split(' ');
    let cont:number = 0;
    let temp:number = cont;
    for(let i = 0; i < array_str.length; i++){
      temp = cont;
      cont = cont + array_str[i].length + 1;
      if( cont >= (limit+2) ) {
        return (temp-1);
      }
    }
    return -1;
  }

  cleanVoucher() {
    this.voucher_residue = '';
    this.myForm.reset();
    this.myForm.markAsUntouched();
    this.hidden_button_msj = false;
  }

  numbert2Currency(num) {
    return (num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  selectOtherPaymentType(index) {
    this.myForm.controls['paymenttypeid'].setValue(this.paymenttype_array[index].paymenttypeid);
    this.otherPaymentType = this.paymenttype_array[index].paymenttypename;
  }

  selectClient() {
    let dialogRef = this.dialog.open(ClientsComponent, {
      width: '600px',
      height: '90%',
      data: {
        clients : this.clientsModal
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result['newClient']) {
        this.myForm.controls['clientid'].setValue('');
        this.myForm.controls['client'].setValue('');
        this.openNewClient();
      }
      else if (result && result['client']) {
        this.myForm.controls['clientid'].setValue(result['client']['id']);
        this.myForm.controls['client'].setValue(result['client']['text']);
      }
      else {
        this.myForm.controls['clientid'].setValue('');
        this.myForm.controls['client'].setValue('');
      }
    });
  }

  selectPaymentConcept() {
    let dialogRef = this.dialog.open(PaymentconceptsComponent, {
      width: '600px',
      height: '90%',
      data: {
        paymentconcepts : this.paymentconcept_array
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result['paymentconcept']) {
        this.myForm.controls['paymentconceptid'].setValue(result['paymentconcept']['paymentconceptid']);
        this.myForm.controls['paymentconcept'].setValue(result['paymentconcept']['paymentconceptname']);
      } else {
        this.myForm.controls['paymentconceptid'].setValue('');
        this.myForm.controls['paymentconcept'].setValue('');
      }
    });
  }

  selectBank() {
    let dialogRef = this.dialog.open(BanksComponent, {
      width: '600px',
      height: '90%',
      data: {
        banks : this.bank_array
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result['bank']) {
        this.myForm.controls['bankid'].setValue(result['bank']['bankid']);
        this.myForm.controls['bank'].setValue(result['bank']['bankname']);
      } else {
        this.myForm.controls['bankid'].setValue('');
        this.myForm.controls['bank'].setValue('');
      }
    });
  }

  openSnackBar() {
    this.snackBar.open('Solo quedan ' + (this.plan.receiptslimit - this.rCount) + 'recibo(s) disponibles para emitir este mes.', 'Entendido', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  getUserPlan(company) {
    this.SeggacService.getPlan(company.planid).subscribe((res: any) => {
      this.plan = res;
      sessionStorage.setItem('_userplan', window.btoa(JSON.stringify(res)));
      this.rLimit = this.SeggacService.getRLimit(res, this.company.companyaddon);
      sessionStorage.setItem('_useraddons', window.btoa(JSON.stringify(company.companyaddon)));
    });
  }
  
  getReceiptslimit() {
    this.SeggacService.getReceiptslimit().subscribe((res: any) => {
      this.rCount = res[0]['count(voucherid)'];
      this.lastId = res[0]['lastId'] ? res[0]['lastId'] : false;
      sessionStorage.setItem('_receiptscount', window.btoa(JSON.stringify(this.rCount)));
    });
  }
}
