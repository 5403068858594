import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {PagesConfig} from '../../entities/pages.config';
import {LoginService} from '../../services/login.service';
import {md5} from '../../services/md5';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $: any;
declare var jQuery: any;
declare var window: any;

@Component({
  selector: 'recover',
  templateUrl: './recover.html',
})
export class Recover implements OnInit {
  public form: FormGroup;
  public token = '';
  public tokenValid = false;
  public recoverMessage: string = '';
  public currentyear = new Date().getFullYear();
  constructor(
    fb: FormBuilder,
    private _router: Router,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = fb.group({});
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      if (!this.token) {
        this.recoverMessage =
          'El token usado no existe o se encuentra inactivo, por favor intente de nuevo.';
      } else {
        this.loginService.validateRecoverToken(this.token).subscribe(
          res => {
            if (res) {
              this.recoverMessage =
                'Pulse en el siguiente botón para restablecer su contraseña la cual será enviada al correo registrado.';
              this.tokenValid = true;
              this.form.setValue({token: this.token});
            } else {
              this.recoverMessage =
                'El token usado no existe o se encuentra inactivo, por favor intente de nuevo.';
            }
          },
          error => {
            this.recoverMessage =
              'El token usado no existe o se encuentra inactivo, por favor intente de nuevo.';
          },
        );
      }
    });
  }

  ngOnInit(): void {}

  /**
   *
   * @param values
   */
  onSubmit(values: any): void {
    if (!this.token) return;
    let newpass = this.randomPassword(6);
    let password = md5(newpass);
    this.loginService.resetPassword(this.token, password, newpass).subscribe(
      res => {
        Swal.fire({
          title: 'Su solicitud fue recibida!',
          text:
            'Se le enviará a su correo la información necesaria para establecer una nueva contraseña.',
        });
      },
      err => {
        $.notify(
          {
            title: '',
            message: 'Ocurrió un error al enviar el correo.',
          },
          {
            type: 'warning',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          },
        );
      },
      () => {
        this._router.navigateByUrl(PagesConfig.PAGEINICIO);
      },
    );
  }

  randomPassword(length) {
    let chars =
      'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890';
    let pass = '';
    for (let x = 0; x < length; x++) {
      let i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    return pass;
  }
}
