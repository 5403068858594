import { Component, OnInit, Inject } from '@angular/core';
import { SeggacService } from '../../services/seggac.service';
import { Identificationtype, ClientType } from '../../models/seggac-model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-clientmodal',
  templateUrl: './clientmodal.component.html',
  styleUrls: ['./clientmodal.component.css']
})

export class ClientmodalComponent implements OnInit {
  client;
  checkboxValue:boolean = false;
  identificationtypeCurrent;
  identificationtypes;
  identificationtypesEmpresa = [];
  identificationtypesPersona = [];
  clienttypes = [];
  isEdit = false;
  newClient: any;

  constructor(
    private SeggacService: SeggacService,
    public dialogRef: MatDialogRef<ClientmodalComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data && data.client) {
      this.isEdit = true;
      this.newClient = data.client;
      if ( this.newClient.clienttypeid == 2 ) {
        this.identificationtypeCurrent = this.identificationtypesEmpresa;
      } else {
        this.identificationtypeCurrent = this.identificationtypesPersona;
      }
    } else {
      this.limpiarNewClient();
      this.newClient = {
        active: true,
        companyid: "",
        identification: "",
        displayname: "",
        firstname: "",
        lastname: "",
        email: "",
        sendreadingemail: "",
        homephone: "",
        officephone: "",
        cellphone: "",
        identificationtypeid: ""
      };
    }
  }

  ngOnInit() {
    this.getIdentificationtypes();
    this.getClientTypes();
  }

  limpiarNewClient() {
    this.newClient = {
      active: true,
      companyid: "",
      identification: "",
      displayname: "",
      firstname: "",
      lastname: "",
      email: "",
      sendreadingemail: "",
      homephone: "",
      officephone: "",
      cellphone: "",
      description: "",
      clienttypeid: "",
      identificationtypeid: ""
    };
    this.checkboxValue = false;
  }

  verifyNewClientData() {
    if( this.newClient.firstname.length == 0 ) {
      $.notify({
        title: '<strong>Validacion</strong>',
        message: 'Nombre Incorrecto'
      },{
        type: 'warning',
        delay: 1000,
        timer: 1000,
        z_index: 2000,
      });
      return false;
    }
    if( this.newClient.clienttypeid === 1  && this.newClient.lastname.length == 0 ) {
      $.notify({
        title: '<strong>Validacion</strong>',
        message: 'Apellido Incorrecto'
      },{
        type: 'warning',
        delay: 1000,
        timer: 1000,
        z_index: 2000,
      });
      return false;
    }
    if( this.newClient.identification.length == 0 ) {
      $.notify({
        title: '<strong>Validacion</strong>',
        message: 'Numero de Identificaion Incorrecto'
      },{
        type: 'warning',
        delay: 1000,
        timer: 1000,
        z_index: 2000,
      });
      return false;
    }
    if (!this.validateEmail(this.newClient.email)) {
      $.notify({
        title: '<strong>Validacion</strong>',
        message: 'El correo electronico es Incorrecto'
      }, {
        type: 'warning',
        delay: 1000,
        timer: 1000,
        z_index: 2000,
      });
      return false;
    }

    return true;
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  saveClient() {
    if (this.verifyNewClientData()) {
      if (this.newClient.lastname) {
        this.newClient.displayname = this.newClient.firstname + " " + this.newClient.lastname;
      } else {
        this.newClient.displayname = this.newClient.firstname;
      }
      this.newClient.checkSendMail = this.checkboxValue;
      if (this.isEdit) {
        this.SeggacService.updateClient(this.newClient, this.newClient.clientid).subscribe(
          res => {
            this.newClient.changedate = res.date.date;
            var client = JSON.parse(JSON.stringify(this.newClient));
            this.closeModal(client);
          },
          err => {
            $.notify({
              title: '',
              message: 'Error al ingresar los datos.'
            },{
              type: 'warning',
              delay: 1000,
              timer: 1000,
              z_index: 2000,
            });
          }, () => {
            $.notify({
              title: '',
              message: 'Se editó el cliente exitosamente.'
            },{
              type: 'success',
              delay: 1000,
              timer: 1000,
              z_index: 2000,
            });
            this.limpiarNewClient();
          }
        ); 
      } else {
        this.SeggacService.addClient(this.newClient).subscribe(
          res => {
            if(res.status){
              $.notify({
                title: '',
                message: 'Se añadió el cliente exitosamente.'
              },{
                type: 'success',
                delay: 1000,
                timer: 1000,
                z_index: 2000,
              });
              this.newClient.changedate = res.date.date;
              this.newClient.clientid = res.clientid;
              var client = JSON.parse(JSON.stringify(this.newClient));
              this.closeModal(client);
              this.limpiarNewClient();
            }
            else{
              $.notify({
                title: '',
                message: 'El cliente ya existe.'
              },{
                type: 'warning',
                delay: 1000,
                timer: 10000,
                z_index: 2000,
              });
            }
          },
          err => {
            $.notify({
              title: '',
              message: 'Error al ingresar los datos.'
            },{
              type: 'warning',
              delay: 1000,
              timer: 1000,
              z_index: 2000,
            });
          }
        );
      }
    }
  }

  getIdentificationtypes() {
    this.SeggacService.getIdentificationtypes().subscribe(
      (data: Identificationtype[]) => {
        this.identificationtypes = data;
      }
    );
  }

  getClientTypes() {
    this.SeggacService.getClientTypes().subscribe(
      (data: ClientType[]) => {
        this.clienttypes = data;
      }
    );
  }

  closeModal(client = null) {
    this.limpiarNewClient();
    if (client) {
      this.dialogRef.close({client: client});
    }
    else {
      this.dialogRef.close();
    }
  }
}
