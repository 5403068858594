import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  searchText = '';
  clients: any;
  clients_initial: any;

  constructor(
    public dialogRef: MatDialogRef<ClientsComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.clients = data.clients;
    this.clients_initial = data.clients;
  }

  ngOnInit(): void {
  }

  filterItems(ev) {
    let val = ev.target.value;
    if (val && val.trim() != '') {
      this.clients = this.clients.filter(item => {
        return (
          item.text.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          item.identification.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    } else {
      this.clients = this.clients_initial;
    }
  }

  selectClient(client) {
    this.dialogRef.close({client: client});
  }

  openNewClient() {
    this.dialogRef.close({newClient: true});
  }

  closeModal() {
    this.dialogRef.close({});
  }
}
