<ng-progress #progressBar (started)="onStarted()" (completed)="onCompleted()"></ng-progress>

<cat-menu-left *ngIf = "isLogged"></cat-menu-left>
<cat-top-bar *ngIf = "isLogged"></cat-top-bar>
<div class="cat__content">
  <router-outlet #outlet="outlet"></router-outlet>
  <cat-footer *ngIf = "isLogged"></cat-footer>
  <a id="chargebee-button-plan" style="opacity: 0; position: absolute; width: 0; height: 0;" class="btn btn-primary" href="javascript:void(0)" data-cb-type="portal">Cambiar plan</a>
</div>
<div [className]="'overlay-progress ' + ' ' + (inProgress ? 'disabledwindow' : '') + (isLogged ? '' : 'login')">
  <div id="loading-bar-spinner" class="spinner"><div class="spinner-icon"></div></div>
</div> 