import { Component } from '@angular/core';
import { PagesConfig } from '../../entities/pages.config';
import { Router } from '@angular/router';
import {LoginService} from '../../services/login.service';

@Component({
  selector: 'logout',
  templateUrl: './logout.html'
})
export class Logout {
  constructor(private loginService: LoginService, private _router: Router) {
    sessionStorage.removeItem('_userseggac');
    sessionStorage.removeItem('_usercompany');
    sessionStorage.removeItem('_userplan');
    sessionStorage.removeItem('_useraddons');
    this.loginService.removeUserSeggac();
    this._router.navigateByUrl(PagesConfig.PAGELOGIN);
  }
}
