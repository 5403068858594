<div class="cat__menu-right-filter">
  <div class="cat__menu-right__inner">
    <div class="cat__menu-right__header">
      <span class="fa fa-times pull-right cat__menu__filterAdvanced-right__action--menu-toggle">
        <!-- -->
      </span>
      Filtro Avanzado
    </div>
    <div class="cat__menu-right__content">
      <div class="cat__menu-right__label">
        Proyectos
      </div>
      <div class="cat__menu-right__setting">
        <mat-form-field class="">
          <mat-label class="col-md-3 col-form-label">Proyectos</mat-label>
        </mat-form-field>
        <mat-form-field class="">
          <div class="col-md-12">
            <select #selectProject class="select2 selectProject" multiple>
              <option *ngFor="let item of projects" [value]="item.projectid">
                {{item.project}}
              </option>
            </select>
          </div>
        </mat-form-field>
      </div>
      <div class="cat__menu-right__setting">
        <mat-form-field class="">
          <mat-label class="col-md-3 col-form-label">Modelos</mat-label>
        </mat-form-field>
        <mat-form-field class="">
          <div class="col-md-12">
            <select #selectModel class="select2 selectModel" multiple [disabled]="!isSelectedProject">
              <option *ngFor="let item of modelsForSelect" [value]="item.propertymodelid">
                {{item.propertymodel}} ( {{item.projectid.project}} )
              </option>
            </select>
          </div>
        </mat-form-field>
      </div>
      <div class="cat__menu-right__label">
        Propiedades
      </div>
      <div class="cat__menu-right__setting">
        <mat-form-field class="">
          <mat-label class="col-md-10 col-form-label">Tipos de Propiedades</mat-label>
        </mat-form-field>
        <mat-form-field class="">
          <div class="col-md-12">
            <select #selectPropertyType class="select2 selectPropertyType" multiple>
              <option *ngFor="let item of propertiesTypes" [value]="item.propertytypeid">
                {{item.propertytype}}
              </option>
            </select>
          </div>
        </mat-form-field>
      </div>
      <div class="cat__menu-right__label">
        Fechas
      </div>
      <div class="cat__menu-right__setting">
        <mat-form-field class="">
          <mat-label class="col-md-10 col-form-label">Prog. Const.</mat-label>
        </mat-form-field>
        <div class="cat__menu-right__setting">
          <mat-form-field class="">
            <mat-label class="col-md-10 col-form-label">Ultimo(s)</mat-label>
          </mat-form-field>
          <mat-form-field class="">
            <div class="col-md-12">
              <select #selectDateLastTypeConst class="select2 selectDateLastTypeConst">
                <option *ngFor="let item of lastDatesSelect" [value]="item.id">
                  {{item.title}}
                </option>
              </select>
            </div>
          </mat-form-field>
        </div>
        <mat-form-field class="">
          <mat-label class="col-md-3 col-form-label">De:</mat-label>
          <input matInput type="text" class="form-control col-md-9 datepicker-only-init" id="fromDateProgConst" [disabled] = "selectDateTypeConst"/>
        </mat-form-field>
        <mat-form-field class="">
          <mat-label class="col-md-3 col-form-label">Hasta:</mat-label>
          <input matInput type="text" class="form-control col-md-9 datepicker-only-init" id="toDateProgConst" [disabled] = "selectDateTypeConst"/>
        </mat-form-field>
      </div>
      <div class="cat__menu-right__setting">
        <mat-form-field class="">
          <mat-label class="col-md-10 col-form-label">Prog. Ventas.</mat-label>
        </mat-form-field>
        <div class="cat__menu-right__setting">
          <mat-form-field class="">
            <mat-label class="col-md-10 col-form-label">Ultimo(s)</mat-label>
          </mat-form-field>
          <mat-form-field class="">
            <div class="col-md-12">
              <select #selectDateLastTypeSale class="select2 selectDateLastTypeSale">
                <option [value]="null"></option>
                <option *ngFor="let item of lastDatesSelect" [value]="item.id">
                  {{item.title}}
                </option>
              </select>
            </div>
          </mat-form-field>
        </div>
        <mat-form-field class="">
          <mat-label class="col-md-3 col-form-label">De:</mat-label>
          <input type="input" class="form-control col-md-9 datepicker-only-init" id="fromDateProgSales" [disabled] = "selectDateTypeSale"/>
        </mat-form-field>
        <mat-form-field class="">
          <mat-label class="col-md-3 col-form-label">Hasta:</mat-label>
          <input type="input" class="form-control col-md-9 datepicker-only-init" id="toDateProgSales" [disabled] = "selectDateTypeSale"/>
        </mat-form-field>
      </div>
      <div class="cat__menu-right__setting" style="padding-bottom: 30px;">
        <mat-form-field class="">
          <mat-label class="col-md-10 col-form-label">Entrega</mat-label>
        </mat-form-field>
        <div class="cat__menu-right__setting">
          <mat-form-field class="">
            <mat-label class="col-md-10 col-form-label">Ultimo(s)</mat-label>
          </mat-form-field>
          <mat-form-field class="">
            <div class="col-md-12">
              <select #selectDateLastTypeDeliver class="select2 selectDateLastTypeDeliver">
                <option *ngFor="let item of lastDatesSelect" [value]="item.id">
                  {{item.title}}
                </option>
              </select>
            </div>
          </mat-form-field>
        </div>
        <mat-form-field class="">
          <mat-label class="col-md-3 col-form-label">De:</mat-label>
          <input type="input" class="form-control col-md-9 datepicker-only-init" id="fromDateDeliver" [disabled] = "selectDateTypeDeliver"/>
        </mat-form-field>
        <mat-form-field class="">
          <mat-label class="col-md-3 col-form-label">Hasta:</mat-label>
          <input type="input" class="form-control col-md-9 datepicker-only-init" id="toDateDeliver" [disabled] = "selectDateTypeDeliver"/>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>