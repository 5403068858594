import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ServiceCli } from './serviceclient.page';
import { FormsModule } from '@angular/forms';
import { CanActivateViaAuthGuard } from '../../security/canactivateviaauthguard'
import { InlineSVGModule } from "ng-inline-svg";
import { MaterialModule } from 'src/app/material.module';

export const routes: Routes = [
  {
    path: 'company/servicecli',
    component: ServiceCli,
    canActivate: [
      CanActivateViaAuthGuard
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    InlineSVGModule,
    MaterialModule
  ],
  declarations: [
    ServiceCli
  ]
})

export class ServiceCliModule { }

