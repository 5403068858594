import { Component, ViewChild, ViewChildren, OnInit, AfterViewInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { SeggacService } from '../../../services/seggac.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { convNumLetra } from '../../../services/conv_num_letra';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../entities/user';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MatDialog } from '@angular/material/dialog';
import { XlsxService } from 'src/app/services/xlsx.service';
import { Capacitor } from '@capacitor/core';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['./voucher-list.component.css']
})

export class VoucherListComponent implements OnInit, AfterViewInit, AfterViewChecked {

  inputsearch;
  date1;
  date2;
  voucher_print: any = {
    voucherid:"",
    date: "",
    amount: 0,
    amount_letters: "",
    deposit: 0,
    residue: 0,
    clientname: "",
    employeename: "",
    concept: "",
    paymenttype: ""
  };

  amount_letter_div = false;
  amount_letter_first = "";
  amount_letter_second = "";
  cheque_id = 2;
  cheque_is = false;
  cheque_bank = "";
  cheque_number = "";

  email_data: any = {
    client_email:"",
    client_emailreply:"",
    userid: ""
  }

  public _userid: User;
  _useremployee: any;

  company: any = {
    company: '',
    email: '',
    phone: '',
    description: '',
    identification: '',
    address: ''
  };

  country;

  temp = [];
  public myForm: FormGroup;
  today: number = Date.now();
  voucher_array: any = [];

  @ViewChild(DatatableComponent) tablereceipt: DatatableComponent;
  @ViewChild('tableWrapper') tableWrapper;
  private currentComponentWidth;
  @ViewChildren('popover') popover: any;
  popContentShown = false;

  showAdvanced = false;
  isNative: Boolean

  constructor(
    private _fb: FormBuilder,
    private SeggacService: SeggacService,
    private conNumLetra: convNumLetra,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private xlsxService: XlsxService
  ) {

  }

  ngOnInit() {
    this.isNative = Capacitor.isNativePlatform() ? true : false;
    this.getUser();
    this.getCompany();
    this.getVoucher();
    this.myForm = new FormGroup({
      voucheremailreply: new FormControl('')
    });

    document.addEventListener('click', (e) => {
      if (e.target['tagName'] !== 'svg' && e.target['tagName'] !== 'path') {
        this.popover.forEach(e => e.close());
        this.popContentShown = false;
        let body = document.getElementsByTagName('body')[0];
        body.classList.remove('popover-shown');
      }
    });
  }
  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
    this.voucher_array = [...this.voucher_array];
    this.tablereceipt.recalculate();
    if (this.tablereceipt && this.tablereceipt.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {
      this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
      this.tablereceipt.recalculate();
      this.changeDetectorRef.detectChanges();
    }
  }

  ngAfterViewInit() {
    $(".datepicker-only-init").datetimepicker({
      icons: {
        previous: 'fa fa-arrow-left',
        next: 'fa fa-arrow-right',
        clear: 'fa fa-times-circle-o'
      },
      format: 'YYYY-MM-DD'
    }).on('dp.hide', e => { 
      if (e.currentTarget.id === 'date1') {
        this.date1 = e.date._d;
        this.filtercore();
      } else {
        this.date2 = e.date._d;
        this.filtercore();
      }
    });
  }

  /**
   * 
   * @param row 
   * @param download 
   */
  sendEmail(row, download = 'false') {
    this.email_data.receipt_id = row.voucherid;
    this.email_data.date = this.datePipe.transform(row.voucherdate,'dd-MM-yy');
    this.email_data.amount = row.vouchertotal;
    this.email_data.deposit = row.voucherdeposit;
    this.email_data.residue = row.voucherresidue;
    this.email_data.clientname = row.clientid.displayname;
    this.email_data.concept = row.paymentconceptid.paymentconceptname;
    this.email_data.client_email = row.clientid.email;
    this.email_data.client_emailreply  = row.voucheremailreply;
    this.email_data.paymenttype  = row.paymenttypeid.paymenttypename;
    this.email_data.userid = this._userid.userdata.userid;
    this.email_data['company_name'] = this.company.company;
    this.email_data['company_description'] = this.company.description;
    this.email_data['company_identification'] = this.company.identification;
    this.email_data['company_phone'] = this.company.phone;
    this.email_data['company_address'] = this.company.address;
    this.email_data['company_email'] = this.company.email;
    this.email_data['employeename'] = this._useremployee.displayname;
    this.email_data['usersignature'] = this._userid.signature;
    this.email_data['cheque_bank'] = row.databank;
    this.email_data['cheque_number'] = row.voucherchecknumber;
    this.email_data.amount_letters = this.conNumLetra.NumeroALetras('' + row.vouchertotal);

    if (download == 'false') {
      this.myForm.controls['voucheremailreply'].setValue(row.voucheremailreply);
      $('#modalMsgConfirm').modal('show');
    } else {
      this.sendemail(download);
    }
  }

  sendfromform(voucher: any, isValid: Boolean){
    this.email_data.client_emailreply = this.myForm.get('voucheremailreply').value;
    this.SeggacService.editEmailReply({"emailcc":this.email_data.client_emailreply},this.email_data.receipt_id)
      .subscribe(
        (data: any[]) => {
          this.sendemail();
        }
      );
  }

  sendemail(download = 'false') {
    const emaildata = {
      receipt_id: this.email_data.receipt_id,
      download: download,
      client_emailreply: this.email_data.client_emailreply
    };
    this.SeggacService.sendEmail(emaildata)
      .subscribe(
        (data: any[]) => {
          if (download == 'true') {
            this.SeggacService.downloadPdf(data['pdf'], data['namepdf']); 
          }         
        },
        err => {
          let body = document.getElementsByTagName('body')[0];
          body.classList.remove('popover-shown');
          $.notify({
            title: '',
            message: 'Error en envio de correo.'
          }, {
            type: 'warning',
            delay: 1000,
            timer: 1000,
            z_index: 2000,
          });
        },
        () => {
          let body = document.getElementsByTagName('body')[0];
          body.classList.remove('popover-shown');
          if (download == 'true') {
            $.notify({
              title: '',
              message: 'Se descargó exitosamente.'
            }, {
              type: 'success',
              delay: 1000,
              timer: 1000,
              z_index: 2000,
            });  
          } else {
            $.notify({
              title: '',
              message: 'Se envió exitosamente.'
            }, {
              type: 'success',
              delay: 1000,
              timer: 1000,
              z_index: 2000,
            });
            $('#modalMsgConfirm').modal('hide');
          }

          this.getVoucher();
        }
      );
  }

  view(row : any){

  }

  private ordenarAsc(p_array_json, p_key) {
    p_array_json.sort(function (a, b) {
      return a[p_key] > b[p_key];
    });
  }

  print(row: any){
    this.sendEmail(row, 'true');
  }

  delete(row: any){
    Swal.fire({
      title: this.translate.instant("Do you want to cancel this receipt?"),
      text: this.translate.instant("Important: Once canceled it will not be possible to recover. The client will be notified via email about the cancellation."),
      icon: 'warning',
      showCloseButton: true
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        Swal.fire(
          {
            title: 'Cancelando recibo...'
          }
        );
        this.SeggacService.deleteVoucher(row.voucherid).subscribe(
          (data: any) => {
            Swal.close();
            this.getVoucher();
          }
        );
      }
    });
  }

  getCompany(){
    var idcompany = JSON.parse(sessionStorage.getItem('_usercompany'));
    this.SeggacService.getCompanyWithDetails(idcompany).subscribe(
      (data: any) => {
        this.company = data;
        this.SeggacService.getCountryById(data.country).subscribe(
          data2 => {
            this.country = data2[0];
          }
        )
      }
    );
  }

  getUser(){
    this._userid = new User();
    var _userCache = JSON.parse(window.atob(sessionStorage.getItem('_userseggac')));
    this._useremployee = JSON.parse(window.atob(sessionStorage.getItem('_useremployee')));
    this._userid.userdata = _userCache.userdata;
    this._userid.signature = _userCache.signature;
    this._userid.signname = _userCache.signname;
    this._userid.userdata.userid = _userCache.userdata.userid;
  }

   getVoucher(){
    this.SeggacService.getVoucher()
      .subscribe(
        (data: any) => {
          this.temp = [...data];
          this.voucher_array = data;
        });
  }

  private timeout(ms) {
    return () => new Promise(resolve => setTimeout(resolve, ms));
  }

  private divtext(str: string, limit: number){
    let array_str = str.split(" ");
    let cont:number = 0;
    let temp:number = cont;
    for(let i = 0; i < array_str.length; i++){
      temp = cont;
      cont = cont + array_str[i].length + 1;
      if(cont>=(limit+2)){
        return (temp-1);
      }
    }
    return -1;
  }

  updateFilter(event) {
    this.inputsearch = event.target.value.toLowerCase();
    this.filtercore(); 
  }

  clearDate1() {
    $(".date1").data("DateTimePicker").clear();
    this.date1 = undefined;
    this.filtercore();
  }
  clearDate2() {
    $(".date2").data("DateTimePicker").clear(); 
    this.date2 = undefined;
    this.filtercore();
  }

  filtercore() {
    let temp = this.temp.filter(d => {
      if (d.voucherid && d.clientid && 
        d.clientid.displayname && 
        d.clientid.displayname.toLowerCase().indexOf(this.inputsearch) !== -1 || !this.inputsearch)
        return true;
      else if (d.voucherid && d.clientid && 
        d.clientid.identification && 
        d.clientid.identification.toString().toLowerCase().indexOf(this.inputsearch) !== -1 || !this.inputsearch)
        return true;
      else if (d.voucherid && d.paymentconceptid && d.paymentconceptid.paymentconceptname && d.paymentconceptid.paymentconceptname.toString().toLowerCase().indexOf(this.inputsearch) !== -1 || !this.inputsearch)
        return true;
      else if (d.voucherid && d.voucherid.toString().toLowerCase().indexOf(this.inputsearch) !== -1 || !this.inputsearch)
        return true;
      else if (d.voucherid && d.vouchertotal.toString().toLowerCase().indexOf(this.inputsearch) !== -1 || !this.inputsearch)
        return true;
      return false;
    });

    if (this.date1 && this.date1 !== undefined) {
      temp = temp.filter(d => (new Date(d.voucherdate)).setHours(0,0,0,0) >= this.date1.setHours(0,0,0,0));
    }

    if (this.date2 && this.date2 !== undefined) {
      temp = temp.filter(d => (new Date(d.voucherdate)).setHours(0,0,0,0) <= this.date2.setHours(0,0,0,0));
    }

    this.voucher_array = temp;
    this.tablereceipt.offset = 0;
  }

  getRowClass(row) {
    return {
      'row-receipt': true
    };
  }

  toggleExpandRow(row) {
    this.tablereceipt.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
  }

  showPopContent() {
    this.popContentShown = true;
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('popover-shown');
  }

  exportToExcel() {
    let vouchersExcel = [];
    this.voucher_array.forEach(voucher => {
      vouchersExcel.push ({
        '#': voucher.voucherid,
        'fecha': voucher.voucherdate,
        'cliente': (voucher.dataclient.split('|'))[0],
        'id': (voucher.dataclient.split('|'))[1] ? (voucher.dataclient.split('|'))[1] : voucher.clientid.identification,
        'monto': parseFloat(voucher.vouchertotal).toFixed(2),
        'concepto': voucher.datapaymentconcept,
        'observaciones': voucher.voucherobservation,
        'forma de pago': voucher.datapaymenttype,
        'banco': voucher.databank,
        'nro cheque': voucher.voucherchecknumber,
        'emitido por': voucher.employeeid.firstname + ' ' + voucher.employeeid.lastname,
        'estado': voucher.active == true ? 'emitido' : 'anulado'
      });
    }

    );
    this.xlsxService.exportToExcel(vouchersExcel, 'Lista de Erecibos');
  }

  customSplit(str) {
    return str.split('|');
  }
    
} 
