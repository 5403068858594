import {Component, OnInit} from '@angular/core';
import { NgProgress } from 'ngx-progressbar';
import { ChangeDetectorRef } from '@angular/core';
import { LoginService } from './services/login.service'
import { TranslateService } from '@ngx-translate/core';
import {Subscription} from 'rxjs/index';
import { SeggacService } from './services/seggac.service';
import { ValidationsService } from './services/validations.service';
import { Capacitor } from '@capacitor/core';

declare var UXCam:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLogged: boolean;
  companyStatus: boolean;
  usertypeid: any;
  _token;
  _subscription: Subscription;
  _subscription_2: Subscription;
  title = 'e-recibos-capacitor';
  inProgress = false;

  constructor(
    public ngProgress: NgProgress,
    private cdr: ChangeDetectorRef,
    public loginService: LoginService,
    public seggacService: SeggacService,
    private translate: TranslateService,
    private validationsService: ValidationsService
  ) {
    translate.setDefaultLang('es');
    this._subscription = loginService.getIsLogged().subscribe (value => {
      this.isLogged = value;
    });
    this._subscription = loginService.getUsertype().subscribe (value => {
      this.usertypeid = value;
    });
    this._subscription = seggacService.getCompanyStatusValue().subscribe (value => {
      this.companyStatus = value;
    });
  }

  ngOnInit() {
    if (Capacitor.isNativePlatform()) {
      UXCam.optIntoSchematicRecordings();
      UXCam.startWithKey("t3ry9yxeiltz9e0");
    }
  }
  ngAfterViewInit() {
    //this.seggacService.checkStatus();
    this.seggacService.companyStatus.subscribe(res => {
      this.companyStatus = res;
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this._subscription_2.unsubscribe();
    this._subscription.unsubscribe();
  }

  onCompleted() {
    this.inProgress = false;
  }

  onStarted() {
    this.inProgress = true;
  }


}
