<nav class="cat__menu-left">
  <div class="cat__menu-left__lock cat__menu-left__action--menu-toggle">
    <div class="cat__menu-left__pin-button">
      <div><!-- --></div>
    </div>
  </div>
  <div class="cat__menu-left__logo">
    <img src="assets/iconos/logo_menu.png" alt="" class="img-logo">
  </div>
  <mat-nav-list class="cat__menu-left__inner">
    <ul class="cat__menu-left__list cat__menu-left__list--root">
      <li class="cat__menu-left__item cat__menu-left__submenu" [routerLinkActive]="['cat__menu-left__item--active']">
        <a mat-list-item href="javascript: void(0);">
          {{ 'RECEIPTS' | translate }}
        </a>
        <ul class="cat__menu-left__list">
          <li class="cat__menu-left__item" [routerLinkActive]="['cat__menu-left__item--active']">
            <a mat-list-item [routerLink]="[modules.createVoucher.url]">
              {{ 'Generate Receipt' | translate }}
            </a>
          </li>
          <li class="cat__menu-left__item" [routerLinkActive]="['cat__menu-left__item--active']">
            <a mat-list-item [routerLink]="[modules.listVoucher.url]">
              {{ 'List Receipts' | translate }}
            </a>
          </li>
        </ul>
      </li>
      <li [hidden]="!modules.listClient.show" class="cat__menu-left__item cat__menu-left__submenu" [routerLinkActive]="['cat__menu-left__item--active']">
        <a mat-list-item href="javascript: void(0);">
          {{ 'CUSTOMERS' | translate }}
        </a>
        <ul class="cat__menu-left__list">
          <li class="cat__menu-left__item" [routerLinkActive]="['cat__menu-left__item--active']">
            <a mat-list-item [routerLink]="[modules.listClient.url]">
              {{ 'List Customers' | translate }}
            </a>
          </li>
        </ul>
      </li>

      <li class="cat__menu-left__item cat__menu-left__submenu" [routerLinkActive]="['cat__menu-left__item--active']">
        <a mat-list-item href="javascript: void(0);">
          {{ 'SETTINGS' | translate }}
        </a>
        <ul class="cat__menu-left__list">
          <li class="cat__menu-left__item" [routerLinkActive]="['cat__menu-left__item--active']">
            <a mat-list-item [routerLink]="[modules.bank.url]">
              {{ 'Banks' | translate }}
            </a>
          </li>
          <li class="cat__menu-left__item" [routerLinkActive]="['cat__menu-left__item--active']">
            <a mat-list-item [routerLink]="[modules.paymenttype.url]">
              {{ 'Payment Types' | translate }}
            </a>
          </li>
          <li class="cat__menu-left__item" [routerLinkActive]="['cat__menu-left__item--active']">
            <a mat-list-item [routerLink]="[modules.paymentconcept.url]">
              {{ 'Payment Concepts' | translate }}
            </a>
          </li>
          <li [hidden]="!modules.listUser.show" class="cat__menu-left__item" [routerLinkActive]="['cat__menu-left__item--active']">
            <a mat-list-item [routerLink]="[modules.listUser.url]">
              {{ 'Users' | translate }}
            </a>
          </li>
          <li class="cat__menu-left__item" [routerLinkActive]="['cat__menu-left__item--active']">
            <a mat-list-item [routerLink]="[modules.company.url]">
              {{ 'Company' | translate }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </mat-nav-list>
</nav>
