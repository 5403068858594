import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { Project, Model, Property, TypeProperty } from '../../../models/seggac-model';
import { SeggacService } from '../../../services/seggac.service';
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from 'rxjs/Rx';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'cat-menu-filterAdvancedProperty-right',
  templateUrl: './filter-property.menu.html',
  providers: [SeggacService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuFilterAdvancedPropertyRightComponent implements OnInit, AfterViewInit {
  @Input() rows: Array<Property>;
  @Input() temp: Array<Property>;
  @Input() models: Array<Model>;
  @Input() propertiesTypes: Array<TypeProperty>;
  @Input() projects: Array<Project>;
  @Output() rowsOut: EventEmitter<Array<Property>> = new EventEmitter<Array<Property>>();
  @Output() filterLabelOut: EventEmitter<String> = new  EventEmitter<String>();
  rowsFilter: Array<Property> = [];
  modelsForSelect: Model[] = [];
  isSelectedProject: boolean = false;
  filterLabel = "";
  filterData = {
    projectsSelected: [],
    modelsSelected: [],
    propertiesTypeSelected: [],
    lastDateConst: 0,
    lastDateSales: 0,
    lastDateDeliver: 0,
  }
  today: Date;
  yesterday: Date;
  oneMonthAgo: Date;
  oneYearAgo: Date;
  oneWeekAgo: Date;
  fromDateProgConst = "";
  toDateProgConst = "";
  fromDateProgSales = "";
  toDateProgSales = "";
  fromDateDeliver = "";
  toDateDeliver = "";
  selectDateTypeConst = true;
  selectDateTypeSale = true;
  selectDateTypeDeliver = true;
  lastDatesSelect = [ 
    { id: 0 ,title: " Ninguno"},
    { id: 1 ,title: " dia"},
    { id: 2 ,title: " Semana"},
    { id: 3 ,title: " Mes"},
    { id: 4 ,title: " Año"},
    { id: 5 ,title: " Personalizado"}
  ]
  selectPersonalized = this.lastDatesSelect[5].id.toString();
  selectNothing = this.lastDatesSelect[0].id.toString();
  selectLastDay = this.lastDatesSelect[1].id.toString();
  selectLastWeek = this.lastDatesSelect[2].id.toString();
  selectLastMonth = this.lastDatesSelect[3].id.toString();
  selectLastYear = this.lastDatesSelect[4].id.toString();
  constructor(private SeggacService: SeggacService, private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    var self = this;
    $(function () {
      $('.select2').select2();
      $('.select2-tags').select2({
        tags: true,
        tokenSeparators: [',', ' ']
      });
      
      $('.datepicker-only-init').datetimepicker({
        icons: {
          previous: 'fa fa-arrow-left',
          next: 'fa fa-arrow-right'
        },
        format: 'YYYY-MM-DD'
      }).on('dp.change', e => {
        if (self[e.target.id] == e.target.value)
          return;
        self[e.target.id] = e.target.value;
        self.updateRows();
      });
    });
    this.today = new Date();
    this.yesterday = new Date(this.today);
    this.yesterday.setDate(this.yesterday.getDate() - 1);
    this.oneWeekAgo = new Date(this.today);
    this.oneWeekAgo.setDate(this.oneWeekAgo.getDate() - 7);
    this.oneMonthAgo = new Date(this.today);
    this.oneMonthAgo.setMonth(this.oneMonthAgo.getMonth() - 1);
    this.oneYearAgo = new Date(this.today);
    this.oneYearAgo.setFullYear(this.oneYearAgo.getFullYear() - 1);
  }

  ngAfterViewInit() {
    $('.cat__menu__filterAdvanced-right__action--menu-toggle').on('click', function () {
      $('body').toggleClass('filter-right--visible');
    });
    var self = this;
    $('.selectProject').on('select2:select', function (e) {
      var data = e.params.data;
      self.saveProjectFilter(data);
      self.updateModelsSelect(data);
    });
    $('.selectProject').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.deleteProjectFilter(data);
      self.updateModelsSelect(data);
    });
    $('.selectModel').on('select2:select', function (e) {
      var data = e.params.data;
      self.saveModelFilter(data);
    });
    $('.selectModel').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.deleteModelFilter(data);
    });
    $('.selectPropertyType').on('select2:select', function (e) {
      var data = e.params.data;
      self.savePropertyTypeFilter(data);
    });
    $('.selectPropertyType').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.deletePropertyTypeFilter(data);
    });
    $('.selectDateLastTypeConst').on('select2:select', function (e) {
      var data = e.params.data;
      self.saveDateLastTypeConstFilter(data);
    });
    $('.selectDateLastTypeSale').on('select2:select', function (e) {
      var data = e.params.data;
      self.saveDateLastTypeSaleFilter(data);
    });
    $('.selectDateLastTypeDeliver').on('select2:select', function (e) {
      var data = e.params.data;
      self.saveDateLastTypeDeliverFilter(data);
    });
  }

  /**
    * Save in List "filterData" the id of Project selected.
    * @param id
    */
  saveProjectFilter(id)
  {
    this.filterData.projectsSelected.push(id);
  }

  /**
    * Delete in List "filterData" the id of Project selected.
    * @param id
    */
  deleteProjectFilter(id)
  {
    var index = this.filterData.projectsSelected.indexOf(id);
    this.filterData.projectsSelected.splice(index, 1);
  }

  /**
    * Update the List "modelsForSelect" when  Project is selected.
    * @param id
    */
  updateModelsSelect(id)
  {
    this.isSelectedProject=false;
    this.modelsForSelect = [];
    for (let proj of this.filterData.projectsSelected) {
      this.modelsForSelect = this.modelsForSelect.concat(this.models.filter(function (d) {
        return d.projectid && d.projectid.projectid.toString().toLowerCase().indexOf(proj.id) !== -1 || !proj.id
      }));
    }
    this.modelsForSelect.length>0?this.isSelectedProject=true:this.filterData.modelsSelected=[];
    this.updateRows();
  }

  /**
    * Save in List "filterData" the id of Model selected.
    * @param id
    */
  saveModelFilter(id)
  {
    this.filterData.modelsSelected.push(id);
    this.updateRows();
  }

  /**
    * Delete in List "filterData" the id of Model selected.
    * @param id
    */
  deleteModelFilter(id)
  {
    var index = this.filterData.modelsSelected.indexOf(id);
    this.filterData.modelsSelected.splice(index, 1);
    this.updateRows();
  }

  /**
    * Save in List "filterData" the id of Property Type selected.
    * @param id
    */
  savePropertyTypeFilter(id)
  {
    this.filterData.propertiesTypeSelected.push(id);
    this.updateRows();
  }

  /**
    * Delete in List "filterData" the id of Property Type selected.
    * @param id
    */
  deletePropertyTypeFilter(id)
  {
    var index = this.filterData.propertiesTypeSelected.indexOf(id);
    this.filterData.propertiesTypeSelected.splice(index, 1);
    this.updateRows();
  }

  /**
    * Save in List "filterData" the id of Date Type for Type Construction .
    * @param data
    */
  saveDateLastTypeConstFilter(data)
  {
    this.fromDateProgConst = "";
    this.toDateProgConst = "";
    this.filterData.lastDateConst = +data.id;
    if (data.id === this.selectPersonalized) {
      this.selectDateTypeConst = false;
      this.rowsOut.emit([...this.rows]);
    } else {
      this.selectDateTypeConst = true;
      this.updateRows();
    } 
  }

  /**
    * Save in List "filterData" the id of Date Type for Type Sale .
    * @param data
    */
  saveDateLastTypeSaleFilter(data)
  {
    this.fromDateProgSales = "";
    this.toDateProgSales = "";
    this.filterData.lastDateSales = +data.id;
    if (data.id === this.selectPersonalized) {
      this.selectDateTypeSale = false;
      this.rowsOut.emit([...this.rows]);
    } else {
      this.selectDateTypeSale = true;
      this.updateRows();
    } 
  }

  /**
    * Save in List "filterData" the id of Date Type for Type Deliver .
    * @param data
    */
  saveDateLastTypeDeliverFilter(data)
  {
    this.fromDateDeliver = "";
    this.toDateDeliver = "";
    this.filterData.lastDateDeliver = +data.id;
    if (data.id === this.selectPersonalized) {
      this.selectDateTypeDeliver = false
      this.rowsOut.emit([...this.rows]);
    } else {
      this.selectDateTypeDeliver = true;
      this.updateRows();
    } 
  }

  /**
    * Filter Properties depending its select options and
    * Emit it to Parent Component.
    */
  updateRows() {
    var filtered = [];
    this.rowsFilter = [...this.temp];
    this.filterLabel = "";  
    if(this.filterData.projectsSelected.length == 0 && this.filterData.modelsSelected.length == 0  && this.filterData.propertiesTypeSelected.length == 0 && this.filterData.modelsSelected.length == 0 && this.fromDateDeliver.length == 0 && this.toDateDeliver.length == 0 && this.fromDateProgConst.length == 0 && this.toDateProgConst.length == 0 && this.fromDateProgSales.length == 0 && this.toDateProgSales.length == 0 && this.filterData.lastDateConst == 0 && this.filterData.lastDateSales == 0 &&  this.filterData.lastDateDeliver == 0 ||  this.filterData.lastDateSales == +this.selectPersonalized ||  this.filterData.lastDateDeliver == +this.selectPersonalized) {
      this.rowsOut.emit([...this.rowsFilter]);
    } else {
      this.filterLabel = "project:["; 
      for (let val of this.filterData.projectsSelected) {
        filtered = filtered.concat(this.rowsFilter.filter(function (d) {
          return d.propertymodelid.projectid && d.propertymodelid.projectid.projectid.toString().toLowerCase().indexOf(val.id) !== -1 || !val.id
        }));
        this.filterLabel = this.filterLabel + "'" + val.text.replace(/\s/g, ''); 
      }
      if(this.filterData.projectsSelected.length>0)
      {
        this.rowsFilter = [...filtered]
        filtered = [];
      }
      this.filterLabel = this.filterLabel + "'] and model:["; 
      for (let val of this.filterData.modelsSelected) {
        filtered = filtered.concat(this.rowsFilter.filter(function (d) {
          return d.propertymodelid && d.propertymodelid.propertymodelid.toString().toLowerCase().indexOf(val.id) !== -1 || !val.id
        }));
        this.filterLabel = this.filterLabel + "'" + val.text.replace(/\s/g, '');
      }
      if(this.filterData.modelsSelected.length>0)
      {
        this.rowsFilter = [...filtered]
        filtered = [];
      }
      this.filterLabel = this.filterLabel + "'] and propertyType:[";
      for (let val of this.filterData.propertiesTypeSelected) {
        filtered = filtered.concat(this.rowsFilter.filter(function (d) {
          return d.propertytypeid && d.propertytypeid.propertytypeid.toString().toLowerCase().indexOf(val.id) !== -1 || !val.id
        }));
        this.filterLabel = this.filterLabel + "'" + val.text.replace(/\s/g, '');
      }
      if(this.filterData.propertiesTypeSelected.length>0)
      {
        this.rowsFilter = [...filtered]
        filtered = [];
      }
      if(this.filterData.lastDateConst == +this.selectPersonalized) {
        if (this.fromDateProgConst.length > 0 && this.toDateProgConst.length > 0) {
          var self = this;
          this.filterLabel = this.filterLabel + "'] and fromDateProgConst:[" + "'" + this.fromDateProgConst + "'] and toDateProgConst:[" + this.toDateProgConst + "'";
          filtered = filtered.concat(this.rowsFilter.filter(function (d) {
            return d.datescheduled1 && new Date(d.datescheduled1) >= new Date(self.fromDateProgConst) && new Date(d.datescheduled1) < new Date(self.toDateProgConst)
          }));
          this.rowsFilter = [...filtered]
          filtered = [];
        }
      } else {
        switch (this.filterData.lastDateConst) {
          case +this.selectLastDay: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastDayConst:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datescheduled1 && new Date(d.datescheduled1) >= self.yesterday
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
          case +this.selectLastWeek: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastWeekConst:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datescheduled1 && new Date(d.datescheduled1) >= self.oneWeekAgo
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
          case +this.selectLastMonth: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastMonthConst:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datescheduled1 && new Date(d.datescheduled1) >= self.oneMonthAgo
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
          case +this.selectLastYear: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastYearConst:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datescheduled1 && new Date(d.datescheduled1) >= self.oneYearAgo
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
        }
      }
      if(this.filterData.lastDateSales == +this.selectPersonalized) {
        if (this.fromDateProgSales.length > 0 && this.toDateProgSales.length > 0) {
          var self = this;
          this.filterLabel = this.filterLabel + "'] and fromDateProgSales:[" + "'" + this.fromDateProgSales + "'] and toDateProgSales:[" + this.toDateProgSales + "'";
          filtered = filtered.concat(this.rowsFilter.filter(function (d) {
            return d.datescheduled2 && new Date(d.datescheduled2) >= new Date(self.fromDateProgSales) && new Date(d.datescheduled2) < new Date(self.toDateProgSales)
          }));
          this.rowsFilter = [...filtered]
          filtered = [];
        }
      } else {
        switch (this.filterData.lastDateSales) {
          case +this.selectLastDay: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastDaySales:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datescheduled2 && new Date(d.datescheduled2) >= self.yesterday
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
          case +this.selectLastWeek: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastWeekSales:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datescheduled2 && new Date(d.datescheduled2) >= self.oneWeekAgo
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
          case +this.selectLastMonth: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastMonthSales:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datescheduled2 && new Date(d.datescheduled2) >= self.oneMonthAgo
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
          case +this.selectLastYear: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastYearSales:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datescheduled2 && new Date(d.datescheduled2) >= self.oneYearAgo
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
        }
      }
      if(this.filterData.lastDateConst == +this.selectPersonalized) {
        if (this.fromDateDeliver.length > 0 && this.toDateDeliver.length > 0) {
          var self = this;
          this.filterLabel = this.filterLabel + "'] and fromDateDeliver:[" + "'" + this.fromDateDeliver + "'] and toDateDeliver:[" + this.toDateDeliver + "'";
          filtered = filtered.concat(this.rowsFilter.filter(function (d) {
            return d.datedeliver && new Date(d.datedeliver) >= new Date(self.fromDateDeliver) && new Date(d.datedeliver) < new Date(self.toDateDeliver)
          }));
          this.rowsFilter = [...filtered]
          filtered = [];
        }
      } else {
        switch (this.filterData.lastDateDeliver) {
          case +this.selectLastDay: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastDayDeliver:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datedeliver && new Date(d.datedeliver) >= self.yesterday
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
          case +this.selectLastWeek: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastWeekDeliver:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datedeliver && new Date(d.datedeliver) >= self.oneWeekAgo
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
          case +this.selectLastMonth: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastMonthDeliver:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datedeliver && new Date(d.datedeliver) >= self.oneMonthAgo
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
          case +this.selectLastYear: {
            var self = this;
            this.filterLabel = this.filterLabel + "'] and lastYearDeliver:";
            filtered = filtered.concat(this.rowsFilter.filter(function (d) {
              return d.datedeliver && new Date(d.datedeliver) >= self.oneYearAgo
            }));
            this.rowsFilter = [...filtered]
            filtered = [];
            break;
          }
        }
      } 
      this.rowsOut.emit([...this.rowsFilter]);
    }
    this.filterLabelOut.emit(this.filterLabel);
  }
}
