<section class="text-center login-form">
  <div class="col-md-6 push-md-3 col-sm-12">
    <img src="assets/iconos/logo_menu.png" style="width: 24%"/>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="form-horizontal">
    <mat-form-field class="">
      <div class="col-sm-12 login-msg">
        {{ recoverMessage }}
      </div>
    </mat-form-field>
    <div *ngIf="tokenValid" class="form-group row">
      <div class="col-sm-12 text-center">
        <button type="submit" class="btn btn-success btn-auth">Restablecer Contraseña</button>
      </div>
    </div>
  </form>
  <div class="text-center bottom-login"><span>Derechos Reservados © {{currentyear}} - e-Recibos, S.A. |&nbsp;<a href="#" rel="nofollow" class="">Términos y Condiciones</a> | <a href="#" rel="nofollow" class="">Políticas de Privacidad</a></span></div>
</section>
