import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DateService } from "../../services/date.service";
declare var $: any;
declare var jquery: any;

@Component({
  selector: 'custom-datepicker',
  templateUrl: 'datepicker.component.html',
  providers: [DateService]
})

export class CustomDatePicker implements OnInit, AfterViewInit{
  @Input() idDatePicker:string;
  @Output() rangeDates = new EventEmitter();
  filterData = {
    datestart: new Date(0),
    dateend: new Date(0)
  };
  today1:Date;
  today2:Date;
  oneWeekAgo1:Date;
  oneWeekAgo2:Date;
  oneMonthAgo1:Date;
  oneMonthAgo2:Date;
  oneYearAgo1:Date;
  oneYearAgo2:Date;
  datestart:string = "";
  dateend:string = "";
  lastDatesSelect = [
    { id: 0 ,title: " Ninguno"},
    { id: 1 ,title: " Dia Corriente"},
    { id: 2 ,title: " Semana Corriente"},
    { id: 3 ,title: " Mes Corriente"},
    { id: 4 ,title: " Año Corriente"},
    { id: 5 ,title: " Personalizado"}
  ];
  //@ViewChild('datestart') inputDateStart: ElementRef;
  //@ViewChild('dateend') inputDateEnd: ElementRef;

  constructor(private ref:ChangeDetectorRef, private DateService: DateService) {}

  ngOnInit() {
    this.today2 = new Date();
    this.today2.setHours(23);
    this.today2.setMinutes(59);
    this.today2.setSeconds(59);
    this.today1 = new Date();
    this.today1.setHours(0);
    this.today1.setMinutes(0);
    this.today1.setSeconds(0);

    this.oneWeekAgo2 = new Date();
    this.oneWeekAgo2.setDate(+new Date().getDate() + (7 - new Date().getDay()));
    this.oneWeekAgo2.setHours(23);
    this.oneWeekAgo2.setMinutes(59);
    this.oneWeekAgo2.setSeconds(59);
    this.oneWeekAgo1 = new Date();
    this.oneWeekAgo1.setDate(+new Date().getDate() + (1 - new Date().getDay()));
    this.oneWeekAgo1.setHours(0);
    this.oneWeekAgo1.setMinutes(0);
    this.oneWeekAgo1.setSeconds(0);

    this.oneMonthAgo1 = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.oneMonthAgo1.setHours(0);
    this.oneMonthAgo1.setMinutes(0);
    this.oneMonthAgo1.setSeconds(0);
    this.oneMonthAgo2 = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    this.oneMonthAgo2.setHours(23);
    this.oneMonthAgo2.setMinutes(59);
    this.oneMonthAgo2.setSeconds(59);

    this.oneYearAgo1 = new Date(new Date().getFullYear(), 0, 1);
    this.oneYearAgo1.setHours(0);
    this.oneYearAgo1.setMinutes(0);
    this.oneYearAgo1.setSeconds(0);
    this.oneYearAgo2 = new Date(new Date().getFullYear(), 11, 31);
    this.oneYearAgo2.setHours(23);
    this.oneYearAgo2.setMinutes(59);
    this.oneYearAgo2.setSeconds(59);

    var self = this;
    $(function () {
      $('.select2').select2();
      $('#datetimepicker1' + self.idDatePicker).datetimepicker({
        icons: {
          previous: 'fa fa-arrow-left',
          next: 'fa fa-arrow-right'
        },
        format: 'YYYY-MM-DD'
      });
      $('#datetimepicker2' + self.idDatePicker).datetimepicker({
        icons: {
          previous: 'fa fa-arrow-left',
          next: 'fa fa-arrow-right'
        },
        format: 'YYYY-MM-DD'
      });
    });
  }

  ngAfterViewInit() {
    var self = this;
    $('#'+this.idDatePicker).on('select2:select', function (e) {
      var data = e.params.data;
      self.selectDate(data.id);
    });
    $('#datetimepicker1' + this.idDatePicker).on("dp.change", function(e) {
      self.datestart = self.formatDate(e.date._d);
      self.ref.detectChanges();
    });
    $('#datetimepicker2' + this.idDatePicker).on("dp.change", function(e) {
      self.dateend = self.formatDate(e.date._d);
      self.ref.detectChanges();
    });

  }

  /**
   *
   * @param i
   */
  selectDate(i) {
    switch (i) {
      case '0':
        this.filterData.dateend = new Date(0);
        this.filterData.datestart = new Date(0);
        $('#datepersonalized' + this.idDatePicker).hide("slow");
        $('#rngdates' + this.idDatePicker).html(this.filterData.datestart + " -- " + this.filterData.dateend);
        this.rangeDates.emit(this.filterData);
        break;
      case '1':
        this.filterData.datestart = this.today1;
        this.filterData.dateend = this.today2;
        $('#datepersonalized' + this.idDatePicker).hide("slow");
        $('#rngdates' + this.idDatePicker).html(this.filterData.datestart + " -- " + this.filterData.dateend);
        this.rangeDates.emit(this.filterData);
        break;
      case '2':
        this.filterData.datestart = this.oneWeekAgo1;
        this.filterData.dateend = this.oneWeekAgo2;
        $('#datepersonalized' + this.idDatePicker).hide("slow");
        $('#rngdates' + this.idDatePicker).html(this.filterData.datestart + " -- " + this.filterData.dateend);
        this.rangeDates.emit(this.filterData);
        break;
      case '3':
        this.filterData.datestart = this.oneMonthAgo1;
        this.filterData.dateend = this.oneMonthAgo2;
        $('#datepersonalized' + this.idDatePicker).hide("slow");
        $('#rngdates' + this.idDatePicker).html(this.filterData.datestart + " -- " + this.filterData.dateend);
        this.rangeDates.emit(this.filterData);
        break;
      case '4':
        this.filterData.datestart = this.oneYearAgo1;
        this.filterData.dateend = this.oneYearAgo2;
        $('#datepersonalized' + this.idDatePicker).hide("slow");
        $('#rngdates' + this.idDatePicker).html(this.filterData.datestart + " -- " + this.filterData.dateend);
        this.rangeDates.emit(this.filterData);
        break;
      case '5':
        this.datestart = "";
        this.dateend = "";
        $('#datepersonalized' + this.idDatePicker).show("slow");
        break;
    }
  }

  /**
   *
   * @param values
   */
  getRangeDate(values) {
    // new Date() assumes UTC-0 when using format YYYY-MM-DD, needs offset after
    let offset = (new Date().getTimezoneOffset()) * 60000;
    this.filterData.datestart = new Date(this.DateService.convertToDate(values.datestart).getTime() + offset);
    this.filterData.dateend = new Date(this.DateService.convertToDate(values.dateend).getTime() + offset);
    $('#rngdates' + this.idDatePicker).html(this.filterData.datestart + " -- " + this.filterData.dateend);
    this.rangeDates.emit(this.filterData);
  }

  /**
   *
   */
  canceldaterange() {
    this.filterData.datestart = new Date(0);
    this.filterData.dateend = new Date(0);
    $('#rngdates' + this.idDatePicker).html(this.filterData.datestart + " -- " + this.filterData.dateend);
    this.rangeDates.emit(this.filterData);
  }

  /**
   *
   * @returns {boolean}
   */
  valideDate() {
    if(this.datestart == "" || this.dateend == "" || this.datestart == "NaN-NaN-NaN" || this.dateend == "NaN-NaN-NaN" ) return false;
    else {
      if (this.DateService.convertToDate(this.datestart) > this.DateService.convertToDate(this.dateend) ) return true;
      return false;
    }
  }

  /**
   *
   * @returns {boolean}
   */
  valideForm() {
    if(this.datestart == "" || this.dateend == "" || this.datestart == "NaN-NaN-NaN"|| this.dateend == "NaN-NaN-NaN") return true;
    if (this.DateService.convertToDate(this.datestart) > this.DateService.convertToDate(this.dateend) ) return true;
    return false;
  }

  formatDate(date:Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  } 
}

