import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pipeNumberFormat'
})
export class PipeNumberFormatPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return this.formatearNumero(""+value);
    }

    formatearNumero(nStr) {
        nStr += '';
        let x = nStr.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;

        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }

        return x1 + x2;
    }

}
