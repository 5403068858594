import { NgModule } from '@angular/core'; 

import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list'; 
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';


@NgModule({
   declarations: [],
   imports: [ 
      MatButtonModule,
      MatTabsModule, 
      MatIconModule, 
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatDialogModule,
      MatListModule,
      MatToolbarModule,
      MatSidenavModule,
      MatSnackBarModule,
      NgxMatFileInputModule
   ],
   exports: [ 
      MatButtonModule, 
      MatTabsModule, 
      MatIconModule, 
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatDialogModule,
      MatListModule,
      MatToolbarModule,
      MatSidenavModule,
      MatSnackBarModule,
      NgxMatFileInputModule
   ]
})

export class MaterialModule { }
